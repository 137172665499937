export function CheckImage(filename) {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
    const videoExtensions = ['.mp4', '.webm', '.ogg'];
    const extension = filename.slice(filename.lastIndexOf('.')).toLowerCase();
    if(imageExtensions.includes(extension))
    {
        return "image";
    }
    else if(videoExtensions.includes(extension))
    {
        return "video"
    }
    else
    {
        return "others";
    }
}
export function EachWordCapitalize(str){
    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(" ");
}
export function FilterParameterTags(parameterArray, parameterId) {
    if(parameterArray)
    {
        const parameter = parameterArray.find(
        (param) => param.parameter_id == parameterId
        );
        return parameter ? parameter.values : [];
    }
    else
    {
        return [];
    }
  }
  export function FilterParameterSubTags(parameterArray, parameterId, tag_id) {

    if(parameterArray)
    {
        const parameter = parameterArray.find(
        (param) => param.tag_id == tag_id
        );
        return parameter ? parameter.values : [];
    }
    else
    {
        return [];
    }
  }
export function age(dob){
    if(dob)
    {
        const birthDate = new Date(dob);
        const today = new Date();
        
        const yearsDiff = today.getFullYear() - birthDate.getFullYear();
        const monthsDiff = today.getMonth() - birthDate.getMonth();
        const daysDiff = today.getDate() - birthDate.getDate();
        let calculatedAge = yearsDiff;
        if (monthsDiff < 0 || (monthsDiff === 0 && daysDiff < 0)) {
            calculatedAge--;
        }
        return calculatedAge
    }
}
export function checkNull(data,return_value){
    return data ? data : return_value;
}
export function checkGender(gender){
    if(gender)
    {
        if(gender == "M")
        {
            return "Male";
        }
        else if(gender == "F")
        {
            return "Female";
        }
        else
        {
            return "Others";
        }
    }
    else{
        return null;
    }
}
export function convertUtcToLocalTime(datetime){
    const utcTimeString = datetime;
    const utcDate = new Date(utcTimeString.replace(/-/g, "/"));
    const localOffset = utcDate.getTimezoneOffset();
    const localTime = new Date(utcDate.getTime() - (localOffset * 60 * 1000));
    return localTime.toLocaleString();
}
export function formatTime(dateString) {
    const date = new Date(dateString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    if(isNaN(formattedHours) && isNaN(formattedMinutes)){
        return "-";
    }
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }
export function addLeadingZero(value){
    return value < 10 ? `0${value}` : value;
}
export function formatUTCDate(date) {
    
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  export function formatDate(date) {
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  export function minutesToHHMM(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    
    // Format hours and minutes as two-digit numbers
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
    
    return `${formattedHours}:${formattedMinutes}`;
  }
  export function secondsToHHMMSS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const remainingSecondsAfterHours = seconds % 3600;
    const minutes = Math.floor(remainingSecondsAfterHours / 60);
    const remainingSeconds = remainingSecondsAfterHours % 60;

    // Format hours, minutes, and seconds as two-digit numbers
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}
  export function checkTagsSubTags(parameters, description=null){
    const arraysWithTagsCount = parameters.filter(parameter => parameter.tags && parameter.tags.length > 0).length;
    let value = "";
    if(arraysWithTagsCount == 0)
    {
        value = "hide";
    }
    else
    {
        if(arraysWithTagsCount == parameters.length){
            const check_sub_tag = parameters.filter(parameter => parameter.is_sub_tag === 1);
            if(check_sub_tag.length > 0){
                const someParametersWithSubTagsHaveBothTagsAndSubTags = parameters.some(parameter => parameter.is_sub_tag === 1 && parameter.tags && parameter.tags.length > 0 && parameter.tags.every(tag => tag.sub_tags && tag.sub_tags.length > 0));
                if(someParametersWithSubTagsHaveBothTagsAndSubTags){
                value = "next";
                }
                else{
                    value = "wasted";
                }
            }
            else{
                value = "next";
            }
        }
        else
        {
            value = "wasted";
        }
    }
    return value;
}
export function findValue(array, find_value){
    return array.find((a)=>a.id === find_value);
}
export function utcTimetoLocalTime(utcDateTime, timeZone){
    var currentDate = new Date();
    var hours = currentDate.getHours();
    var minutes = currentDate.getMinutes();
    var seconds = currentDate.getSeconds();


    if(utcDateTime){
        const utcTimeString = utcDateTime;
        const utcDate = new Date(utcTimeString + ' UTC');
        const localTime = new Intl.DateTimeFormat('en-US', {
        timeZone,
        // year: 'numeric',
        // month: 'numeric',
        // day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        }).format(utcDate);
        return localTime;
    }
}
export function currentMonthDates(){
    var currentDate = new Date();

    var currentMonth = currentDate.getMonth() + 1; // Adding 1 because months are zero-based
    var currentYear = currentDate.getFullYear();
    var lastDay = new Date(currentYear, currentMonth, 0).getDate();
    return lastDay;
}
export function average(item1, item2){
    if(item2 == 0){
        return 0;
    }
    return item1/item2;
}
export function decimalNumber(originalValue, decimalPlaces){
    return Number(originalValue.toFixed(decimalPlaces))
}