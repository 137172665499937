import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import Header from '../../layouts/header';
import Footer from '../../layouts/footer';
import { approveRoles, getAdminRolesStatusList, getJobAppliedUser } from '../../Api/ApiHubOffcer';
import { age, checkGender, checkNull } from '../../Api/CommanJsFunction';
import Multiselect from 'multiselect-react-dropdown';
import Select from 'react-select';
import CancelIcon from '@mui/icons-material/Cancel';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ImageViewer from '../../Components/imageviewer';

const HomeHubOfficers = ({ allLanguageLists, setIsShowHeader }) => {
    const [jobAppliedUsers, setJobAppliedUsers] = useState();
    const [purticularUserDetails, setPurticularUserDetails] = useState();
    // const [userRolesDetails, setUserRolesDetails] = useState();
    const [adminRoleStatusLists, setAdminRoleStatusLists] = useState([]);
    const [approveErrors, setApproveErrors] = useState({});
    const [filter, setFilter] = useState({})
    const closeButtonRef = useRef(null);
    const redirect = useNavigate();
    const [selectedSkills, setSelectedSkills] = useState([]);

    const handleSkillChange = (selectedOptions) => {
        setSelectedSkills(selectedOptions);
    };
    useEffect(() => {
        setIsShowHeader(true);
        let page = 1;
        getJobAppliedUser(page, filter).then((res) => {
            setJobAppliedUsers(res.data.data.values);
        }).catch((err) => {
            if (err.response.status === 401) {
                redirect('/');
            }
        })
        document.getElementById('filter_id').reset();

    }, [filter])
    useEffect(() => {
        getAdminRolesStatusList().then((res) => {
            setAdminRoleStatusLists(res.data.data);
        }).catch(() => {

        })
    }, [])
    function showPurticularUser(id) {
        let user_detail = jobAppliedUsers.find((jobAppliedUser) => {
            return jobAppliedUser.id === id;
        });
        setPurticularUserDetails(user_detail);
        setApproveErrors({});
    }
    const [selectedOptions, setSelectedOptions] = useState([]);

    // Handler for when options are selected/deselected
    const onSelect = (selectedList, selectedItem) => {
        setSelectedOptions(selectedList);
    };

    const options = [
        { key: 'option1', value: 'Option 1' },
        { key: 'option2', value: 'Option 2' },
        { key: 'option4', value: 'Option 4' },
        { key: 'option5', value: 'Option 5' },
        { key: 'option6', value: 'Option 6' },
        { key: 'option7', value: 'Option 7' },
        { key: 'option8', value: 'Option 8' },
        { key: 'option9', value: 'Option 9' },
        { key: 'option10', value: 'Option 10' },
        // Add more options as needed
    ];


    function editInput(e) {
        // setUserRolesDetails((prev)=>({...prev,[e.target.name] : e.target.value}));
        setApproveErrors((prev) => ({ ...prev, [e.target.name]: null }))
        setPurticularUserDetails((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    }
    function submitUserRolesDetails(user_id) {
        // if(userRolesDetails)
        // {
        let data = {
            target: purticularUserDetails.target,
            login_time: purticularUserDetails.login_time,
            logout_time: purticularUserDetails.logout_time,
            break_time: purticularUserDetails.break_time,
            role_status: purticularUserDetails.role_status,
            internal_email: purticularUserDetails.internal_email
        }
        approveRoles(user_id, data).then((res) => {
            const updatedUsers = jobAppliedUsers.map((user) => {
                if (user.user_id === user_id) {
                    return {
                        ...user,
                        ...purticularUserDetails,
                    };
                }
                return user; // Keep other user objects unchanged
            });
            setJobAppliedUsers(updatedUsers);
            // setUserRolesDetails();
            if (closeButtonRef.current) {
                closeButtonRef.current.click();
            }
        }).catch((err) => {
            setApproveErrors(err.response.data.error);
        })
        // }
        // else
        // {
        //     alert("fill all details");
        // }
    }
    function handleSearchFilter(e) {
        e.preventDefault();
        setFilter({
            officer_name: e.target.officer_name.value,
            role: e.target.role.value,
            registration_date: e.target.registration_date.value,
            language: e.target.language.value,
        })
    }
    function findRoleName(id = 2) {
        if(adminRoleStatusLists.length > 0){
            return id ? adminRoleStatusLists.find((list) => id === list.id).name : null;
        }
    }
    function devideLanguage(languages, type) {
        if (type === 'main') {
            let lan = languages.find((lang) => lang.is_main === 1);
            return lan?.details.name;
        }
        if (type === 'written') {
            let lan = languages.find((lang) => lang.is_written === 1);
            return lan?.details.name;
        }
        if (type === 'others') {
            let lan = languages.find((lang) => lang.is_main === 0);
            return lan?.details.name;
        }
    }
    function array_selected_language(languages, type) {
        let lan = [];
        if (type === "main") {
            lan = languages.filter((lang) => lang.is_main === 1)
        }
        if (type === "written") {
            lan = languages.filter((lang) => lang.is_written === 1)
        }
        if (type === "others") {
            lan = languages.filter((lang) => lang.is_main === 0)
        }

        const languageLists = lan.map((lang, key) => ({
            key: key,
            label: lang.details.name,
            value: lang.details.id,
        }))
        return languageLists;
    }
    const [profilePicture, setProfilePicture] = useState(null);

    const handleProfilePictureChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfilePicture(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleCancelProfilePicture = () => {
        setProfilePicture(null);
    };
    return (
        <>
            {/* <Header></Header> */}
            <main className="main-content main-top">
                <div className="">
                    <div className="container-fluid mt-20">
                    
                        <div className='row'>
                            <div className='col-lg-2 col-md-2 col-sm-12 mb-20'>
                                <div className='card'>
                                    <div className='card-header'>Search Filter</div>
                                    <form onSubmit={handleSearchFilter} id="filter_id">

                                        <div className='card-body user-filter'>
                                            <div className="form-group">
                                                <label htmlFor="a10" className="il-gray fs-14 fw-500 align-center">Officer Name</label>
                                                <input type="text" className="form-control ih-small ip-light radius-xs b-light px-15" placeholder="" name="officer_name" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="a10" className="il-gray fs-14 fw-500 align-center">Role</label>
                                                <input type="text" className="form-control ih-small ip-light radius-xs b-light px-15" placeholder="" name="role" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="a10" className="il-gray fs-14 fw-500 align-center">Date of Registration</label>
                                                <input type="text" className="form-control ih-small ip-light radius-xs b-light px-15" placeholder="" Type='date' name="registration_date" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="a10" className="il-gray fs-14 fw-500 align-center">Language</label>
                                                <select className="form-control ih-small ip-light radius-xs b-light px-15" name='language'>
                                                    <option value="">Select Language</option>
                                                    {allLanguageLists?.map((lang, key) => {
                                                        return (
                                                            <option value={lang.id} key={key}>{lang.name}</option>
                                                        )
                                                    })}
                                                </select>
                                                {/* <input type="text" className="form-control ih-small ip-light radius-xs b-light px-15" placeholder="Numbers Only" name='language'/> */}
                                            </div>
                                        </div>
                                        <div className='card-footer'>
                                            <button className="btn btn-success btn-default btn-squared " type='submit'>Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className='col-lg-10'>
                                <div className="card">
                                    <div className="card-header color-dark fw-500">
                                        <div className='row w-100 mt-10'><div className='col-6'><h6>Hub Officers</h6></div><div className='col-6 justify-content-end'>&nbsp;</div></div>
                                    </div>
                                    <div className="card-body p-0 user-table-height">
                                        <div className="table4  p-25 bg-white mb-30">
                                            <div className="table-responsive">
                                                <table className="table mb-0">
                                                    <thead>
                                                        <tr className="userDatatable-header">
                                                            <th><span className="userDatatable-title">Officer Name</span></th>
                                                            <th><span className="userDatatable-title">Role</span></th>
                                                            <th><span className="userDatatable-title">Main languages</span></th>
                                                            <th><span className='userDatatable-title'>Religion</span></th>
                                                            <th><span className='userDatatable-title'>Denomination</span></th>
                                                            <th><span className='userDatatable-title'>Gender</span></th>
                                                            <th><span className='userDatatable-title'>Nationality</span></th>
                                                            <th><span className='userDatatable-title'>Age</span></th>
                                                            <th><span className='userDatatable-title'>Status</span></th>
                                                            <th><span className='userDatatable-title'>Action</span></th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {jobAppliedUsers?.map((jobAppliedUser) => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        <div className="userDatatable-content">
                                                                            {checkNull(jobAppliedUser.profile_name, "-")}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="userDatatable-content">
                                                                            Curator
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div className="userDatatable-content">
                                                                            {checkNull(devideLanguage(jobAppliedUser.languages, 'main'))}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="userDatatable-content">
                                                                            {checkNull(jobAppliedUser.religion_name, "-")}
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div className="userDatatable-content">
                                                                            {checkNull(jobAppliedUser.denomination_name, "-")}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="userDatatable-content">
                                                                            {checkNull(checkGender(jobAppliedUser.user?.gender), "-")}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="userDatatable-content">
                                                                            {checkNull(jobAppliedUser.user?.nationality_name, "-")}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="userDatatable-content">
                                                                            {checkNull(age(jobAppliedUser.user?.dob), "-")}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="userDatatable-content">
                                                                            <span className={`atbd-tag tag-${jobAppliedUser.role_status === 1 ? 'success' : (jobAppliedUser.role_status === null ? 'warning' : 'danger')} tag-transparented`}>{findRoleName(jobAppliedUser.role_status) || "Not Seen"}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="userDatatable-content">
                                                                            <Link to='#' data-toggle="modal" data-target="#editofficer"> <img width={18} src='/assets/img/edit.png' onClick={() => showPurticularUser(jobAppliedUser.id)} /></Link>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                        {/* <tr>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    Ponmariappan
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    Moderator
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    Tamil
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    {Hindu}
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    Siva
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    Male
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    Indian
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    31
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    <span className="atbd-tag tag-warning tag-transparented">Pending</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    <a href='#' data-toggle="modal" data-target="#editofficer"> <img width={18} src='assets/img/edit.png' /></a>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    Ponmariappan
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    Moderator
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    Tamil
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    Hindu
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    Siva
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    Male
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    Indian
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    31
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    <span className="atbd-tag tag-danger tag-transparented">Disqualified</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    <a href='#' data-toggle="modal" data-target="#editofficer"> <img width={18} src='assets/img/edit.png' /></a>
                                                                </div>
                                                            </td>
                                                        </tr> */}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
                <div className="modal-basic modal fade show" id="getaccess" tabindex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content modal-bg-white ">
                            <div className="modal-header">
                                <h6 className="modal-title">Get Access</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span data-feather="x"></span></button>
                            </div>
                            <div className="modal-body">
                                <div className='pop-up-height'>
                                    <table className='table m-0'>
                                        <tr>
                                            <td>Officer Name</td>
                                            <td><input type="text" className="form-control ih-small ip-light radius-xs b-light px-15" id="" placeholder="Officer Name" /></td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td><input type="text" className="form-control ih-small ip-light radius-xs b-light px-15" id="" placeholder="Email" /></td>
                                        </tr>
                                        <tr>
                                            <td>Phone Number</td>
                                            <td><input type="text" className="form-control ih-small ip-light radius-xs b-light px-15" id="" placeholder="Phone Number" /></td>
                                        </tr>
                                        <tr>
                                            <td>Religious Channel</td>
                                            <td><input type="text" className="form-control ih-small ip-light radius-xs b-light px-15" id="" placeholder="Religious Channel" /></td>
                                        </tr>
                                        <tr>
                                            <td>Profile Name</td>
                                            <td><input type="text" className="form-control ih-small ip-light radius-xs b-light px-15" id="" placeholder="Profile Name" /></td>
                                        </tr>
                                        <tr>
                                            <td>Main Language</td>
                                            <td><select className='form-control'><option>Tamil</option>
                                                <option>Tamil</option>
                                                <option>Tamil</option>
                                                <option>Tamil</option></select></td>
                                            <td>Written</td>
                                            <td><div className="checkbox-list__single">


                                                <div className="checkbox-group d-flex">

                                                    <div className="checkbox-theme-default custom-checkbox checkbox-group__single">
                                                        <input className="checkbox" type="checkbox" id="check-grp-10" />
                                                        <label for="check-grp-10">
                                                            <span className="checkbox-text">Tamil</span>
                                                        </label>
                                                    </div>

                                                    <div className="checkbox-theme-default custom-checkbox checkbox-group__single">
                                                        <input className="checkbox" type="checkbox" id="check-grp-11" />
                                                        <label for="check-grp-11">
                                                            <span className="checkbox-text">Eng</span>
                                                        </label>
                                                    </div>

                                                    <div className="checkbox-theme-default custom-checkbox checkbox-group__single">
                                                        <input className="checkbox" type="checkbox" id="check-grp-12" />
                                                        <label for="check-grp-12">
                                                            <span className="checkbox-text">Hindi</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div></td>
                                        </tr>
                                        <tr>
                                            <td>Other Languages</td>
                                            <td> <div className="atbd-select ">
                                                <select className="form-control" >
                                                    <option value="01">Eng</option>
                                                    <option value="02">China</option>
                                                    <option value="03">Tamil</option>
                                                </select>
                                            </div></td>

                                            <td>Religion</td>
                                            <td> <div className="atbd-select ">
                                                <select className="form-control" >
                                                    <option value="01">Christianity</option>
                                                    <option value="02">Islam</option>
                                                    <option value="03">Hindu</option>
                                                </select>
                                            </div></td>
                                        </tr>
                                        <tr>

                                            <td>Denomination</td>
                                            <td> <div className="atbd-select ">
                                                <select className="form-control" >
                                                    <option value="01">Christianity</option>
                                                    <option value="02">Islam</option>
                                                    <option value="03">Hindu</option>
                                                </select>
                                            </div></td>
                                            <td>Gender</td>
                                            <td>
                                                <div className="atbd-select ">
                                                    <select className="form-control" >
                                                        <option value="01">Male</option>
                                                        <option value="02">Female</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Nationality</td>
                                            <td><div className="atbd-select ">
                                                <select className="form-control" >
                                                    <option value="01">Indian</option>
                                                    <option value="02">USA</option>
                                                </select>
                                            </div></td>
                                            <td>Age</td>
                                            <td><div className="atbd-select ">
                                                <select className="form-control" >
                                                    <option value="01">21</option>
                                                    <option value="02">22</option>
                                                </select>
                                            </div></td>
                                        </tr>
                                        <tr>
                                            <td>Books Associated</td>
                                            <td><div className="form-group row">
                                                <div className="col-sm-12">
                                                    <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15" id="inputName" placeholder="Add Negative Values" />
                                                </div>
                                            </div>
                                                <div className='row mb-20 pb-20 border-bottom'>
                                                    <div className='col-12'>
                                                        <div className="atbd-tag-wrap">
                                                            <div className="tag-box"><span className="atbd-tag tag-light">Positive values <Link to="#" className="tag-closable"><i className="la la-times"></i></Link></span>
                                                                <span className="atbd-tag tag-light">Tag 3 <Link to="#" className="tag-closable"><i className="la la-times"></i></Link></span>
                                                                <span className="atbd-tag tag-light">Tag 3 <Link to="#" className="tag-closable"><i className="la la-times"></i></Link></span>
                                                                <span className="atbd-tag tag-light">Tag 3 <Link to="#" className="tag-closable"><i className="la la-times"></i></Link></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-success btn-sm">Proceed</button>
                                <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                {purticularUserDetails &&

                    <div className="modal-basic modal fade show" id="editofficer" tabindex="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content modal-bg-white ">
                                <div className="modal-header border-0">
                                    <h6 className="modal-title">Edit Officer</h6>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" ref={closeButtonRef}>
                                        <span data-feather="x"></span></button>
                                </div>
                                <Tabs>
                                    <div className='tabsection'>
                                        <TabList className="tab-list " >
                                            <Tab className="tab-item ">Personal Details</Tab>
                                            <Tab className="tab-item">Professional Details</Tab>
                                            <Tab className="tab-item">Job Specifics</Tab>
                                            <Tab className="tab-item">OnBoarding</Tab>
                                        </TabList>
                                    </div>
                                    <TabPanel>
                                        <div className="modal-body pb-0">
                                            <div className='pop-up-height'>
                                                <div className="personal-details-container">
                                                    <div className="personal-details-section">
                                                        <div>
                                                        <ImageViewer></ImageViewer>
                                                        </div>
                                                        {/* <div className="profile-picture-section">
                                                            <label htmlFor="profile">Profile Picture</label>
                                                            <div className="form-group">
                                                                {profilePicture ? (
                                                                    <div className="profile-picture-container" style={{ position: 'relative' }}>
                                                                        <img
                                                                            src={profilePicture}
                                                                            alt="Profile"
                                                                            className="profile-picture rounded-circle"
                                                                            style={{ maxWidth: '100px', height: '100px', borderRadius: '15px' }}
                                                                        />
                                                                        <button
                                                                            className="cancel-button"
                                                                            onClick={handleCancelProfilePicture}
                                                                            style={{ position: 'absolute', top: '-18px', left: '86px', background: 'none', border: 'none', padding: '5px' }}
                                                                        >
                                                                            <CancelIcon style={{ width: '15px', height: '15px' }} /> {/* Render the Cancel icon 
                                                                        </button>
                                                                    </div>
                                                                ) : (
                                                                    <label htmlFor="profile" className="btn btn-outline-success" style={{ maxWidth: '270px', height: '40px', borderRadius: '15px' }}>
                                                                        + Upload Profile Picture
                                                                        <input
                                                                            type="file"
                                                                            id="profile"
                                                                            className="form-control"
                                                                            style={{ display: 'none' }}
                                                                            onChange={handleProfilePictureChange}
                                                                        />
                                                                    </label>
                                                                )}
                                                                <small className="form-text text-muted">Max file size: 5MB</small>
                                                            </div>
                                                        </div> */}
                                                        <div className='row mt-20'>
                                                            
                                                            <div className="form-group col-6 pl-20 mb-0">
                                                                <label className='hub-user-label mb-0' htmlFor="name">Name : </label>
                                                                <label id="name" className='mb-0'>{purticularUserDetails.user.name}</label>
                                                                {/* <input type="text" className="form-control" id="name" placeholder="Enter Name" disabled value={purticularUserDetails.user.name} /> */}
                                                            </div>
                                                            <div className="form-group col-6 pr-20 mb-0">
                                                                <div className="form-group">
                                                                    <label className='hub-user-label mb-0' htmlFor="dob">Date of Birth : </label>
                                                                    <label className='mb-0'>{purticularUserDetails.user.dateOfBirth}</label>
                                                                    {/* <input type="text" className="form-control" placeholder="" Type='date' value={purticularUserDetails.user.dateOfBirth} /> */}
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className='row '>
                                                            <div className="form-group col-6 pl-20">
                                                                <label className='hub-user-label mb-0' htmlFor="phone">Phone Number : </label>
                                                                 {/* Example country code, replace with actual code */}
                                                                 <label className='mb-0'>+91 {purticularUserDetails.user.mobile}</label>
                                                                    {/* <input type="text" className="form-control" id="phone" placeholder="Enter Phone Number" disabled value={purticularUserDetails.user.mobile} /> */}
                                                                
                                                            </div>
                                                            <div className="form-group col-6 pr-20 mb-0">
                                                                <label className='hub-user-label mb-0' htmlFor="email">Email : </label>
                                                                <label className='mb-0'>{purticularUserDetails.user.email}</label>
                                                                {/* <input type="email" className="form-control" id="email" placeholder="Enter Email" disabled value={purticularUserDetails.user.email} /> */}
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="form-group col-6 pl-20 mb-0">
                                                                <label className='hub-user-label mb-0' htmlFor="nationality">Nationality : </label><label className='mb-0'>Indian</label>
                                                                {/* <span className="atbd-select ">
                                                                    <select className="form-control" disabled>
                                                                        <option value="01">{purticularUserDetails.user.nationality_name}</option>
                                                                        <option value="01">Indian</option>
                                                                        <option value="02">USA</option>
                                                                    </select>
                                                                </span> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-success btn-sm" onClick={() => submitUserRolesDetails(purticularUserDetails.user_id)}>Approve</button>
                                                <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal">Reject</button>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="modal-body">
                                            <div className='pop-up-height'>
                                                <div className="professional-details-container">
                                                    <div className="professional-details-section">
                                                        <div className='row'>
                                                            <div className="form-group col-6 pl-20">
                                                                <label htmlFor="academic_documents">Academic History </label>
                                                                <div> <span>Documet Name</span><span className='view-icon'><Link to='#' data-toggle="modal" data-target="#personaldocment"><i class="fa fa-eye view-eye" aria-hidden="true"></i></Link>
                                                                </span></div>
                                                                {/* <input type="file" id="academic_documents" className="form-control" placeholder='Documents upload' /> */}
                                                            </div>
                                                            <div className="form-group col-6 pr-20">
                                                                <label htmlFor="work_documents">Work History</label>
                                                                <div> <span>Documet Name</span><span className='view-icon'><a href=''><i class="fa fa-eye view-eye" aria-hidden="true"></i></a>
                                                                </span></div>
                                                                {/* <input type="file" id="work_documents" className="form-control" placeholder='Documents upload' /> */}
                                                            </div>
                                                        </div>
                                                        <h6 className='mb-4'>Languages</h6>
                                                        <div className='row'>
                                                            <div className="form-group col-6 pl-20">
                                                                <label htmlFor="main_language">Main Language</label>
                                                                <div>
                                                                    <Select
                                                                        name="main_language"
                                                                        isMulti={false}
                                                                        // onChange={handleChange}
                                                                        value={array_selected_language(purticularUserDetails.languages, 'main')}
                                                                        isSearchable={false}
                                                                        isDisabled={true}
                                                                        placeholder="Select Main Language"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group col-6 pr-20">
                                                                <label htmlFor="written_language" >Written Languages</label>
                                                                <Select
                                                                    name="written_language"
                                                                    isMulti={true}
                                                                    // onChange={handleChange}
                                                                    value={array_selected_language(purticularUserDetails.languages, 'written')}
                                                                    isSearchable={false}
                                                                    isDisabled={true}
                                                                    placeholder="Written Main Language"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="form-group col-6 pl-20">
                                                                <label htmlFor="others_language">Other Languages</label>
                                                                <Select
                                                                    name="others_language"
                                                                    isMulti={true}
                                                                    // onChange={handleChange} 
                                                                    value={array_selected_language(purticularUserDetails.languages, 'others')}
                                                                    isSearchable={false}
                                                                    isDisabled={true}
                                                                    placeholder="other Main Language"
                                                                />
                                                            </div>
                                                            <div className="form-group col-6 pr-20">
                                                                <label htmlFor="skills">Skills</label>
                                                                <Select
                                                                    name="skills"
                                                                    isMulti
                                                                    onChange={handleSkillChange}
                                                                    value={selectedSkills}
                                                                    options={[
                                                                        { value: 'skill1', label: 'Skill 1' },
                                                                        { value: 'skill2', label: 'Skill 2' },
                                                                        { value: 'skill3', label: 'Skill 3' },
                                                                        { value: 'skill3', label: 'Skill 4' },
                                                                        { value: 'skill3', label: 'Skill 5' },
                                                                        { value: 'skill3', label: 'Skill 6' },
                                                                        // Add more options as needed
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>


                                                        <h6 className='mb-4'>Addresses</h6>
                                                        <div className='row'>
                                                            <div className="form-group col-6 pl-20">
                                                                <label htmlFor="address_line1">Address Line 1</label>
                                                                <input type="text" id="address_line1" className="form-control" placeholder="Enter Address Line 1" />
                                                            </div>
                                                            <div className="form-group col-6 pr-20">
                                                                <label htmlFor="address_line2">Address Line 2</label>
                                                                <input type="text" id="address_line2" className="form-control" placeholder="Enter Address Line 2" />
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="form-group col-6 pl-20">
                                                                <label htmlFor="city_town">City/Town</label>
                                                                <input type="text" id="city_town_1" className="form-control" placeholder="Enter City/Town" />
                                                            </div>
                                                            <div className="form-group col-6 pr-20">
                                                                <label htmlFor="state_region">State/Region</label>
                                                                <input type="text" id="state_region_1" className="form-control" placeholder="Enter State/Region" />
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="form-group col-6 pl-20">
                                                                <label htmlFor="zip_code">Zip/Postal Code</label>
                                                                <input type="text" id="zip_code_1" className="form-control" placeholder="Zip/Postal Code" />
                                                            </div>
                                                            <div className="form-group col-6 pr-20">
                                                                <label htmlFor="country">Country</label>
                                                                <input type="text" id="country_1" className="form-control" placeholder="Enter Country" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-success btn-sm" onClick={() => submitUserRolesDetails(purticularUserDetails.user_id)}>Approve</button>
                                                <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal">reject</button>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="modal-body ">
                                            <div className='pop-up-height'>
                                                <div className="job-details-container">
                                                    <div className="job-details-section">
                                                        <div className='row'>
                                                            <div className="form-group col-6 pl-20">
                                                                <label htmlFor="religion">Religion</label>
                                                                <div className="atbd-select">
                                                                    <select className="form-control" disabled>
                                                                        <option value="01">{purticularUserDetails.religion_name}</option>
                                                                        {/* <option value="02">Islam</option> */}
                                                                        {/* <option value="03">Hindu</option> */}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="form-group col-6 pr-20">
                                                                <label htmlFor="denomination">Denomination</label>
                                                                <div className="atbd-select">
                                                                    <select className="form-control" disabled>
                                                                        <option value="01">{purticularUserDetails.denomination_name}</option>
                                                                        {/* <option value="02">Islam</option>
                                                                    <option value="03">Hindu</option> */}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="form-group col-6 pl-20">
                                                                <div className="form-group row">
                                                                    <div className="col-sm-12">
                                                                        <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15" id="inputName" placeholder="Add Negative Values" disabled />
                                                                    </div>
                                                                </div>
                                                                <div className='row mb-20 pb-20 '>
                                                                    <div className='col-12'>
                                                                        <div className="atbd-tag-wrap">
                                                                            <div className="tag-box">
                                                                                {purticularUserDetails.books?.map((book) => {
                                                                                    return (
                                                                                        <span className="atbd-tag tag-light">{book.name} <Link to="#" className="tag-closable"><i className="la la-times"></i></Link></span>
                                                                                    )
                                                                                })}
                                                                                {/* <span className="atbd-tag tag-light">Tag 3 <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                                            <span className="atbd-tag tag-light">Tag 3 <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                                            <span className="atbd-tag tag-light">Tag 3 <a href="#" className="tag-closable"><i className="la la-times"></i></a></span> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-success btn-sm" onClick={() => submitUserRolesDetails(purticularUserDetails.user_id)}>Approve</button>
                                                        <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal">Reject</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="modal-body mb-4">
                                            <div className='pop-up-height'>
                                                <div className="Onboarding-details-container">
                                                    <div className="Onboarding-details-section">
                                                        <table className='table m-0 mb-4' >
                                                            <tr>
                                                                <td>Internal Email</td>
                                                            </tr>
                                                            <tr >
                                                                <td colSpan='4'>
                                                                    <div className="input-group" >
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="internal_email"
                                                                            placeholder="Enter Internal Email"
                                                                        />
                                                                        <div className="input-group-append">
                                                                            <span className="input-group-text">@evzone.app</span>
                                                                            <span><button className='btn btn-warning ml-10'>Check E-mail</button></span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan='1'>Roles Assigned</td>
                                                                <td colSpan='3'>
                                                                    <Multiselect
                                                                        options={options}
                                                                        onSelect={onSelect}
                                                                        onRemove={onSelect}
                                                                        displayValue="value"
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan='1'>Target</td>
                                                                <td colSpan='3'>
                                                                    <input type="text" className="form-control ih-small ip-light radius-xs b-light px-15" id="" placeholder="10" onChange={editInput} name="target" value={purticularUserDetails.target || ""} />
                                                                    <span style={{ color: "red" }}>{approveErrors.target}</span>
                                                                </td>
                                                            </tr>
                                                            <tr >
                                                                <td >Login time</td>
                                                                <td ><input className='form-control' type='time' onChange={editInput} name="login_time" value={purticularUserDetails.login_time || ""} />
                                                                    <span style={{ color: "red" }}>{approveErrors.login_time}</span>
                                                                </td>
                                                                <td >Logout time</td>
                                                                <td ><input className='form-control' type='time' onChange={editInput} name="logout_time" value={purticularUserDetails.logout_time || ""} />
                                                                    <span style={{ color: "red" }}>{approveErrors.logout_time}</span>
                                                                </td>
                                                            </tr>
                                                            <tr>


                                                                <td colSpan='1'>Break time Allowed</td>
                                                                <td colSpan='3'><input className='form-control' type='time' onChange={editInput} name="break_time" value={purticularUserDetails.break_time || ""} />
                                                                    <span style={{ color: "red" }}>{approveErrors.break_time}</span>
                                                                </td>
                                                            </tr>
                                                            <tr className='mb-4'>
                                                                <td colSpan='1'>Status</td>
                                                                <td colSpan='3' >
                                                                    <select className='form-control' name="role_status" onChange={editInput}>
                                                                        <option>Status</option>
                                                                        {adminRoleStatusLists?.map((adminRoleStatusList, key) => {
                                                                            return (
                                                                                <option value={adminRoleStatusList.id} key={key} selected={purticularUserDetails.role_status === adminRoleStatusList.id ? "selected" : ""}>{adminRoleStatusList.name}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                    <span style={{ color: "red" }}>{approveErrors.role_status}</span>
                                                                </td>
                                                            </tr>

                                                        </table>
                                                        <div className="add_email d-flex justify-content-center mb-4">
                                                            <button className="btn btn-outline-success" style={{ width: '100%', height: '40px', borderRadius: '15px' }}>Add Internal Email</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-success btn-sm" onClick={() => submitUserRolesDetails(purticularUserDetails.user_id)}>Approve</button>
                                                <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal">Reject</button>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </Tabs>
                                {/* <div className="modal-body">
                                <div className='pop-up-height'>
                                    <table className='table m-0'>
                                        <tr>
                                            <td>Officer Name</td>
                                            <td><input type="text" className="form-control ih-small ip-light radius-xs b-light px-15" id="" placeholder="Officer Name" disabled value={purticularUserDetails.user.name}/></td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td><input type="text" className="form-control ih-small" id="" placeholder="Email" disabled value={purticularUserDetails.user.email}/></td>
                                            <td>Phone Number</td>
                                            <td><input type="text" className="form-control ih-small" id="" placeholder="Phone Number" disabled value={purticularUserDetails.user.mobile}/></td>
                                        </tr>
                                        <tr>
                                            <td>Main Language</td>
                                            <td><Select
                                                    name="main_language"
                                                    isMulti={false}
                                                    // onChange={handleChange}
                                                    value={array_selected_language(purticularUserDetails.languages,'main')}
                                                    isSearchable={false}
                                                    isDisabled={true}
                                                    placeholder="Select Main Language"
                                                /></td>
                                            <td>Written</td>
                                            <td><Select
                                                    name="written_language"
                                                    isMulti={true}
                                                    // onChange={handleChange}
                                                    value={array_selected_language(purticularUserDetails.languages,'written')}
                                                    isSearchable={false}
                                                    isDisabled={true}
                                                    placeholder="Written Main Language"
                                                /></td>
                                        </tr>
                                        <tr>
                                            <td>Other Languages</td>
                                            <td> <Select
                                                    name="others_language"
                                                    isMulti={true}
                                                    // onChange={handleChange}
                                                    value={array_selected_language(purticularUserDetails.languages,'others')}
                                                    isSearchable={false}
                                                    isDisabled={true}
                                                    placeholder="other Main Language"
                                                /></td>

                                            <td>Religion</td>
                                            <td> <div className="atbd-select ">
                                                <select className="form-control" disabled>
                                                    <option value="01">{purticularUserDetails.religion_name}</option> */}
                                {/* <option value="02">Islam</option> */}
                                {/* <option value="03">Hindu</option> */}
                                {/* </select>
                                            </div></td>
                                        </tr>
                                        <tr>

                                            <td>Denomination</td>
                                            <td> <div className="atbd-select">
                                                <select className="form-control" disabled>
                                                    <option value="01">{purticularUserDetails.denomination_name}</option> */}
                                {/* <option value="02">Islam</option>
                                                    <option value="03">Hindu</option> */}
                                {/* </select>
                                            </div></td>
                                            <td>Gender</td>
                                            <td>
                                                <div className="atbd-select ">
                                                    <select className="form-control" disabled>
                                                        <option value="01">{checkGender(purticularUserDetails.user.gender)}</option> */}
                                {/* <option value="01">Male</option> */}
                                {/* <option value="02">Female</option> */}
                                {/* </select>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Nationality</td>
                                            <td><div className="atbd-select ">
                                                <select className="form-control" disabled>
                                                    <option value="01">{purticularUserDetails.user.nationality_name}</option>
                                                    <option value="01">Indian</option>
                                                    <option value="02">USA</option>
                                                </select>
                                            </div></td>
                                            <td>Age</td>
                                            <td><div className="atbd-select">
                                                <select className="form-control" disabled>
                                                    <option value="01">{age(purticularUserDetails.user.dob)}</option> */}
                                {/* <option value="02">22</option> */}
                                {/* </select>
                                            </div></td>
                                        </tr>
                                        <tr>
                                            <td>Books Associated</td>
                                            <td><div className="form-group row">
                                                <div className="col-sm-12">
                                                    <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15" id="inputName" placeholder="Add Negative Values" disabled />
                                                </div>
                                            </div>
                                                <div className='row mb-20 pb-20 border-bottom'>
                                                    <div className='col-12'>
                                                        <div className="atbd-tag-wrap">
                                                            <div className="tag-box">
                                                                {purticularUserDetails.books?.map((book)=>{return(
                                                                <span className="atbd-tag tag-light">{book.name} <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                                )})} */}
                                {/* <span className="atbd-tag tag-light">Tag 3 <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                                <span className="atbd-tag tag-light">Tag 3 <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                                <span className="atbd-tag tag-light">Tag 3 <a href="#" className="tag-closable"><i className="la la-times"></i></a></span> */}
                                {/* </div>
                                                        </div>
                                                    </div>
                                                </div></td>
                                            <td>Religious Channel Profile Name</td>
                                            <td><input className='form-control' type='text' disabled value={purticularUserDetails.profile_name}/></td>
                                        </tr> 

                                        
                                        <tr>
                                            <td>Roles Assigned</td>
                                            <td>
                                            <Multiselect
                                                options={options}
                                                onSelect={onSelect}
                                                onRemove={onSelect}
                                                displayValue="value"
                                            />                                      
                                            </td>
                                            <td>Target</td>
                                            <td>
                                                <input type="text" className="form-control ih-small ip-light radius-xs b-light px-15" id="" placeholder="10" onChange={editInput} name="target" value={purticularUserDetails.target || ""} />
                                                <span style={{color:"red"}}>{approveErrors.target}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Login time</td>
                                            <td><input className='form-control' type='time' onChange={editInput} name="login_time" value={purticularUserDetails.login_time || ""}/>
                                            <span style={{color:"red"}}>{approveErrors.login_time}</span>
                                            </td>
                                            <td>Logout time</td>
                                            <td><input className='form-control' type='time' onChange={editInput} name="logout_time" value={purticularUserDetails.logout_time || ""}/>
                                            <span style={{color:"red"}}>{approveErrors.logout_time}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Status</td>
                                            <td>
                                                <select className='form-control' name="role_status" onChange={editInput}>
                                                <option>Status</option>
                                                {adminRoleStatusLists?.map((adminRoleStatusList,key)=>{
                                                    return(
                                                    <option value={adminRoleStatusList.id} key={key} selected={purticularUserDetails.role_status == adminRoleStatusList.id ? "selected" : ""}>{adminRoleStatusList.name}</option>
                                                )})}
                                            </select>
                                            <span style={{color:"red"}}>{approveErrors.role_status}</span>
                                            </td>
                                            
                                            <td>Break time</td>
                                            <td><input className='form-control' type='time' onChange={editInput} name="break_time" value={purticularUserDetails.break_time || ""}/>
                                            <span style={{color:"red"}}>{approveErrors.break_time}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Internal Email</td>
                                            <td><input className='form-control ih-small ip-light radius-xs b-light px-15' type='text' onChange={editInput} name="internal_email" placeholder='Enter internal email' value={purticularUserDetails.internal_email || ""}/>
                                            <span style={{color:"red"}}>{approveErrors.internal_email}</span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div> */}
                                {/* <div className="modal-footer">
                                <button type="button" className="btn btn-success btn-sm" onClick={() => submitUserRolesDetails(purticularUserDetails.user_id)}>Save</button>
                                <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal">Cancel</button>
                            </div> */}

                            </div>
                        </div>
                    </div>
                }
                <div className="modal fade" id="vivewdocument" tabindex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="example-Modal3">View Officer</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
                            </div>
                            <div className="modal-body hub-view-height">
                                <table className='table m-0'>
                                    <tr>
                                        <td>Profile Picture</td>
                                        <td><div className='user-img'><span className="avatar avatar-light avatar-lg avatar-circle">
                                            <img className="avatar-img" src="/assets/img/author/1.jpg" alt="" />
                                        </span></div></td>
                                    </tr>
                                    <tr>
                                        <td>Role</td>
                                        <td>Role Name</td>
                                    </tr>
                                    <tr>
                                        <td>Date Registered</td>
                                        <td>01/06/2023</td>
                                    </tr>
                                    <tr>
                                        <td>Main Language</td>
                                        <td>tamil</td>
                                    </tr>
                                    <tr>
                                        <td>Written Language</td>
                                        <td>Tamil</td>
                                    </tr>
                                    <tr>
                                        <td>Other Language</td>
                                        <td>English</td>
                                    </tr>
                                    <tr>
                                        <td>Books Associated</td>
                                        <td><div className="atbd-tag-wrap">
                                            <div className="tag-box"><span className="atbd-tag tag-light">Positive values <Link to="#" className="tag-closable"><i className="la la-times"></i></Link></span>
                                                <span className="atbd-tag tag-light">Tag 3 <Link to="#" className="tag-closable"><i className="la la-times"></i></Link></span>
                                                <span className="atbd-tag tag-light">Tag 3 <Link to="#" className="tag-closable"><i className="la la-times"></i></Link></span>
                                                <span className="atbd-tag tag-light">Tag 3 <Link to="#" className="tag-closable"><i className="la la-times"></i></Link></span>
                                            </div>
                                        </div></td>
                                    </tr>
                                    <tr>
                                        <td>Religious channel profile name</td>
                                        <td>Profile Name</td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td>test@gmail.com</td>
                                    </tr>
                                    <tr>
                                        <td>Phone Number</td>
                                        <td>+91 987 456 1230</td>
                                    </tr>
                                    <tr>
                                        <td>Roles Assigned</td>
                                        <td><div className="list-box">
                                            <ul>
                                                <li className="list-box__item">Role Name1</li>
                                                <li className="list-box__item">Role Name2</li>
                                                <li className="list-box__item"><Link to='#'>Add Role +</Link></li>
                                            </ul>
                                        </div></td>
                                    </tr>
                                    <tr>
                                        <td>Login Time</td>
                                        <td>16:30:45</td>
                                    </tr>
                                    <tr>
                                        <td>Logout Time</td>
                                        <td>16:30:45</td>
                                    </tr>
                                    <tr>
                                        <td>Break Times</td>
                                        <td><table className='table'>
                                            <thead>
                                                <th>Break</th>
                                                <th>Time</th>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Lunch</td>
                                                    <td>12:30</td>
                                                </tr>
                                                <tr>
                                                    <td>Tea</td>
                                                    <td>16:30</td>
                                                </tr>
                                            </tbody>
                                        </table></td>
                                    </tr>
                                    <tr>
                                        <td>Set Target</td>
                                        <td><div className='atbd-button-list d-flex flex-wrap'> <span>145</span> <button className='btn btn-default btn-warning'>Edit</button></div></td>
                                    </tr>
                                    <tr>
                                        <td>Achieved target daily</td>
                                        <td><span>145</span></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-success btn-sm">Save</button>
                                <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="profileview" tabindex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-body text-center">
                               
                                <div className='pop-height-img'>
                                <img className='profile-img' src='/assets/img/login-bg.png' />
                                </div>
                            </div>
                            {/* <div className="modal-footer">
                                <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal">Cancel</button>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="personaldocment" tabindex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-body align-center">
                                <object type="application/pdf" data="/assets/sample.pdf" width="100%" height="600px"></object>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </main >

        </>
    );
}
export default HomeHubOfficers;