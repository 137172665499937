import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from '../../layouts/header';
import Footer from '../../layouts/footer';





const Calendar = ({setIsShowHeader}) => {
    useEffect(()=>{
        setIsShowHeader(true);
    },[])
    return (
        <>
            {/* <Header></Header> */}
            <main className="main-content">
                <div className="contents no-left-pane">
                    <div className="container-fluid mt-20">
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className="card">
                                    <div className="card-header color-dark fw-500">
                                        <div className='row w-100'><div className='col-lg-6'><h6>Calendar</h6></div><div className='col-lg-6 justify-content-end'><button className="btn btn-secondary btn-create-event mr-10" data-toggle="modal" data-target="#c-event-modal">
                                            <span data-feather="plus"></span>Create New Event</button>  <button className='btn btn-warning btn-default' data-toggle="modal" data-target="#modal-basic"> Add Caledar +</button></div></div>
                                    </div>
                                    <div className="card-body">
                                    
                                    <div id="calendar" className="full_calendar cal-height"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
                <div className="c-event-modal modal fade" id="c-event-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-md c-event-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title">Create Event</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span data-feather="x"></span></button>
                        </div>
                        <div className="modal-body">
                            <div className="c-event-form">
                                <form action="#">
                                    <div className="e-form-row d-flex">
                                        <div className="e-form-row__left">
                                            <label>Title</label>
                                        </div>
                                        <div className="e-form-row__right">
                                            <input type="text" name="e-title" placeholder="Weekly report meeting" className="form-control form-control-md"/>
                                        </div>
                                    </div>
                                
                                    <div className="e-form-row d-flex">
                                        <div className="e-form-row__left">
                                            <label>Event type</label>
                                        </div>
                                        <div className="e-form-row__right">
                                            <div className="radio-horizontal-list d-flex flex-wrap">
                                                <div className="radio-theme-default custom-radio ">
                                                    <input className="radio" type="radio" name="radio-e-type" value="01" id="radio-1"/>
                                                    <label for="radio-1">
                                                        <span className="radio-text">Event</span>
                                                    </label>
                                                </div>
                                                <div className="radio-theme-default custom-radio ">
                                                    <input className="radio" type="radio" name="radio-e-type" value="02" id="radio-2"/>
                                                    <label for="radio-2">
                                                        <span className="radio-text">Remainder</span>
                                                    </label>
                                                </div>
                                                <div className="radio-theme-default custom-radio ">
                                                    <input className="radio" type="radio" name="radio-e-type" value="03" id="radio-3"/>
                                                    <label for="radio-3">
                                                        <span className="radio-text">Task</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="e-form-row d-flex">
                                        <div className="e-form-row__left">
                                            <label>Start</label>
                                        </div>
                                        <div className="e-form-row__right d-flex">
                                            <div className="input-container icon-left position-relative mr-2">
                                                <span className="input-icon icon-left">
                                                    <span data-feather="calendar"></span></span>
                                                <input type="text" className="form-control form-control-md" name="s-date" placeholder="Select Date"/>
                                            </div>
                                            <div className="input-container icon-left position-relative">
                                                <span className="input-icon icon-left">
                                                    <span data-feather="clock"></span></span>
                                                <input type="text" className="form-control form-control-md" name="s-time" placeholder="Select Time"/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="e-form-row d-flex">
                                        <div className="e-form-row__left">
                                            <label>End</label>
                                        </div>
                                        <div className="e-form-row__right d-flex">
                                            <div className="input-container icon-left position-relative mr-2">
                                                <span className="input-icon icon-left">
                                                    <span data-feather="calendar"></span></span>
                                                <input type="text" className="form-control form-control-md" name="e-date" placeholder="Select Date"/>
                                            </div>
                                            <div className="input-container icon-left position-relative">
                                                <span className="input-icon icon-left">
                                                    <span data-feather="clock"></span></span>
                                                <input type="text" className="form-control form-control-md" name="e-time" placeholder="Select Time"/>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div className="e-form-row d-flex">
                                        <div className="e-form-row__left">
                                            <label>Description</label>
                                        </div>
                                        <div className="e-form-row__right">
                                            <textarea name="e-description" className="form-control form-control-md" placeholder="Add Description"></textarea>
                                        </div>
                                    </div>
                                    
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-white btn-sm" data-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary btn-sm">Save</button>
                        </div>
                    </div>
                </div>
            </div>
                <div className="e-info-modal modal fade" id="e-info-modal" tabindex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-sm e-info-dialog modal-dialog-centered" id="c-event" role="document">
                        <div className="modal-content">
                            <div className="modal-header e-info-header bg-primary">
                                <h6 className="modal-title e-info-title">Project Update</h6>
                                <div className="e-info-action">
                                    <button className="btn-icon">
                                        <span data-feather="edit"></span></button>
                                    <button className="btn-icon">
                                        <span data-feather="mail"></span></button>
                                    <button className="btn-icon">
                                        <span data-feather="trash-2"></span></button>
                                    <button type="button" className="btn-icon btn-close" data-dismiss="modal" aria-label="Close">
                                        <span data-feather="x"></span></button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <ul className="e-info-list">
                                    <li>
                                        <span data-feather="calendar"></span>
                                        <span className="list-line">
                                            <span className="list-label">Date :</span>
                                            <span className="list-meta"> Thursday, January 23</span>
                                        </span>
                                    </li>
                                    <li>
                                        <span data-feather="clock"></span>
                                        <span className="list-line">
                                            <span className="list-label">Time :</span>
                                            <span className="list-meta"> 23⋅5:00 – 6:00 am</span>
                                        </span>
                                    </li>
                                    <li>
                                        <span data-feather="align-left"></span>
                                        <span className="list-line">
                                            <span className="list-text"> Lorem ipsum dolor sit amet consetetur sadipscing elitr sed diam consetetur sadipscing elitr sed diam</span>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </main >

        </>
    );
}
export default Calendar;