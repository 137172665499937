import React, { useEffect, useState } from 'react'
import Header from '../layouts/header';
import Footer from '../layouts/footer';
import { ProfileApi, UpdateUserProfle } from '../Api/ApiCore';
import { Navigate, useNavigate, Link, useParams } from 'react-router-dom';
import { getBooks, getDenomination, getReligion } from '../Api/ApiUnAuth';
import { AddUserDetails } from '../Api/ApiUser';
import { getMcc, getNationality } from '../Api/ApiCoreUnAuth';
import Multiselect from 'multiselect-react-dropdown';
import Select from 'react-select';
import ReactFlagsSelect from 'react-flags-select';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CancelIcon from '@mui/icons-material/Cancel';

const UserDetails = ({ allLanguageLists }) => {
    const { authid } = useParams();
    
    const [userProfile, setUserProfile] = useState();
    const [userProfileValues, setUserProfileValues] = useState();
    const [userDetailValues, setUserDetailValues] = useState({
        profile_name: "",
        religion_id: "",
        denomination_id: "",
        book_associated: "",
        book_lists: []
    });
    const [religions, setReligions] = useState([]);
    const [denominations, setDenominations] = useState([]);
    const [title, setTitle] = useState({
        denomination: "Select Religion(Denomination)",
        books_associated: "Select Religion (Books list)",

    })
    const [books, setBooks] = useState([])
    const [userDetailsError, setUserDetailsError] = useState({
        profile_name: "",
        religion_id: "",
        denomination_id: "",
    })
    const [userProfleError, setUserProfleError] = useState({
        name: "",
        email: "",
        mcc_id: "",
        moble: "",
        gender: "",
    })
    const [mccs, setMccs] = useState([]);
    const [profileSubmiit, setProfileSubmiit] = useState(0);
    const [nationalityLists, setNationalityLists] = useState([]);
    const [bookLoading, setBookLoading] = useState(false)
    const redirect = useNavigate();
    useEffect(() => {
        getMcc().then((res) => {
            setMccs(res.data.data);
            //     countries={countryArray.map((country) => country.country_code)}  
            //     customLabels={{
            //         "US": { primary: "+1"},
            //         "GB": { primary: "+44"},
            //         "FR": { primary: "+!2" }
            //     }}        }).catch((err)=>{

        })
    }, [])
    useEffect(() => {
        getNationality().then((res) => {
            setNationalityLists(res.data.data);
        }).catch((err) => {

        })
    }, [])
    useEffect(() => {
        ProfileApi().then((res) => {
            setUserProfile(res.data.data);
        }).catch((err) => {
            return redirect('/');
        })
    }, [])


    function enterDetailFunction(e) {
        setUserDetailsError((prev) => ({ ...prev, [e.target.name]: "" }))
        if (e.target.name == "book_associated" && userDetailValues.religion_id !== "") {
            getBookList(userDetailValues.religion_id, e.target.value);
        }
        setUserDetailValues(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    }
    function enterProfileFunction(e) {
        setUserProfleError((prev) => ({ ...prev, [e.target.name]: "" }))
        setUserProfile(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }
    function getBookList(religion_id, search) {
        getBooks(religion_id, search).then((res) => {
            const book_options = res.data.data.map((bookList, key) =>
            ({
                key: key,
                label: bookList.name,
                value: bookList.id,
            }))
            setBooks(book_options)
        }).catch((err) => {

        });
    }
    function bookClick(book) {
        setUserDetailsError((prev) => ({ ...prev, ["book_list"]: "" }))
        const bookAlreadyExists = userDetailValues.book_lists.some(existingBook => existingBook.id === book.id);
        if (!bookAlreadyExists) {
            const newBook = { id: book.id, name: book.name };
            setUserDetailValues(prevState => (
                {
                    ...prevState,
                    book_lists: [...prevState.book_lists, newBook],
                    book_associated: ""
                }

            ));
            setBooks([]);
        }
    }
    function removeBookList(id) {
        const updatedBookLists = userDetailValues.book_lists.filter(existingBook => existingBook.id !== id);
        setUserDetailValues(prevState => ({
            ...prevState,
            book_lists: updatedBookLists
        }));
    }
    function userDetailsSubmit() {
        const languagesArray = userDetailValues.languages || [];
        const mainLanguages = languagesArray.filter(lang => lang.isMain === 1);
        const writtenLanguages = languagesArray.filter(lang => lang.isWritten === 1);
        if (!userDetailValues.religion_id || !userDetailValues.denomination_id, userDetailValues.book_lists.length == 0 || mainLanguages.length === 0 || writtenLanguages.length === 0 || (mainLanguages == 1 && languagesArray.length == 1) || !userDetailValues.profile_name) {
            const updatedErrorState = {
                religion_id: !userDetailValues.religion_id ? "religion is required." : "",
                denomination_id: !userDetailValues.denomination_id ? "denomination is required." : "",
                book_list: userDetailValues.book_lists.length == 0 ? "books is required." : "",
                main_language: mainLanguages.length === 0 ? "main language is required." : "",
                writting_language: writtenLanguages.length === 0 ? "writting language is required." : "",
                other_language: (mainLanguages == 1 && languagesArray.length == 1) || (languagesArray.length == 0) ? "other language is required" : "",
                profile_name: !userDetailValues.profile_name ? "profile name is required" : ""
            }
            setUserDetailsError(updatedErrorState)
        }
        else {
            AddUserDetails(userDetailValues).then((res) => {
                return redirect(`/u/${authid}/auth`);
            }).catch((err) => {
                const updatedErrorState = {
                    profile_name: err.response.data.error.profile_name || "",
                    religion_id: err.response.data.error.religion_id || "",
                    denomination_id: err.response.data.error.denomination_id || "",
                };
                setUserDetailsError(updatedErrorState);
            })
        }
    }
    function profileDetailsSubmit() {
        if (!userProfile.name || !userProfile.email || !userProfile.mcc_id || !userProfile.mobile || !userProfile.gender || !userProfile.nationality) {
            const updatedErrorState = {
                name: !userProfile.name ? "Name is required." : "",
                email: !userProfile.email ? "Email is required." : "",
                mcc_id: !userProfile.mcc_id ? "MCC ID is required." : "",
                mobile: !userProfile.mobile ? "Mobile is required." : "",
                gender: !userProfile.gender ? "Gender is required." : "",
                nationality: !userProfile.nationality ? "Nationlaity is required." : ""
            };
            setUserProfleError(updatedErrorState);
        }
        else {
            const sentValue = {
                name: userProfile.name,
                email: userProfile.email,
                mcc_id: userProfile.mcc_id,
                mobile: userProfile.mobile,
                gender: userProfile.gender,
                country_id: userProfile.nationality
            }
            UpdateUserProfle(sentValue).then((res) => {
                setProfileSubmiit(1);
            }).catch((err) => {
                const updatedErrorState = {
                    name: err.response.data.error.name || "",
                    email: err.response.data.error.email || "",
                    mcc_id: err.response.data.error.mcc_id || "",
                    mobile: err.response.data.error.mobile || "",
                    gender: err.response.data.error.gender || "",
                };
                setUserProfleError(updatedErrorState);
            })
        }
    }
    const [languageLists, setLanguageLists] = useState([]);
    useEffect(() => {
        const languagesArray = userDetailValues.languages || [];
        const filteredLanguages = allLanguageLists.filter(lang =>
            !languagesArray.some(selectedLang => selectedLang.value === lang.id)
        );
        const filter = filteredLanguages.map((languageList, key) =>
        ({
            key: key,
            label: languageList.name,
            value: languageList.id,
            isMain: 0,
            isWritten: 0
        }))
        setLanguageLists(filter);
    }, [allLanguageLists, userDetailValues])

    useEffect(() => {
        getReligion().then((res) => {
            const religions = res.data.data.values.map((rel, key) => ({
                key: key,
                label: rel.tag.name,
                value: rel.tag.id
            }))
            setReligions(religions);
        }).catch((err) => {

        })
    }, [])
    const handleChange = (selectedValue, actionMeta) => {
        setUserDetailsError((prev) => ({ ...prev, [actionMeta.name]: "" }))
        if (actionMeta.name == "religion_id") {
            getDenomination(selectedValue.value).then((res) => {
                const denominationLists = res.data.data.values.map((rel, key) => ({
                    key: key,
                    label: rel.sub_tag.name,
                    value: rel.sub_tag.id,
                }))
                setDenominations(denominationLists);
                setTitle({ denomination: "Select Denomination", books_associated: "Search Books" })
                setUserDetailValues((prevState) => ({
                    ...prevState,
                    denomination_id: "",
                }));
            }).catch((err) => {

            })
        }
        if (actionMeta.name == "main_language") {
            selectedValue.isMain = 1;

            const languagesArray = userDetailValues.languages || [];
            const mainLanguageIndex = languagesArray.findIndex((lang) => lang.isMain === 1)
            if (mainLanguageIndex !== -1) {
                const updatedLanguages = [...languagesArray];
                updatedLanguages[mainLanguageIndex] = selectedValue;
                setUserDetailValues((prevState) => ({
                    ...prevState,
                    languages: updatedLanguages,
                }));
            }
            else {
                setUserDetailValues((prevState) => ({
                    ...prevState,
                    languages: [...(prevState.languages || []), selectedValue],
                }));
            }
        }
        else if (actionMeta.name == "other_language") {
            if (actionMeta.action == "remove-value" || actionMeta.action == "pop-value") {
                const filter = userDetailValues.languages.filter((lang) => lang.value !== actionMeta.removedValue.value)
                setUserDetailValues(prevState => ({
                    ...prevState, languages: filter
                }));
            }
            else {
                const languagesArray = userDetailValues.languages || [];
                const other_lang_index = languagesArray.findIndex((lang) => lang.value === actionMeta.option.value);
                if (other_lang_index == -1) {
                    setUserDetailValues(prevState => ({
                        ...prevState,
                        languages: [...(prevState.languages || []), actionMeta.option]
                    }));
                }
            }
        }
        else if (actionMeta.name == "writting_language") {
            const languagesArray = userDetailValues.languages || [];
            let mainLanguageIndex;
            let updateValue;
            if (actionMeta.action == "remove-value" || actionMeta.action == "pop-value") {
                updateValue = actionMeta.removedValue;
                updateValue.isWritten = 0;
                mainLanguageIndex = languagesArray.findIndex((lang) => lang.value === actionMeta.removedValue.value)
            }
            else {
                updateValue = actionMeta.option;
                updateValue.isWritten = 1;
                mainLanguageIndex = languagesArray.findIndex((lang) => lang.value === actionMeta.option.value)
            }

            if (mainLanguageIndex !== -1) {
                const updatedLanguages = [...languagesArray];
                updatedLanguages[mainLanguageIndex] = updateValue;

                setUserDetailValues((prevState) => ({
                    ...prevState,
                    languages: updatedLanguages,
                }));
            }
        }
        else if (actionMeta.name == "books_associated") {
            setUserDetailsError((prev) => ({ ...prev, ["book_list"]: "" }))
            if (actionMeta.action == "remove-value" || actionMeta.action == "pop-value") {
                const filter = userDetailValues.book_lists.filter((book) => book.value !== actionMeta.removedValue.value)
                setUserDetailValues(prevState => ({
                    ...prevState, book_lists: filter
                }));
            }
            else {
                setUserDetailValues(prevState => ({
                    ...prevState,
                    book_lists: [...(prevState.book_lists || []), actionMeta.option]
                }));
            }

        }
        else {
            setUserDetailValues(prevState => ({
                ...prevState,
                [actionMeta.name]: selectedValue.value
            }));
        }
    }
    const loadOptions = (inputValue) => {
        if (!inputValue) {
            return;
        }
        else {
            setBookLoading(true);
            if (userDetailValues.religion_id !== "") {
                getBookList(userDetailValues.religion_id, inputValue);
                setBookLoading(false);
            }
        }
    }
    const [profilePicture, setProfilePicture] = useState(null);

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicture(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCancelProfilePicture = () => {
    setProfilePicture(null);
  };
  const [selectedOptions, setSelectedOptions] = useState([]);

    // Handler for when options are selected/deselected
    const onSelect = (selectedList, selectedItem) => {
      setSelectedOptions(selectedList);
    };
  const options = [
    { key: 'option1', value: 'Option 1' },
    { key: 'option2', value: 'Option 2' },
    { key: 'option4', value: 'Option 4' },
    { key: 'option5', value: 'Option 5' },
    { key: 'option6', value: 'Option 6' },
    { key: 'option7', value: 'Option 7' },
    { key: 'option8', value: 'Option 8' },
    { key: 'option9', value: 'Option 9' },
    { key: 'option10', value: 'Option 10' },
    // Add more options as needed
  ]; 
    return (
        <>
            <Header></Header>
            <main className="main-content">
                <div className="contents no-left-pane">
                    <div className="container-fluid mt-20">
                        <div className='row'>
                            <div className='col-3'>&nbsp;</div>
                            {userProfile ?
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    {profileSubmiit == 0 &&
                                        <div className="card card-Vertical card-default card-md mb-4">
                                            <div className="card-header">
                                                <h6>User Registration</h6>
                                            </div>
                                        <Tabs>   
                                        <div className='tabsection'>
                                        <TabList className="tab-list " style={{ }}>
                                            <Tab className="tab-item ">Personal Details</Tab>
                                            <Tab className="tab-item">Professional Details</Tab>
                                            <Tab className="tab-item">Job Specifies</Tab>
                                        
                                        </TabList>
                                        
                                        </div>
                                        <TabPanel>                         
                                        <div className="modal-body">
                                                <div className="personal-details-container">
                                                    <div className="personal-details-section">
                                                        
                                                        <div className="profile-picture-section">
                                                            <label htmlFor="profile">Profile Picture</label>
                                                            <div className="form-group">
                                                            {profilePicture ? (
                                                    <div className="profile-picture-container" style={{ position: 'relative' }}>
                                                        <img
                                                        src={profilePicture}
                                                        alt="Profile"
                                                        className="profile-picture rounded-circle"
                                                        style={{ maxWidth: '100px', height: '100px', borderRadius: '15px' }}
                                                        />
                                                        <button
                                                        className="cancel-button"
                                                        onClick={handleCancelProfilePicture}
                                                        style={{ position: 'absolute', top: '-18px', left: '86px', background: 'none', border: 'none', padding: '5px' }}
                                                        >
                                                        <CancelIcon style={{ width: '15px', height: '15px' }}/> {/* Render the Cancel icon */}
                                                        </button>
                                                    </div>
                                                    ) : (
                                                    <label htmlFor="profile" className="btn btn-outline-success" style={{ maxWidth: '270px', height: '40px', borderRadius: '15px' }}>
                                                        + Upload Profile Picture
                                                        <input
                                                        type="file"
                                                        id="profile"
                                                        className="form-control"
                                                        style={{ display: 'none' }}
                                                        onChange={handleProfilePictureChange}
                                                        />
                                                    </label>
                                                    )}
                                                                
                                                    <small className="form-text text-muted">Max file size: 5MB</small>
                                                </div>
                                                </div>
                                                <div className='row '>
                                                    <div  className="form-group col-6 pl-20">
                                                    <label htmlFor="name">Name</label>
                                                    <input type="text" className="form-control" placeholder="Officer Name" name="name" value={userProfile.name} onChange={enterProfileFunction} />
                                                            <span className='danger' style={{ color: 'red' }}>{userProfleError.name}</span>
                                                    </div>    
                                                    
                                                    <div className="form-group col-6 pr-20">
                                                        <div className="form-group">
                                                            <label htmlFor="dob">Date of Birth</label>
                                                            <input type="text" className="form-control" placeholder="" Type='date'  />
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                
                                                <div className='row '>
                                               
                                                <div className="form-group col-6 pl-20">
                                                    <label htmlFor="phone">Phone Number</label>
                                                    <div className="input-group ">
                                                        <div className="input-group-prepend " >
                                                            <ReactFlagsSelect
                                                                countries={mccs.map((country) => country.country_code)}
                                                                placeholder="Select code"
                                                                
                                                                customLabels={mccs.reduce((labels, country) => {
                                                                    labels[country.country_code] = { primary: `+${country.code}` };
                                                                    return labels;
                                                                }, {})}
                                                                onSelect={(countryCode) =>
                                                                    setUserProfile(prevState => ({
                                                                        ...prevState,
                                                                        ["mcc_id"]: mccs.find((mcc) => mcc.country_code == countryCode).id
                                                                    }))}
                                                                selected={userProfile.mcc_id ? mccs.find((mcc) => mcc.id == userProfile.mcc_id).country_code : "US"}
                                                                searchable={true}
                                                            />
                                                        </div>
                                                        <input type="text" className="form-control" placeholder="Phone Number" value={userProfile.mobile} name="mobile" onChange={enterProfileFunction} />
                                                    <span className='danger' style={{ color: 'red' }}>{userProfleError.mobile}</span>
                                                    </div> 
                                                       
                                                </div>
 
                                                    <div className="form-group col-6 pr-20">
                                                        <label htmlFor="name">Email:</label>
                                                        <input type="text" className="form-control" placeholder="Email" name="email" value={userProfile.email} onChange={enterProfileFunction} />
                                                        <span className='danger' style={{ color: 'red' }}>{userProfleError.email}</span>
                                                    </div>
                                                </div>
                                                <div className='row '>
                                                    <div className="form-group col-6 pl-20">
                                                         <label htmlFor="name">Nationality</label>
                                                            <select name="nationality" id="" className="form-control" placeholder="Nationality" onChange={enterProfileFunction}>
                                                                <option value="">Select Nationality</option>
                                                                {nationalityLists && nationalityLists.map((nationalityList) => {
                                                                    return (
                                                                        <option value={nationalityList.id} selected={nationalityList.id === userProfile.nationality}>{nationalityList.name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            <span className='danger' style={{ color: 'red' }}>{userProfleError.nationality}</span>                                           
                                                    </div>
                                           </div>                                                                                                                                
                                    </div>
                                </div>
                                <div className="modal-footer">
                                <div className="layout-button mt-0">
                                    <button type="button" className="btn btn-success btn-sm" >Cancel</button>
                                    <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal" onClick={profileDetailsSubmit}>save</button>
                                </div>
                                </div>
                            </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="modal-body">                       
                                        
                                            <div className="professional-details-container">
                                                <div className="professional-details-section">
                                                   
                                                    <div className='row'>
                                                        <div className="form-group col-6 pl-20">
                                                        
                                                            <label htmlFor="academic_documents">Academic History </label>
                                                            <input type="file" id="academic_documents" className="form-control" placeholder='Documents upload' />
                                                        </div>                                             
                                                        <div className="form-group col-6 pr-20">
                                                            <label htmlFor="work_documents">Work History</label>
                                                            <input type="file" id="work_documents" className="form-control" placeholder='Documents upload'  />
                                                        </div>
                                                    </div> 
                                                    <h6 className='mb-4'>Languages</h6>
                                                    <div className='row'>
                                                        <div className="form-group col-6 pl-20">
                                                        <label htmlFor="name">Main Language:</label>
                                                            <Select
                                                                name="main_language"
                                                                isMulti={false}
                                                                onChange={handleChange}
                                                                options={languageLists}
                                                                isSearchable={true}
                                                                isClearable={false}
                                                                placeholder="Select Main Language"
                                                            />
                                                            <span className='danger' style={{ color: 'red' }}>{userDetailsError.main_language}</span>
                                                        </div>
                                                        <div className="form-group col-6 pr-20">
                                                        <label htmlFor="name">Writting Language:</label>
                                                            <Select
                                                                name="writting_language"
                                                                isMulti={true}
                                                                value={userDetailValues.languages?.filter((lang) => lang.isWritten == 1)}
                                                                onChange={handleChange}
                                                                options={userDetailValues.languages}
                                                                isSearchable={true}
                                                                isClearable={false}
                                                                placeholder="Select Written Language"
                                                            />
                                                            <span className='danger' style={{ color: 'red' }}>{userDetailsError.writting_language}</span>                                                          
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-6 pl-20">
                                                        <label htmlFor="name">Other Language:</label>
                                                            <Select
                                                                name="other_language"
                                                                isMulti={true}
                                                                // value={selectedOption}
                                                                onChange={handleChange}
                                                                options={languageLists}
                                                                isClearable={false}
                                                                isSearchable={true}
                                                                placeholder="Select Other Language"
                                                            />
                                                            <span className='danger' style={{ color: 'red' }}>{userDetailsError.other_language}</span>                                                       
                                                        </div>
                                                        <div className="form-group col-6 pr-20">
                                                            <label htmlFor="skills">Skills</label>
                                                            <Select
                                                            name="skills"
                                                            isMulti
                                                           
                                                            options={[
                                                                { value: 'skill1', label: 'Skill 1' },
                                                                { value: 'skill2', label: 'Skill 2' },
                                                                { value: 'skill3', label: 'Skill 3' },
                                                                { value: 'skill3', label: 'Skill 4' },
                                                                { value: 'skill3', label: 'Skill 5' },
                                                                { value: 'skill3', label: 'Skill 6' },
                                                                // Add more options as needed
                                                            ]}
                                                            />
                                                        </div>
                                                    </div>
                                                    

                                                <h6 className='mb-4'>Addresses</h6>
                                                <div className='row'>
                                                    <div className="form-group col-6 pl-20">
                                                        <label htmlFor="address_line1">Address Line 1</label>
                                                        <input type="text" id="address_line1" className="form-control " placeholder="Enter Address Line 1" />
                                                    </div>
                                                    <div className="form-group col-6 pr-20">
                                                        <label htmlFor="address_line2">Address Line 2</label>
                                                        <input type="text" id="address_line2" className="form-control " placeholder="Enter Address Line 2" />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="form-group col-6 pl-20">
                                                        <label htmlFor="city_town">City/Town</label>
                                                        <input type="text" id="city_town_1" className="form-control" placeholder="Enter City/Town" />
                                                    </div>
                                                    <div className="form-group col-6 pr-20">
                                                        <label htmlFor="state_region">State/Region</label>
                                                        <input type="text" id="state_region_1" className="form-control" placeholder="Enter State/Region" />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="form-group col-6 pl-20">
                                                        <label htmlFor="zip_code">Zip/Postal Code</label>
                                                        <input type="text" id="zip_code_1" className="form-control" placeholder="Zip/Postal Code" />
                                                    </div>
                                                    <div className="form-group col-6 pr-20">
                                                        <label htmlFor="country">Country</label>
                                                        <input type="text" id="country_1" className="form-control" placeholder="Enter Country" />
                                                    </div>
                                                </div>
                                                </div>
                                               
                                            </div>
                                        
                                        <div className="modal-footer">
                                            <div className="layout-button mt-0">
                                                <button type="button" className="btn btn-success btn-sm" >Cancel</button>
                                                <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal" onClick={profileDetailsSubmit}>save</button>
                                            </div>
                                        </div>
                            </div>
                            </TabPanel>
                            <TabPanel>
                            <div className="modal-body ">
                                       
                                            <div className="job-details-container">
                                                <div className="job-details-section">
                                                   
                                                    <div className='row'>
                                                        <div className="form-group col-6 pl-20">
                                                            <label htmlFor="name">Religion:</label>
                                                                <Select
                                                                    name="religion_id"
                                                                    isMulti={false}
                                                                    // value={selectedOption}
                                                                    onChange={handleChange}
                                                                    options={religions}
                                                                    isSearchable={true}
                                                                    placeholder="Select Religion"
                                                                />
                                                                <span className='danger' style={{ color: 'red' }}>{userDetailsError.religion_id}</span>
                                                            
                                                        </div>
                                                        <div className="form-group col-6 pr-20">
                                                        <label htmlFor="name">Denomination:</label>
                                                            <Select
                                                                name="denomination_id"
                                                                isMulti={false}
                                                                // value={selectedOption}
                                                                value={userDetailValues.denomination_id !== "" && denominations.filter((den) => den.value == userDetailValues.denomination_id)}
                                                                onChange={handleChange}
                                                                options={denominations}
                                                                isSearchable={true}
                                                                placeholder={title.denomination}
                                                            />
                                                            <span className='danger' style={{ color: 'red' }}>{userDetailsError.denomination_id}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-6 pl-20">
                                                        <label htmlFor="name">Books:</label>
                                                            <Select
                                                                name="books_associated"
                                                                isMulti={true}
                                                                onChange={handleChange}
                                                                options={books}
                                                                isSearchable={true}
                                                                isClearable={false}
                                                                isLoading={bookLoading}
                                                                onInputChange={(inputValue) => loadOptions(inputValue)}
                                                                placeholder={title.books_associated}
                                                            />
                                                            <span className='danger' style={{ color: 'red' }}>{userDetailsError.book_list}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <div className="layout-button mt-0">
                                                        <button type="button" className="btn btn-success btn-sm" >Cancel</button>
                                                        <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal" onClick={profileDetailsSubmit}>save</button>
                                                    </div>
                                                </div>
                                            </div>
                                    
                            </div>
                            </TabPanel>
                            
                            </Tabs>
                                            {/* <div className="card-body py-md-30 user-details-height">
                                                <form>
                                                    <div className="row">
                                                        <div className="col-md-6 mb-25">
                                                            <label htmlFor="name">Name</label>
                                                            <input type="text" className="form-control ih-medium ip-gray radius-xs b-light px-15" placeholder="Officer Name" name="name" value={userProfile.name} onChange={enterProfileFunction} />
                                                            <span className='danger' style={{ color: 'red' }}>{userProfleError.name}</span>
                                                        </div>
                                                        <div className="col-md-6 mb-25">
                                                            <label htmlFor="name">Email:</label>
                                                            <input type="text" className="form-control ih-medium ip-gray radius-xs b-light px-15" placeholder="Email" name="email" value={userProfile.email} onChange={enterProfileFunction} />
                                                            <span className='danger' style={{ color: 'red' }}>{userProfleError.email}</span>
                                                        </div>
                                                        <div className="col-md-6 mb-25">
                                                            <label htmlFor="name">Mobile Number:</label>
                                                            <div className="row">
                                                                <div className="col-md-4 mb-25 ml-1"> */}
                                                                    {/* <select className='form-control ih-medium ip-gray radius-xs b-light px-15' onChange={enterProfileFunction} name="mcc_id">
                                                                <option value="" >Select code</option>
                                                                {mccs.map((mcc)=>(
                                                                    <option value={mcc.id} selected={userProfile.mcc_id == mcc.id ? "selected" :  null} >{mcc.code}</option>
                                                                ))}
                                                            </select> */}

                                                                    {/* <ReactFlagsSelect
                                                                        countries={mccs.map((country) => country.country_code)}
                                                                        placeholder="Select code"
                                                                        customLabels={mccs.reduce((labels, country) => {
                                                                            labels[country.country_code] = { primary: `+${country.code}` };
                                                                            return labels;
                                                                        }, {})}
                                                                        onSelect={(countryCode) =>
                                                                            setUserProfile(prevState => ({
                                                                                ...prevState,
                                                                                ["mcc_id"]: mccs.find((mcc) => mcc.country_code == countryCode).id
                                                                            }))}
                                                                        selected={userProfile.mcc_id ? mccs.find((mcc) => mcc.id == userProfile.mcc_id).country_code : "US"}
                                                                        searchable={true}
                                                                    />

                                                                    <span className='danger' style={{ color: 'red' }}>{userProfleError.mcc_id}</span>
                                                                </div>
                                                                <div className="col-md-7 mb-25">
                                                                    <input type="text" className="form-control ih-medium ip-gray radius-xs b-light px-15" placeholder="Phone Number" value={userProfile.mobile} name="mobile" onChange={enterProfileFunction} />
                                                                    <span className='danger' style={{ color: 'red' }}>{userProfleError.mobile}</span>
                                                                </div>
                                                            </div>
                                                        </div> */}

                                                        {/* <div className='col-md-12 mb-25'>
                                                    <div className="checkbox-list__single">
                                                        <div className="checkbox-group d-flex">
                                                            <div className="checkbox-theme-default custom-checkbox checkbox-group__single">

                                                                <label for="check-grp-10">
                                                                    <span className="checkbox-text">Written</span>
                                                                </label>
                                                            </div>
                                                            <div className="checkbox-theme-default custom-checkbox checkbox-group__single">
                                                                <input className="checkbox" type="checkbox" id="check-grp-13" />
                                                                <label for="check-grp-13">
                                                                    <span className="checkbox-text">Tamil</span>
                                                                </label>
                                                            </div>
                                                            <div className="checkbox-theme-default custom-checkbox checkbox-group__single">
                                                                <input className="checkbox" type="checkbox" id="check-grp-14" />
                                                                <label for="check-grp-14">
                                                                    <span className="checkbox-text">Eng</span>
                                                                </label>
                                                            </div>
                                                            <div className="checkbox-theme-default custom-checkbox checkbox-group__single">
                                                                <input className="checkbox" type="checkbox" id="check-grp-15" />
                                                                <label for="check-grp-15">
                                                                    <span className="checkbox-text">Hindi</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                        {/* <div className="col-md-6 mb-25">
                                                            <label htmlFor="name">Gender:</label>
                                                            <select className="form-control ih-medium ip-gray radius-xs b-light px-15" name="gender" onChange={enterProfileFunction}>
                                                                <option value="">Select Gender</option>
                                                                <option value="M" selected={userProfile.gender == "M" ? "selected" : null}>Male</option>
                                                                <option value="F" selected={userProfile.gender == "F" ? "selected" : null}>Female</option>
                                                            </select>
                                                            <span className='danger' style={{ color: 'red' }}>{userProfleError.gender}</span>
                                                        </div>
                                                        <div className="col-md-6 mb-25">
                                                            <label htmlFor="name">Nationality</label>
                                                            <select name="nationality" id="" className="form-control ih-medium ip-gray radius-xs b-light px-15" placeholder="Nationality" onChange={enterProfileFunction}>
                                                                <option value="">Select Nationality</option>
                                                                {nationalityLists && nationalityLists.map((nationalityList) => {
                                                                    return (
                                                                        <option value={nationalityList.id} selected={nationalityList.id == userProfile.nationality}>{nationalityList.name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            <span className='danger' style={{ color: 'red' }}>{userProfleError.nationality}</span>
                                                        </div>
                                                        <div className="col-md-6 mb-25">
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="layout-button mt-0">
                                                                <button type="button" className="btn btn-default btn-squared border-normal bg-normal px-20 ">cancel</button>
                                                                <button type="button" className="btn btn-primary btn-default btn-squared px-30" onClick={profileDetailsSubmit}>save</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                </div> */}
                                        </div>
                                    } 
                                    {profileSubmiit == 1 &&
                                        <div className="card card-Vertical card-default card-md mb-4">
                                            <div className="card-header">
                                                <h6>Fill User Details</h6>
                                            </div>
                                            <div className="card-body py-md-30 user-details-height">

                                                <form action="">
                                                    <div className="row">
                                                        <div className="col-md-6 mb-25">
                                                            {/* <input type="text" className="form-control ih-medium ip-gray radius-xs b-light px-15" placeholder="Religion" name="religion_id" onChange={enterDetailFunction} value={userDetailValues.religion_id}/> */}
                                                            {/* <select id="" className="form-control ih-medium ip-gray radius-xs b-light px-15"name="religion_id" onChange={enterDetailFunction}>
                                                        <option value="">Select Religion</option>
                                                        {religions.map((religion)=>{
                                                            return(
                                                                <option value={religion.tag.id}>{religion.tag.name}</option>
                                                            )
                                                        })}
                                                    </select> */}
                                                            <label htmlFor="name">Religion:</label>
                                                            <Select
                                                                name="religion_id"
                                                                isMulti={false}
                                                                // value={selectedOption}
                                                                onChange={handleChange}
                                                                options={religions}
                                                                isSearchable={true}
                                                                placeholder="Select Religion"
                                                            />
                                                            <span className='danger' style={{ color: 'red' }}>{userDetailsError.religion_id}</span>
                                                        </div>
                                                        <div className="col-md-6 mb-25">
                                                            <label htmlFor="name">Denomination:</label>
                                                            <Select
                                                                name="denomination_id"
                                                                isMulti={false}
                                                                // value={selectedOption}
                                                                value={userDetailValues.denomination_id !== "" && denominations.filter((den) => den.value == userDetailValues.denomination_id)}
                                                                onChange={handleChange}
                                                                options={denominations}
                                                                isSearchable={true}
                                                                placeholder={title.denomination}
                                                            />
                                                            {/* <select id="" className="form-control ih-medium ip-gray radius-xs b-light px-15"name="denomination_id" onChange={enterDetailFunction}>
                                                        <option value="">{denominationTitle}</option>
                                                        {denominations.map((denomination)=>{return(
                                                            <option value={denomination.sub_tag.id}>{denomination.sub_tag.name}</option>
                                                        )})}
                                                        
                                                    </select> */}
                                                            <span className='danger' style={{ color: 'red' }}>{userDetailsError.denomination_id}</span>
                                                        </div>
                                                        <div className="col-md-6 mb-25">
                                                            <label htmlFor="name">Books:</label>
                                                            <Select
                                                                name="books_associated"
                                                                isMulti={true}
                                                                onChange={handleChange}
                                                                options={books}
                                                                isSearchable={true}
                                                                isClearable={false}
                                                                isLoading={bookLoading}
                                                                onInputChange={(inputValue) => loadOptions(inputValue)}
                                                                placeholder={title.books_associated}
                                                            />
                                                            <span className='danger' style={{ color: 'red' }}>{userDetailsError.book_list}</span>

                                                            {/* <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15" id="inputName" placeholder={title.books_associated} name="book_associated" value={userDetailValues.book_associated} onChange={enterDetailFunction} />  */}
                                                            {/* <ul>   
                                                        {books.map((book)=>{
                                                        return (
                                                        <li onClick={() => bookClick(book)}>{book.name}</li>
                                                        )    
                                                        })}
                                                        
                                                    </ul> */}
                                                            {/* <div className='col-12'>
                                                        <div className="atbd-tag-wrap">
                                                            <div className="tag-box">
                                                                {userDetailValues.book_lists &&userDetailValues.book_lists.map((book_list)=>{
                                                                    return(

                                                                <span className="atbd-tag tag-light">{book_list.name} <a className="tag-closable" onClick={() => removeBookList(book_list.id)}><i className="la la-times"></i></a></span>
                                                                )})}
                                                                <span className='danger' style={{color:'red'}}>{userDetailsError.book_list}</span>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                        </div>
                                                        <div className="col-md-6 mb-25">
                                                            <label htmlFor="name">Main Language:</label>
                                                            <Select
                                                                name="main_language"
                                                                isMulti={false}
                                                                onChange={handleChange}
                                                                options={languageLists}
                                                                isSearchable={true}
                                                                isClearable={false}
                                                                placeholder="Select Main Language"
                                                            />
                                                            <span className='danger' style={{ color: 'red' }}>{userDetailsError.main_language}</span>
                                                        </div>

                                                        <div className="col-md-6 mb-25">
                                                            <label htmlFor="name">Other Language:</label>
                                                            <Select
                                                                name="other_language"
                                                                isMulti={true}
                                                                // value={selectedOption}
                                                                onChange={handleChange}
                                                                options={languageLists}
                                                                isClearable={false}
                                                                isSearchable={true}
                                                                placeholder="Select Other Language"
                                                            />
                                                            <span className='danger' style={{ color: 'red' }}>{userDetailsError.other_language}</span>
                                                        </div>
                                                        <div className="col-md-6 mb-25">
                                                            <label htmlFor="name">Writting Language:</label>
                                                            <Select
                                                                name="writting_language"
                                                                isMulti={true}
                                                                value={userDetailValues.languages?.filter((lang) => lang.isWritten == 1)}
                                                                onChange={handleChange}
                                                                options={userDetailValues.languages}
                                                                isSearchable={true}
                                                                isClearable={false}
                                                                placeholder="Select Written Language"
                                                            />
                                                            <span className='danger' style={{ color: 'red' }}>{userDetailsError.writting_language}</span>
                                                        </div>
                                                        <div className="col-md-6 mb-25">
                                                            <label htmlFor="name">Profile Name:</label>
                                                            <input type="text" className="form-control ih-medium ip-gray radius-xs b-light px-15" placeholder="Enter Profile Name" name="profile_name" value={userDetailValues.profile_name} onChange={enterDetailFunction} />
                                                            <span className='danger' style={{ color: 'red' }}>{userDetailsError.profile_name}</span>
                                                        </div>

                                                        <div className="col-md-6 mb-25">
                                                            {/* <input type="Religion" className="form-control ih-medium ip-gray radius-xs b-light px-15" placeholder="Age" /> */}
                                                        </div>
                                                        <div className="col-md-6 mb-25">
                                                            {/* <input type="Religion" className="form-control ih-medium ip-gray radius-xs b-light px-15" placeholder="Age" /> */}
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="layout-button mt-0">
                                                                <button type="button" className="btn btn-default btn-squared border-normal bg-normal px-20 " onClick={() => setProfileSubmiit(0)}>cancel</button>
                                                                <button type="button" className="btn btn-primary btn-default btn-squared px-30" onClick={userDetailsSubmit}>save</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    }
                                    {profileSubmiit == 2 &&
                                        <div className="card card-Vertical card-default card-md mb-4">
                                            <div className="card-header"><h6>Upload Document</h6></div>
                                            <div className='card-body'>
                                                <div className='doc-title'>
                                                    <h6>Upload Documents</h6><span><a href=''><i className="fa fa-eye" aria-hidden="true"></i></a>
                                                    </span>
                                                </div>
                                                <div className='row p-15'>
                                                    <div className='col-lg-6 col-md-6 col-sm-12 form-group'>
                                                        <label className='form-lable'>Job Title</label>
                                                        <select className='form-control'>
                                                            <option>Job 1</option>
                                                            <option>Job 2</option>
                                                        </select>
                                                    </div>
                                                    <div className='col-lg-6 col-md-6 col-sm-12 form-group'>
                                                        <label className='form-lable'>Company Name</label>
                                                        <select className='form-control'>
                                                            <option>Company 1</option>
                                                            <option>Company 2</option>
                                                        </select>
                                                    </div>
                                                    <div className='col-lg-6 col-md-6 col-sm-12 form-group'>
                                                        <label className='form-lable'>Date of issue</label>
                                                        <input type='date' className='form-control' />
                                                    </div>
                                                    <div className='col-lg-6 col-md-6 col-sm-12 form-group'>
                                                        <label className='form-lable'>Expire Date</label>
                                                        <input type='date' className='form-control' />
                                                    </div>
                                                    <div className='form-group col-lg-12'>

                                                        <div className="atbd-upload">
                                                            <div className="atbd-upload-avatar media-import dropzone-md-s">
                                                                <p className="color-light mt-0 fs-14">Drop files here to upload</p>
                                                            </div>
                                                            <div className="avatar-up">
                                                                <input type="file" name="upload-avatar-input" className="upload-avatar-input" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='card-footer'>
                                                <div class="atbd-button-list d-flex flex-wrap">
                                                    <button class="btn btn-success btn-default btn-squared ">Upload</button>
                                                    <button class="btn btn-light btn-default btn-squared ">Cancel</button>
                                                    <button class="btn btn-info btn-default btn-squared ">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                : null}
                            <div className='col-3'>&nbsp;</div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </main >
        </>
    );
}
export default UserDetails;