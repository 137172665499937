import React, { useEffect, useState } from 'react'
import { curatorFinishCuration, curatorGetPostCompleted, curatorGetTag, curatorPostHide } from '../Api/ApiCurator';
import { moderatorFinishModeration, moderatorGetPostCompleted, moderatorPostHide, moderatorGetTag } from '../Api/ApiModerator';
const Dummy = (props) => {
    const [completedPost, setCompletedPost] = useState([]);
    useEffect(() => {
        props.setGetPostCompletedLoading(true);
        let targetCompletedPostetUrl = props.content == "curator" ? curatorGetPostCompleted() : moderatorGetPostCompleted();
        targetCompletedPostetUrl.then((res) => {
            setCompletedPost(res.data.data);
            props.setGetPostCompletedLoading(false);
        }).catch(() => {
            console.log('failed');
        });
    }, []);
     
      
  return (
    <div>dummy</div>
  )
}

export default Dummy