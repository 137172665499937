import React, { useEffect, useRef } from 'react'
import { BrowserRouter, Routes, Route, useNavigate, useParams } from 'react-router-dom';
import Header from '../layouts/header';
import Aside from '../layouts/aside';
import Footer from '../layouts/footer';
import { curatorSearchTag, curatorParameter, curatorTarget, curatorInsertTag, curatorGetTag, curatorDeleteTag } from '../Api/ApiCurator';
import { useState } from 'react';
import { ImageUrl } from '../Api/Url';
import Post from '../Components/Post';
import { EachWordCapitalize, FilterParameterTags } from '../Api/CommanJsFunction';
import Tags from '../Components/Tags';
import { getDailyTrack } from '../Api/GeneralApi';
import useGetDailyTrack from '../CustomHooks/useGetDailyTrack';
import MediLoader from '../Components/medi-loading';
import Dummy from '../layouts/dummy';
const Home = ({ setIsShowHeader, setShowLoading }) => {
    const { authid } = useParams();

    const redirect = useNavigate();
    const textAreaRef = React.createRef();
    const [targets, setTargets] = useState();
    const [postNextPage, setPostNextPage] = useState(1);
    const [parameters, setParameters] = useState([])
    const [parameterTags, setParameterTags] = useState([]);
    const [parameterSubTags, setParameterSubTags] = useState([]);
    const [postDescription, setPostDescription] = useState('');
    const [wordCount, setWordCount] = useState(400);
    const [allTargetCounts, setAllTargetCounts] = useState({});
    const [editPostId, setEditPostId] = useState();
    const [IsTagDeleteCall, setIsTagDeleteCall] = useState(0);
    const [textareaHeight, setTextareaHeight] = useState("auto");
    const [selectedTags, setSelectedTags] = useState([]);

    const [isLoading1, setIsLoading1] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    
    const [getPostCompletedLoading, setGetPostCompletedLoading] = useState(false)
    const [getTagLoading, SetGetTagLoading] = useState(false);
    const [postHideLoading, setPostHideLoading] = useState(false)

    const [finishTargetLoading, setFinishTargetLoading] = useState(false)
    const [searchSubTagLoading, setSearchSubTagLoading] = useState(false)

    useEffect(() => {
        updateTextareaHeight();
        const handleInput = () => {
            updateTextareaHeight();
        };
        document.addEventListener('input', handleInput);
        return () => {
            document.removeEventListener('input', handleInput);
        };
    }, [postDescription]);

    const updateTextareaHeight = () => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = 'auto';
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        }
    };

    useEffect(() => {
        setIsShowHeader(true);
        setIsLoading1(true)
        curatorTarget(postNextPage, '', editPostId).then((res) => {
            setTargets(res.data.data);
            setIsLoading1(false)
            // setAllTargetCounts({total_target:res.data.data.total_target,completed_target:res.data.data.completed_target,skipped_target:res.data.data.skipped_target});
        }).catch((err) => {
            if (err.response.status == 402) {
                redirect(`/u/${authid}/user-details`, {
                    state: {
                        // for:err.response.data.data.name
                        //   errorMessage: err.response.data.message,
                    },
                });
            }
            if (err.response.status == 422) {
                redirect(`u/${authid}/auth`, {
                    state: {
                        // for:err.response.data.data
                        //   errorMessage: err.response.data.message,
                    },
                });
            }
            if (err.response.status == 401) {
                redirect('/');
            }
            console.error('Request failed:', err.response);
        })
    }, [postNextPage])

    useEffect(() => {
        if (parameters.length == 0 && (!localStorage.getItem('curator') || JSON.parse(localStorage.getItem('curator').length == 0))) {
            setIsLoading2(true);
            curatorParameter().then((res) => {
                const modifiedData = res.data.data.map(item => {
                    const modifiedParameterTag = item.parameter_tag.map(paramTag => ({
                        value: paramTag.tag.id,
                        label: paramTag.tag.name,
                        sub_tags: paramTag.tag.sub_tags.map(subTag => ({
                            value: subTag.id,
                            label: subTag.name,
                        })),
                    }));
                    return {
                        id: item.id,
                        name: item.name,
                        for: item.for,
                        is_sub_tag: item.is_sub_tag,
                        status: item.status,
                        parameter_tag: modifiedParameterTag,
                    };
                });
                localStorage.setItem('curator', JSON.stringify(modifiedData))
                setParameters(modifiedData);
                setIsLoading2(false);
            }).catch((err) => {

            })
        }
        else {
            setParameters(JSON.parse(localStorage.getItem('curator')));
            setIsLoading2(false);
        }
    }, [postNextPage])
    useGetDailyTrack(setAllTargetCounts);
    function description(e) {
        if (e.target.value.length <= 400) {
            setPostDescription(e.target.value);
        }
        else {
            const truncatedText = e.target.value.slice(0, 400);
            setPostDescription(truncatedText);
        }
    }
    useEffect(() => {
        // postDescription &&
        setWordCount((prev) => (postDescription.length < 400 ? 400 - postDescription.length : 0));
    }, [postDescription])


    // const mainLoading = isLoading1 || isLoading2 || getTagLoading;
    const mainLoading = isLoading1 || isLoading2  || getTagLoading || postHideLoading || finishTargetLoading || searchSubTagLoading ;
    console.log(getPostCompletedLoading);
    return (
        <>
        {mainLoading  ? <MediLoader /> :
            <main className="main-content main-top">
                <div className="">
                    <div className="container-fluid mt-20">
                        <div className='row'>
                            {/* <Dummy setGetPostCompletedLoading={setGetPostCompletedLoading}/> */}
                            <Aside targets={targets} allTargetCounts={allTargetCounts} setEditPostId={setEditPostId} content="curator" setTargets={setTargets} setParameterTags={setParameterTags} setParameters={setParameters} setPostDescription={setPostDescription} selectedTags={selectedTags} setSelectedTags={setSelectedTags} setGetPostCompletedLoading={setGetPostCompletedLoading} SetGetTagLoading={SetGetTagLoading} setPostHideLoading={setPostHideLoading}></Aside>

                            {targets && targets.values.length > 0 ?
                                <>
                                    <Post targets={targets} setPostNextPage={setPostNextPage} setParameterTags={setParameterTags} postDescription={postDescription} parameterTags={parameterTags} allTargetCounts={allTargetCounts} setEditPostId={setEditPostId} setPostDescription={setPostDescription} parameterSubTags={parameterSubTags} parameters={parameters} editPostId={editPostId} setAllTargetCounts={setAllTargetCounts} setParameters={setParameters} selectedTags={selectedTags} setSelectedTags={setSelectedTags} for="curator" setFinishTargetLoading={setFinishTargetLoading}/>
                                    <div className="col-lg-6 col-md-6 col-sm-12 mb-25">
                                        <Tags parameters={parameters} setParameters={setParameters} setParameterTags={setParameterTags} setParameterSubTags={setParameterSubTags} targets={targets} parameterTags={parameterTags} parameterSubTags={parameterSubTags} setIsTagDeleteCall={setIsTagDeleteCall} selectedTags={selectedTags} setSelectedTags={setSelectedTags} for="curator" setSearchSubTagLoading={setSearchSubTagLoading}/>
                                       
                                            <div className='col-lg-12 col-md-12 col-sm-12 mt-20 pl-0'>
                                                <div className='card'>
                                                    <div className='card-header'><h6>Tell us about this post </h6></div>
                                                    <div className='card-body'>
                                                        <textarea ref={textAreaRef} className="form-control" id="" rows="2" placeholder='How do you find this post' onChange={(e) => description(e)} value={postDescription} style={{ height: textareaHeight, overflowY: 'hidden', resize: 'none' }} onKeyDown={(e) => { if (e.key === 'Enter') {e.preventDefault(); }}}></textarea>
                                                        <p className='text-right'>{wordCount} words</p>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                    </div>
                                </>
                                : <h1 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>No Post Available</h1>}
                        </div>
                    </div>
                </div>
            </main>}
            <Footer></Footer>
        </>
                            
    );
}
export default Home;