import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from '../../layouts/header';
import Footer from '../../layouts/footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
const HomeUserAccounts = ({ setIsShowHeader }) => {
    useEffect(() => {
        setIsShowHeader(true);
    }, [])
    return (
        <>
            {/* <Header></Header> */}
            <main className="main-content main-top">
                <div className="">
                    <div className="container-fluid mt-20">
                        <div className='row'>
                            <div className='col-lg-3 col-md-3 col-sm-12'>
                                <div className='card'>
                                    <div className='card-header'>Search Filter </div>
                                    <div className='card-body user-filter'>
                                        <form action="#">
                                            <div className="form-group">
                                                <label htmlFor="a10" className="il-gray fs-14 fw-500 align-center">Name</label>
                                                <input type="text" className="form-control ih-small ip-light radius-xs b-light px-15" placeholder="" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="a10" className="il-gray fs-14 fw-500 align-center">Religion</label>
                                                <input type="text" className="form-control ih-small ip-light radius-xs b-light px-15" placeholder="" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="a10" className="il-gray fs-14 fw-500 align-center">Joined at</label>
                                                <input type="text" className="form-control ih-small ip-light radius-xs b-light px-15" placeholder="" Type='date' />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="a10" className="il-gray fs-14 fw-500 align-center">Inspirations</label>
                                                <input type="text" className="form-control ih-small ip-light radius-xs b-light px-15" placeholder="Numbers Only" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="a10" className="il-gray fs-14 fw-500 align-center">Ministries</label>
                                                <input type="text" className="form-control ih-small ip-light radius-xs b-light px-15" placeholder="Numbers Only" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="a10" className="il-gray fs-14 fw-500 align-center">Followers</label>
                                                <input type="text" className="form-control ih-small ip-light radius-xs b-light px-15" placeholder="Numbers Only" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="a10" className="il-gray fs-14 fw-500 align-center">Following</label>
                                                <input type="text" className="form-control ih-small ip-light radius-xs b-light px-15" placeholder="Numbers Only" />
                                            </div>
                                        </form>
                                    </div>
                                    <div className='card-footer'><button className="btn btn-success btn-default btn-squared ">Submit</button></div>
                                </div>
                            </div>
                            <div className='col-lg-9'>
                                <div className="card">
                                    <div className="card-header color-dark fw-500">
                                        <h6>User Accounts</h6>
                                    </div>
                                    <div className="card-body p-0 user-table-height">
                                        <div className="table4 p-25 bg-white mb-30">
                                            <div className="table-responsive">
                                                <table className="table mb-0">
                                                    <thead>
                                                        <tr className="userDatatable-header">
                                                            <th><span className="userDatatable-title">Name</span></th>
                                                            <th><span className="userDatatable-title">Badge</span></th>
                                                            <th><span className="userDatatable-title">Joined at</span></th>
                                                            <th><span className='userDatatable-title'>Need </span></th>
                                                            <th><span className='userDatatable-title'>followers </span></th>
                                                            <th><span className='userDatatable-title'>following </span></th>
                                                            <th><span className='userDatatable-title'>Status </span></th>
                                                            <th><span className='userDatatable-title'>Action </span></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    Name
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    Gold
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    25/10/2010
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    2012
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    12
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    05
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    <span className="atbd-tag tag-success tag-transparented">Active</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    <a href='#' data-toggle="modal" data-target="#modal-basic"> <img width={18} src='/assets/img/edit.png' /></a>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    Name
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    Gold
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    25/10/2010
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    2012
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    12
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    05
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    <span className="atbd-tag tag-warning tag-transparented">Warning</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    <a href='#' data-toggle="modal" data-target="#modal-basic"> <img width={18} src='/assets/img/edit.png' /></a>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    Name
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    Gold
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    25/10/2010
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    2012
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    12
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    05
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    <span className="atbd-tag tag-danger tag-transparented">Suspended</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="userDatatable-content">
                                                                    <a href='#' data-toggle="modal" data-target="#modal-basic"> <img width={18} src='/assets/img/edit.png' /></a>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="modal-basic modal fade show" id="modal-basic" tabindex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content modal-bg-white ">
                            <div className="modal-header">
                                <h6 className="modal-title">Edit User</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span data-feather="x"></span></button>
                            </div>
                            <Tabs>
                                <div className='tabsection'>
                                    <TabList className="tab-list">
                                        <Tab className="tab-item ">Account Info</Tab>
                                        <Tab className="tab-item">Performance</Tab>
                                        <Tab className="tab-item">Statistics & Interactions</Tab>
                                    </TabList>
                                </div>
                                <TabPanel>
                                    <div className="modal-body">
                                    <div><span className='avatar'><img className="avatar-big" src='/assets/img/author-nav.jpg' /></span><span className='view-icon'><Link to='#' data-toggle="modal" data-target="#profileview"><i class="fa fa-eye view-eye" aria-hidden="true"></i></Link>
                                                </span></div>
                                        <div className='row'>
                                            <div className='col-lg-5 col-md-5 col-sm-12'>
                                            
                                                <div className='table=responsive'>
                                                    <table className='table bordered'>
                                                        <tr>
                                                            <td colSpan={2}><h5>Personal details</h5></td>
                                                        </tr>
                                                        <tr>
                                                            <td>SID :</td>
                                                            <td>SID0123546</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Joined At :</td>
                                                            <td>SID0123546</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Gender :</td>
                                                            <td>Male</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 col-sm-12'>
                                                <div className='table=responsive'>
                                                    <table className='table bordered'>
                                                        <tr>
                                                            <td>Religion :</td>
                                                            <td>Hindu</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Denomination :</td>
                                                            <td>test</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Age on the Platform :</td>
                                                            <td>Male</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className='col-lg-3 col-md-3 col-sm-12'>
                                                <div className='table=responsive'>
                                                    <table className='table bordered'>
                                                        <tr>
                                                            <td>Country</td>
                                                            <td>India</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Age :</td>
                                                            <td>35</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    Performance
                                </TabPanel>
                                <TabPanel>Statistics & Interactions</TabPanel>
                            </Tabs>
                            {/* <div className="table4 bg-white pop-up-height">

                                    <div className="table-responsive">
                                        <table className='table mb-0'>
                                            <tbody>
                                                <tr>
                                                    <th scope="row"><div className='user-img'><span className="avatar avatar-light avatar-lg avatar-circle">
                                                        <img className="avatar-img" src="/assets/img/author/1.jpg" alt="" />
                                                    </span></div></th>
                                                    <td> Name</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Name</th>
                                                    <td> <input className='form-control' type='text' readOnly /></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Badge</th>
                                                    <td><select className='form-control' readOnly>
                                                        <option>Gold</option>
                                                        <option>Silver</option>
                                                        <option>Diamond</option>
                                                    </select></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Joined at</th>
                                                    <td> <input className='form-control' type='date' readOnly /></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Inspiration</th>
                                                    <td> <input className='form-control' type='text' readOnly /></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Ministries</th>
                                                    <td> <input className='form-control' type='text' readOnly /></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Followers</th>
                                                    <td> <input className='form-control' type='text' readOnly /></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Status</th>
                                                    <td><select className='form-control' readOnly>
                                                        <option>Active</option>
                                                        <option>Warning</option>
                                                        <option>Suspended</option>
                                                    </select></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Following</th>
                                                    <td> <input className='form-control' type='text' readOnly /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div> */}

                            <div className="modal-footer">
                                <button type="button" className="btn btn-success btn-sm">Save</button>
                                <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="profileview" tabindex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-body text-center">
                                <img className='profile-img' src='/assets/img/login-bg.png' />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer></Footer>
        </>
    );
}
export default HomeUserAccounts;