import React, {useState, useEffect, useRef} from 'react'
import { curatorDeleteTag, curatorInsertTag, curatorSearchSubTag, curatorSearchTag } from '../Api/ApiCurator';
import { EachWordCapitalize, FilterParameterSubTags, FilterParameterTags } from '../Api/CommanJsFunction';
import { moderatorDeleteTag, moderatorInsertTag, moderatorSearchSubTag, moderatorSearchTag } from '../Api/ApiModerator';
import Select from 'react-select';
import Multiselect from 'multiselect-react-dropdown';

const Tags = (props) => {
    const [postTags, setPostTags] = useState({})
    const [recommendTags, setRecommendTags] = useState();
    const [recommendSubTags, setRecommendSubTags] = useState();
    const [isLoading, setIsLoading] = useState(false);
    
    const selectRefs = useRef({}); 
    function handleChange(selectedValue, actionMeta, additionalParams){
        if(additionalParams.parameter_id)
        {
            const updatedSelectedTags = [...props.selectedTags];
            const index = updatedSelectedTags.findIndex(
                (tag) => tag.parameter_id === additionalParams.parameter_id && tag.tags.some(t => t.value === actionMeta.name)
            );
            if (index !== -1) {
                updatedSelectedTags[index].tags.find(t => t.value === actionMeta.name).sub_tags = selectedValue;
            }
            props.setSelectedTags(updatedSelectedTags);
        }
        else{
            const existingIndex = props.selectedTags.findIndex(tag => tag.parameter_id === actionMeta.name);
            const updatedTags = {
                parameter_id: actionMeta.name,
                tags: selectedValue,
                is_sub_tag: additionalParams.is_sub_tag
            };
            if (selectedValue.length === 0) {
                props.setSelectedTags(prevTags => prevTags.filter(tag => tag.parameter_id !== actionMeta.name));
            } else {
                props.setSelectedTags(prevTags =>
                    existingIndex !== -1
                        ? [...prevTags.slice(0, existingIndex), updatedTags, ...prevTags.slice(existingIndex + 1)]
                        : [...prevTags, updatedTags]
                );
            }

        }
    }

    // function handleChange(selectedValue, actionMeta, additionalParams){
    //         let param_tags = [...props.parameterTags];
    //         const index = param_tags.findIndex((item)=>item.parameter_id == actionMeta.name);
    //         if(index !== -1)
    //         {
    //             param_tags[index].values = selectedValue;
    //             props.setParameterTags(param_tags);
    //         }
    //         else
    //         {
    //             const new_params = {
    //                 parameter_id : actionMeta.name,
    //                 values: selectedValue
    //             }
    //             props.setParameterTags((prev)=>[...prev, new_params])
    //         }
    //         if(additionalParams.is_sub_tag){
    //             if(actionMeta.action == "remove-value"){
    //                 props.setSubParameters && props.setSubParameters((prev) => prev.filter((item) => item.tag_id !== actionMeta.removedValue.value));
    //                 props.setSubParameters && props.setParameterSubTags((prev) => prev.filter((item) => item.tag_id !== actionMeta.removedValue.value));
    //             }
    //             else
    //             {
    //                 props.setSubParameters && props.setSubParameters((prev)=>[...prev, {parameter_id: actionMeta.name, tag_id: actionMeta.option.value, name: actionMeta.option.label}])
    //                 props.setSubParameters && props.setParameterSubTags((prev)=>[...prev, {parameter_id: actionMeta.name, tag_id: actionMeta.option.value, name: actionMeta.option.label, values:[]}])
                    
    //             }
    //         }
    // }
    const [openSelectIds, setOpenSelectIds] = useState([]);
    const [openSelectTagIds, setOpenSelectTagIds] = useState([]);

    function loadOptions(search, parameter_id, tag_id=null, reason, actionMeta){
        setRecommendTags([]);
        if(reason == "menu_open" || actionMeta.action == "input-change")
        {
            setOpenSelectIds((prevIds) => [...prevIds, parameter_id]);
            setIsLoading(true);
            let dataArray = props.parameters.find((parameter)=>parameter.id == parameter_id).parameter_tag;
            if(tag_id){
            let foundTag = dataArray.find((tag) => tag.value === tag_id);
            setOpenSelectTagIds((prevIds) => [...prevIds, {parameter_id:parameter_id, tag_id: tag_id}]);
                setRecommendSubTags({
                    "parameter_id": parameter_id,
                    "tag_id": tag_id,
                    "values": foundTag.sub_tags.map((tag) => ({
                    ...tag,
                    }))
                });
            }
            else{
                setRecommendTags({
                    "parameter_id": parameter_id,
                    "values": dataArray.map((tag) => ({
                    ...tag,
                    sub_tags: [] // Set sub_tags to an empty array
                    }))
                });
            }
        }

    }
    // function handleChangeSubParameter(selectedValue, actionMeta, additionalParams){
    //     let param_tags = [...props.parameterSubTags];
    //     const index = param_tags.findIndex((item)=>(item.tag_id == actionMeta.name && item.parameter_id == additionalParams.parameter_id));
    //     if(index !== -1)
    //     {
    //         param_tags[index].values = selectedValue;
    //         props.setParameterSubTags(param_tags);
    //     }
    //     else
    //     {
    //         const new_params = {
    //             tag_id : actionMeta.name,
    //             parameter_id : additionalParams.parameter_id, 
    //             values: selectedValue
    //         }
    //         props.setParameterSubTags((prev)=>[...prev, new_params])
    //     }
    // }
    function loadOptionsSubParameter(search, parameter_id, tag_id, reason, actionMeta){
        setRecommendSubTags([]);
        if(reason == "menu_open" || actionMeta.action == "input-change")
        {
            let searchSubTag = props.for == "curator" ? curatorSearchSubTag : moderatorSearchSubTag;
            searchSubTag(search, parameter_id, tag_id).then((res)=>{
                setRecommendSubTags({"parameter_id" : parameter_id, tag_id: tag_id, "values": res.data.data });
            }).catch((err)=>{

            })
        }
    }
    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (menuIsOpen && selectRef.current && !selectRef.current.contains(event.target)) {
    //         console.log('Clicked outside');

    //             setMenuIsOpen(null);
    //         }
    //     };
    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, [menuIsOpen]); // Adding/removing event listener based on openSelectId
    useEffect(() => {
        const handleClickOutside = (event) => {
            const clickedOutside = openSelectIds.some(id => {
                return selectRefs.current[id] && !selectRefs.current[id].contains(event.target);
            });
            if (clickedOutside) {
                setOpenSelectIds([]);
            }
            const clickedOutsideTag = openSelectTagIds.some(p => {
                return selectRefs.current[p.parameter_id+"_"+p.tag_id] && !selectRefs.current[p.parameter_id+"_"+p.tag_id].contains(event.target);
            });
            if(clickedOutsideTag){
                setOpenSelectTagIds([]);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [openSelectIds, openSelectTagIds]);
    return (
        <div className="social-overview-wrap">
            <div className="card border-0">
                <div className="card-header">
                    <h6>{props.for == 'curator' ? 'Curation' : 'Moderation'} Tags</h6>
                </div>
                <div className={`card-body  ${props.for == 'curator' ? 'curator-tags-scroll' : 'tags-scroll'}`}>
                {props.parameters?.map((parameter, key)=>{
                  return(
                    <>
                    <div className="form-group row" key={key}>
                        <div className="col-sm-3 d-flex aling-items-center">
                            <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">{EachWordCapitalize(parameter.name)}</label>
                        </div>
                        <div className="col-sm-9 "  ref={el => selectRefs.current[parameter.id] = el} >
                            <Select

                                onMenuOpen={(actionMeta)=>loadOptions("", parameter.id, null, "menu_open", actionMeta)}
                                name={parameter.id}
                                isMulti={true}
                                value={props.selectedTags.length > 0 ? (props.selectedTags.find((s)=>s.parameter_id == parameter.id))?.tags : []}
                                
                                onChange={(selectedOptions, actionMeta) => {
                                    const additionalParams = {
                                        is_sub_tag: parameter.is_sub_tag,
                                    };
                                    handleChange(selectedOptions, actionMeta, additionalParams);
                                }}
                                isSearchable={true}
                                isClearable={false}
                                isLoading={false}
                                menuIsOpen={openSelectIds.includes(parameter.id)} 

                                // onInputChange={(inputValue, actionMeta) => loadOptions(inputValue, parameter.id, "on_change", actionMeta)}
                                options={recommendTags && recommendTags.parameter_id == parameter.id ? recommendTags.values : []}
                                noOptionsMessage={() => "Loading "+parameter.name}
                            />
                        </div>
                    </div>
                    {(parameter.is_sub_tag == 1 && (props.selectedTags.length > 0 && props.selectedTags?.find((selectedTag)=>(selectedTag.parameter_id === parameter.id && selectedTag.is_sub_tag == 1))) ) && 
                    <div className='row' style={{backgroundColor: "#8cff0024", paddingBottom:"20px"}}>
                        <div className="col-sm-12 d-flex aling-items-center">
                            <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center"></label>
                        </div>
                        {/* {selectedTags.tags?.map((tag, key)=>{ */}

                        {props.selectedTags?.find((selectedTag)=>selectedTag.parameter_id === parameter.id).tags.map((tag, key2)=>{return(

                        <div className='col-sm-12 d-flex aling-items-center' style={{marginBottom:"10px"}} key={key2}>
                            <div className="col-sm-3 d-flex aling-items-center">
                                <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">{EachWordCapitalize(tag.label)}</label>
                            </div>
                            <div className="col-sm-9" ref={el => selectRefs.current[`${parameter.id}_${tag.value}`] = el}>
                            <Select
                                name={tag.value}
                                value={tag.sub_tags}
                                isMulti={true}
                                onChange={(selectedOptions, actionMeta) => {
                                    const additionalParams = {
                                        parameter_id: parameter.id,
                                    };
                                    handleChange(selectedOptions, actionMeta, additionalParams);
                                }}
                                isSearchable={true}
                                isClearable={false}
                                // onInputChange={(inputValue, actionMeta) => loadOptionsSubParameter(inputValue, parameter.id, tag.value, "on_change", actionMeta)}
                                // onMenuOpen={(actionMeta)=>loadOptionsSubParameter("", parameter.id, tag.value,"menu_open",actionMeta)}
                                menuIsOpen={openSelectTagIds.some(item => item.parameter_id === parameter.id && item.tag_id === tag.value)}
                                onMenuOpen={(actionMeta)=>loadOptions("", parameter.id, tag.value,"menu_open",actionMeta)}
                                options={recommendSubTags && (recommendSubTags.parameter_id == parameter.id && recommendSubTags.tag_id == tag.value) ? recommendSubTags.values : []}
                                noOptionsMessage={() => "Loading "+tag.label}
                            />
                            </div>
                        </div>
                        
                        )})}
                    </div>
                }
                    </>
                    )})}
                </div>
            </div>
    

        </div>
    )
}

export default Tags