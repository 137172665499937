import React, { useEffect, useState } from 'react'
import { CheckImage, checkTagsSubTags } from '../Api/CommanJsFunction';
import { ImageUrl } from '../Api/Url';
import { curatorFinishCuration, curatorUpdateDescription } from '../Api/ApiCurator';
import { moderatorFinishModeration } from '../Api/ApiModerator';
import useGetDailyTrack from '../CustomHooks/useGetDailyTrack';
import { Modal, Button } from 'react-bootstrap';
const Post = (props) => {
    let post = props.targets.values[0];
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [skipDescription, setSkipDescription] = useState("");
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);
    const [isOpenWastedModel, setIsOpenWastedModel] = useState(false)
    function toggleShowFullDescription() {
        setShowFullDescription(!showFullDescription);
    }
    const { updateTargetTracking } = useGetDailyTrack(props.setAllTargetCounts);

    function nextPost(current_page, total_pages, status) {
        props.setFinishTargetLoading(true);
        let target = props.for == "curator" ? curatorFinishCuration : moderatorFinishModeration;
        let post_id = props.targets.values[0].id;
        let action = "";
        // target(post_id, props.parameters,props.openItemAnswer||[], status, props.postDescription||"", skipDescription||"", props.editPostId).then((res)=>{
        target(post_id, props.selectedTags, props.openItemAnswer || [], status, props.postDescription || "", skipDescription || "", props.editPostId).then((res) => {
            if (props.for == "moderator") {
                props.setIsShowModeratorTags(false);
                props.setOpenItemAnswer([]);
            }
            else {
                props.setPostDescription('');
            }
            action = res.data.data.action;
            if (!props.editPostId) {
                updateTargetTracking(action, props.setAllTargetCounts)
            }
            props.setEditPostId(null);
            props.setPostNextPage((prev) => prev + 1);
            setIsBtnDisabled(false);
            props.setSelectedTags([]);
            // props.setParameters([]);
            setSkipDescription("");
            setIsOpenWastedModel(false)
            props.setFinishTargetLoading(false);
        }).catch((err) => {
            props.setFinishTargetLoading(false);
        })
    }
    function skip_description(e) {
        setSkipDescription(e.target.value)
    }
    function handleCancel() {
        props.setEditPostId(null);
        props.setPostNextPage((prev) => prev + 1);
        if (props.for == "moderator") {
            props.setIsShowModeratorTags(false);
            props.setOpenItemAnswer([]);
        }
        else {
            props.setPostDescription('');
        }
        // props.setParameters([]);
        props.setSelectedTags([]);
    }
    // function checkTagsAreFilled(){
    //     (JSON.parse(props.parameters).length === props.selectedTags.length && props.selectedTags.every((tag) => tag.is_sub_tag === 1 ? tag.tags.length > 0 && tag.tags.every((subTag) => subTag.sub_tags.length > 0): true))
    // }
    function checkTagsAreFilled(parameters, selectedTags) {
        if (parameters) {
            if (props.for === "curator") {
                if (!props.postDescription) {
                    return false;
                }
            }
            else {
                if (props.openItemAnswer.length == 0) {
                    return false;
                }
            }
            return (
                parameters.length === selectedTags.length &&
                selectedTags.every((tag) =>
                    tag.is_sub_tag === 1
                        ? tag.tags.length > 0 &&
                        tag.tags.every((subTag) => subTag.sub_tags.length > 0)
                        : true
                )
            );
        }
    }
    return (
        <>
            {props.targets.values.length > 0 &&
                <div className="col-lg-4 col-md-4 col-sm-12 mb-25">
                    <div className="social-overview-wrap">
                        <div className="card card-overview-progress border-0">
                            <div className="card-header border-0">
                                <div className="d-flex justify-content-between w-100 align-items-center">
                                    <h6 className='text-dark'>{props.targets.values[0].title}</h6>
                                </div>
                            </div>
                            <div className="card-body curator-post-height pt-0 mt-10">
                                {props.targets.values[0].attachments.length > 0 && (
                                    <div className="card-progress">
                                        <div className='post-img'>
                                            {CheckImage(props.targets.values[0].attachments[0].attachment) == 'image' && (
                                                <img src={ImageUrl() + post.attachments[0].attachment} />
                                            )}
                                            {CheckImage(props.targets.values[0].attachments[0].attachment) == 'video' && (
                                                <video src={ImageUrl() + props.targets.values[0].attachments[0].attachment} controls />
                                            )}
                                            {CheckImage(props.targets.values[0].attachments[0].attachment) == "others" &&
                                                (
                                                    <a href={ImageUrl() + props.targets.values[0].attachments[0].attachment} target="_blank" rel="noopener noreferrer">
                                                        Download Attachment
                                                    </a>
                                                )}
                                        </div>
                                    </div>
                                )}

                                {props.targets.values[0].description &&
                                    <div className='post-dis'><p>
                                        {showFullDescription ?
                                            props.targets.values[0].description :
                                            props.targets.values[0].description.length > 200 ?
                                                props.targets.values[0].description.slice(0, 200) + "..." : post.description}

                                        {props.targets.values[0].description ? props.targets.values[0].description.length > 200 ? !showFullDescription ?
                                            <a onClick={toggleShowFullDescription}>Read More</a>
                                            :
                                            <a onClick={toggleShowFullDescription}>show Less</a>
                                            : null
                                            : null
                                        }


                                    </p></div>
                                }

                                

                                {/* {!props.editPostId ? 
                                <div className="atbd-button-list d-flex justify-content-between">
                                    <button className="btn btn-default btn-squared btn-outline-info " data-toggle="modal" data-target="#modal-basic">Skip </button>

                                    {props.parameters !== "hide" && 
                                    <div>
                                        {(props.for == "curator" && props.parameters == "next" && props.postDescription) || (props.for == "moderator" && props.parameters == "next")  ? 
                                    <button className="btn btn-default btn-squared btn-outline-warning" disabled={isBtnDisabled} onClick={() => nextPost(props.targets.current_page, props.targets.total_pages, 1)}>
                                    Next
                                </button>
                                :
                                <button className="btn btn-default btn-squared btn-outline-info " data-toggle="modal" data-target="#modal-waste">Wasted </button>}
                                </div>
                                }
                                </div>
                                :
                                <div className="atbd-button-list d-flex justify-content-between">
                                    <button className="btn btn-default btn-squared btn-outline-warning"  onClick={() => nextPost(props.targets.current_page, props.targets.total_pages, 1)} disabled={
                                        props.parameters == "hide" || props.parameters== "wasted"  ? true : false
                                        }>Save </button>
                                    <button className="btn btn-default btn-squared btn-outline-warning" onClick={handleCancel}>
                                        Cancel
                                    </button>
                                    
                                </div>
                                } */}
                            </div>
                            <div className='card-footer'>
                            {props.editPostId ?
                                    <div className="atbd-button-list d-flex justify-content-between">
                                        <button className="btn btn-default btn-squared btn-outline-warning" onClick={() => nextPost(props.targets.current_page, props.targets.total_pages, 1)} disabled={!checkTagsAreFilled(props.parameters, props.selectedTags)}>Save </button>
                                        <button className="btn btn-default btn-squared btn-outline-warning" onClick={handleCancel}>
                                            Cancel
                                        </button>
                                    </div>
                                    :
                                    <div className="atbd-button-list d-flex justify-content-between">
                                        {props.selectedTags.length > 0 ?
                                            <button className="btn btn-default btn-squared btn-outline-info" onClick={() => setIsOpenWastedModel(true)}>Skip </button>
                                            :
                                            <button className="btn btn-default btn-squared btn-outline-info " data-toggle="modal" data-target="#modal-basic">Skip</button>}


                                        {checkTagsAreFilled(props.parameters, props.selectedTags) &&
                                            <button className="btn btn-default btn-squared btn-outline-warning" disabled={isBtnDisabled} onClick={() => nextPost(props.targets.current_page, props.targets.total_pages, 1)}>
                                                Next</button>
                                            // :
                                            // <button className="btn btn-default btn-squared btn-outline-info" onClick={()=>setIsOpenWastedModel(true)}>Wasted </button>
                                        }
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="modal-basic modal fade show" id="modal-basic" tabindex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content modal-bg-white ">
                        <div className="modal-header">
                            <h6 className="modal-title">Confirm Skipping</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span data-feather="x"></span></button>
                        </div>
                        <div className="modal-body">
                            <div className="table4 bg-white">
                                <div className="table-responsive">
                                    <table className='table mb-0'>
                                        <tbody>
                                            <tr>
                                                <th scope="row">Why are you skipping this post?</th>
                                                </tr>
                                                <tr>
                                                <td>
                                                    <textarea name="skip_reason" id="" cols="30" rows="10" className='form-control' onChange={(e) => skip_description(e)} value={skipDescription}></textarea>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={() => nextPost(props.targets.current_page, props.targets.total_pages, 2)} data-dismiss="modal" disabled={skipDescription ? false : true}>Skip</button>
                            <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal" onClick={() => setSkipDescription("")}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="modal-waste modal fade show" id="modal-waste" tabindex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content modal-bg-white ">
                        <div className="modal-header">
                            <h6 className="modal-title">Wasted</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span data-feather="x"></span></button>
                        </div>
                        <div className="modal-body">
                            <p>You did not fill all parameters. are sure you want to move next?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={() => nextPost(props.targets.current_page, props.targets.total_pages, 3)} data-dismiss="modal" disabled={isBtnDisabled}>Ok</button>
                            <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div> */}
            <Modal show={isOpenWastedModel}  >
                <Modal.Header>
                    <Modal.Title>Wasted</Modal.Title>
                    <Button variant="outline-secondary" onClick={() => setIsOpenWastedModel(false)}>
                        Close
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <p>You did not fill all parameters. Are you sure you want to move next?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="success"
                        onClick={() => nextPost(props.targets.current_page, props.targets.total_pages, 3)}
                        disabled={false}
                    >
                        Ok
                    </Button>
                    <Button variant="secondary" onClick={() => setIsOpenWastedModel(false)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Post