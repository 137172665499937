import React, { useEffect, useState } from 'react'
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LeftColorPane from './left-color-pane';
import { curatorFinishCuration, curatorGetPostCompleted, curatorGetTag, curatorPostHide } from '../Api/ApiCurator';
import { ImageUrl } from '../Api/Url';
import { CheckImage } from '../Api/CommanJsFunction';
import { moderatorFinishModeration, moderatorGetPostCompleted, moderatorPostHide, moderatorGetTag } from '../Api/ApiModerator';
const Aside = (props) => {
    const [completedPost, setCompletedPost] = useState([]);
    // useEffect(() => {
    //     props.setGetPostCompletedLoading(true);
    //     let targetCompletedPostetUrl = props.content == "curator" ? curatorGetPostCompleted() : moderatorGetPostCompleted();
    //     targetCompletedPostetUrl.then((res) => {
    //         setCompletedPost(res.data.data);
    //         setTimeout(() => {
    //         props.setGetPostCompletedLoading(false);
                
    //         }, 1000);
    //         // setCompletedPost([]);
    //     }).catch(() => {
    //         console.log('failed');
    //     });
    // }, [props.targets]);
    // // }, [props.targets]);
    useEffect(() => {
        const fetchCompletedPosts = async () => {
          props.setGetPostCompletedLoading(true);
          try {
            const targetCompletedPostetUrl = props.content === "curator" ? curatorGetPostCompleted() : moderatorGetPostCompleted();
            const res = await targetCompletedPostetUrl;
            setCompletedPost(res.data.data);
            // props.setGetPostCompletedLoading(false);
          } catch (error) {
            console.error('Failed to fetch completed posts', error);
          } finally {
            // setTimeout(() => {
              props.setGetPostCompletedLoading(false);
            // }, 1000);
          }
        };
    
        fetchCompletedPosts();
      }, []); 
    function completedEdit(post) {
        props.SetGetTagLoading(true);
        props.setEditPostId(post.id);
        props.setTargets((prev) => ({ ...prev, values: [post] }));
        let getTagApi = props.content == "curator" ? curatorGetTag : moderatorGetTag;
        getTagApi(post.id).then((res) => {
            if (props.setPostDescription) { props.setPostDescription(res.data.data.description); }
            if (props.setOpenItemAnswer) { props.setOpenItemAnswer(res.data.data.moderator_post_open_items) }
            let input = props.content == "curator" ? res.data.data.curator_post_parameter : res.data.data.moderator_post_parameter;
            const transformedData = input.map((item) => ({
                parameter_id: item.parameter.id,
                // name: item.parameter.name,
                // for: item.parameter.for,
                is_sub_tag: item.parameter.is_sub_tag || 0,
                // status: item.parameter.status,
                // deleted_at: item.parameter.deleted_at,
                // created_at: item.parameter.created_at,
                // updated_at: item.parameter.updated_at,
                // sub_parameters: item.parameter.sub_parameters || [],
                tags: (item.moderator_post_tag || item.curator_post_tag).map((tagItem) => ({
                    value: tagItem.tag.id,
                    label: tagItem.tag.name,
                    sub_tags: (tagItem.moderator_post_sub_tag || []).map((subTagItem) => ({
                        value: subTagItem.sub_tag.id,
                        label: subTagItem.sub_tag.name,
                    })),
                })),
            }));

            props.setSelectedTags(transformedData);
            if (props.setIsShowModeratorTags) { props.setIsShowModeratorTags(true) }


            // if(props.setIsShowModeratorTags){props.setIsShowModeratorTags(true)}
            // let input = props.content == "curator" ? res.data.data[0].curator_post_parameter : res.data.data[0].moderator_post_parameter;
            // const result = input.map(item => {
            //     const parameter_id = item.parameter_id;
            //     const values = [];
            //     if (item.curator_post_tag) {
            //         values.push(...item.curator_post_tag.map(tag => ({ value: tag.tag.id, label: tag.tag.name })));
            //     }
            //     if (item.moderator_post_tag) {
            //         values.push(...item.moderator_post_tag.map(tag => ({ value: tag.tag.id, label: tag.tag.name })));
            //     }
            //     return { parameter_id, values };
            // });
            // props.setParameterTags(result);

            // const result = input.map(item => {
            //     const parameter_id = item.parameter_id;
            //     const tags = [];
            //     const subTags = [];

            //     // Check and extract values from curator_post_tag
            //     if (item.curator_post_tag) {
            //         tags.push(...item.curator_post_tag.map(tag => ({ value: tag.tag.id, label: tag.tag.name })));
            //         subTags.push(...item.curator_post_tag.map(tag => ({ value: tag.moderator_post_sub_tag.id, label: tag.moderator_post_sub_tag.name })));
            //     }

            //     // Check and extract values from moderator_post_tag
            //     if (item.moderator_post_tag) {
            //         tags.push(...item.moderator_post_tag.map(tag => ({ value: tag.tag.id, label: tag.tag.name })));
            //         subTags.push(...item.moderator_post_tag.map(tag => ({ value: tag.moderator_post_sub_tag.id, label: tag.moderator_post_sub_tag.name })));
            //     }

            //     return { parameter_id, tags, subTags };
            // });

            // // Assuming props.setParameterTags and props.setParameterSubTags update the respective states
            // props.setParameterTags(result.map(item => ({ parameter_id: item.parameter_id, values: item.tags })));
            // props.setParameterSubTags(result.map(item => ({ parameter_id: item.parameter_id, values: item.subTags })));



            // const result = input.map(item => {
            // if(props.setOpenItemAnswer){props.setOpenItemAnswer(item.moderator_post_open_items)}
            //     const parameterTags = {};
            //     let post_tag = props.content == "curator" ? item.curator_post_tag : item.moderator_post_tag
            //     post_tag.forEach(tag => {
            //         if(tag.tag.parameters[0].is_sub_tag)
            //         {
            //             props.setSubParameters((prev)=>[...prev, {parameter_id: tag.tag.parameters[0].parameter_id, values: {value: tag.tag_id,label: tag.tag.name}}]);
            //         }
            //         const parameterId = tag.tag.parameters[0].parameter_id;
            //         if (!parameterTags[parameterId]) {
            //             parameterTags[parameterId] = {
            //                 parameter_id: parameterId,
            //                 values: []
            //             };
            //         }
            //         parameterTags[parameterId].values.push({
            //             value: tag.tag_id,
            //             label: tag.tag.name
            //             });
            //     });
            //     return Object.values(parameterTags);
            // });
            // const sub_result = input.map(item => {
            //     const parameterTags = {};
            //     let post_sub_tag = props.content == "curator" ? item.curator_post_sub_tag : item.moderator_post_sub_tag
            //     post_sub_tag.forEach(tag => {
            //         const parameterId = tag.parameter_id;
            //         if (!parameterTags[tag.tag_id]) {
            //             parameterTags[tag.tag_id] = {
            //                 parameter_id: parameterId,
            //                 tag_id: tag.tag_id,
            //                 values: []
            //             };
            //         }
            //         parameterTags[tag.tag_id].values.push({
            //             value: tag.sub_tag.id,
            //             label: tag.sub_tag.name
            //         });
            //     });
            //     return Object.values(parameterTags);
            // });

            // if(props.setParameterSubTags)
            // {
            //     props.setParameterSubTags(...sub_result);
            // }
            props.SetGetTagLoading(false);
        }).catch((err) => {
            // console.log(err)
            props.SetGetTagLoading(false);
        })
    }
    function postHide(post) {
        props.setPostHideLoading(true);
        let hidePost = props.content == "curator" ? curatorPostHide : moderatorPostHide;
        hidePost(post.id).then((res) => {
            setCompletedPost((prevCompletedPost) =>
                prevCompletedPost.filter((filterPost) => filterPost.post_id !== post.id)
            );
            props.setPostHideLoading(false);
        }).catch((err) => {
            props.setPostHideLoading(false);
        })
    }
    return (
      
            <div className='col-lg-2 col-md-2 col-sm-12'>
                <LeftColorPane total_target={props.targets ? props.targets.total : null} allTargetCounts={props.allTargetCounts}></LeftColorPane>
                <div className="sidebar__menu-group posts">
                    <ul className="sidebar_nav mt-20 ">
                        {completedPost.map((completed, key) => {
                            let post = completed.post;
                            return (
                                <li className='post-content-li' key={key}>
                                    <span data-feather="columns" className="nav-icon"></span>
                                    <div className='posts-content pr-20'>
                                        <div className='post-title'><span className="menu-text">{post.title}</span></div>
                                        <div className='post-img-video'>

                                            {post.attachments[0] &&
                                                <>
                                                    {CheckImage(post.attachments[0].attachment) == 'image' && (
                                                        <img className='w-100' src={ImageUrl() + post.attachments[0].attachment} />
                                                    )}
                                                    {CheckImage(post.attachments[0].attachment) == 'video' && (
                                                        <video className='w-100' src={ImageUrl() + post.attachments[0].attachment} controls />
                                                    )}
                                                    {CheckImage(post.attachments[0].attachment) == "others" &&
                                                        (
                                                            <a href={ImageUrl() + post.attachments[0].attachment} target="_blank" rel="noopener noreferrer">
                                                                Download Attachment
                                                            </a>
                                                        )}
                                                </>
                                            }

                                        </div>
                                    </div>
                                    <div className='align-right pr-20'>
                                        <div className='content-space-between edit-delete'>
                                            {props.targets?.values[0]?.id !== completed.post.id && (
                                                <a title='Edit' onClick={() => completedEdit(completed.post)}>
                                                    <span data-feather="edit" className="nav-icon edit-icon">Edit</span>
                                                </a>
                                            )}
                                            <a title='Hide' onClick={() => postHide(completed.post)}><i className="fa fa-minus-square edit-icon" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        
    );
}
export default Aside;