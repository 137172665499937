import React, { useEffect } from 'react'
import { getDailyTrack } from '../Api/GeneralApi';

const useGetDailyTrack = (setAllTargetCounts) => {
    useEffect(()=>{
        getDailyTrack().then((res)=>{
            setAllTargetCounts(res.data.data);
        }).catch((err)=>{

        })
    },[])
    function updateTargetTracking(action, setAllTargetCounts){
        setAllTargetCounts((prev)=>{
            if(action == "skipped"){
                return { ...prev, skipped: prev.skipped + 1 };
            }
            else if(action == "wasted"){
                return { ...prev, wasted: prev.wasted + 1 };
            }
            else if(action == "achievement"){
                return { ...prev, achievement: prev.achievement + 1 };
            }
            else if(action == "extra"){
                return { ...prev, extra: prev.extra + 1 };
            }
        })
    }
    return { updateTargetTracking };
}

export default useGetDailyTrack