import React from "react";
import BaseLayouts from "./layouts/base-layouts";


function App() {
  return (
    <React.Fragment>
    <BaseLayouts/>
  </React.Fragment>    

  );
}

export default App;
