import axios from 'axios';
import { Configuration, qa } from './Url';

const userListsUrl              = qa()+"user/lists";
const getCompletedPostListUrl   = qa()+"get/completed/post/list"; 
const getPostDetailsUrl         = qa()+"get/post/details";
const getPostTagsDescriptionUrl = qa()+"get/post/tag/description";   
const getPostTagDetailsUrl      = qa()+"get/post/tag/details";
const getOpenItemAnswersUrl     = qa()+"get/postOpenItem/Answers";
const getTimeManagmentDetailsUrl= qa()+"get/time/managment";
const getPerformanceDetailsUrl  = qa()+"get/performance";
const updateMarksUrl            = qa()+"update/mark";
const updatePerformanceStatusUrl= qa()+"update/admin/performance/status";
let config                      = Configuration();

export function userLists(){
    let config = Configuration();
    let url = userListsUrl;
    return axios.get(url,config)
}
export function getCompletedPostList(user_id, for_role){
    let config = Configuration();
    let url = getCompletedPostListUrl;
    let params = {
        user_id : user_id,
        for: for_role,
    }
    config.params = params;
    return axios.get(url,config)
}
export function getPostDetails(post_id){
    let config = Configuration();
    let url = getPostDetailsUrl;
    let params = {
        post_id : post_id,
    }
    config.params = params
    return axios.get(url,config)
}
export function getPostTagsDescription(curator_post_id){
    let config = Configuration();
    let url = getPostTagsDescriptionUrl;
    let params = {
        curator_post_id : curator_post_id,
    }
    config.params = params
    return axios.get(url,config)
}

export function getPostTagDetails(cur_mod_post_id, for_role, page=1){
    let config = Configuration();
    let url = getPostTagDetailsUrl;
    let params = {
        cur_mod_post_id : cur_mod_post_id,
        page            : page,
        for_role        : for_role
    }
    config.params = params;
    return axios.get(url,config)
}
export function getOpenItemAnswers(moderator_post_id, page){
    let config = Configuration();
    let url = getOpenItemAnswersUrl;
    let params = {
        moderator_post_id : moderator_post_id,
        page            : page,
    }
    config.params = params
    return axios.get(url,config)
}
export function getTimeManagmentDetails(user_id){
    let config = Configuration();
    let url = getTimeManagmentDetailsUrl;
    let params = {
        user_id : user_id
    }
    config.params = params
    return axios.get(url,config)
}
export function getPerformanceDetails(user_id, for_role){
    let config = Configuration();
    let url = getPerformanceDetailsUrl;
    let params = {
        user_id : user_id,
        for_role: for_role
    }
    config.params = params;
    return axios.get(url,config)
}
export function updateMarks(for_role, post_parameters, post_sub_parameters = null, description_marks=null, openItemMarks=null){
    let config = Configuration();
    let url = updateMarksUrl;
    let data = {
        for_role : for_role,
        post_parameters : post_parameters,
        description_mark_details: description_marks,
        openItemMarks : openItemMarks
    }
    if(post_sub_parameters){
        data.post_sub_parameters = post_sub_parameters
    }
    return axios.post(url,data,config)
}
export function updatePerformanceStatus(status, user_id){
    let config = Configuration();
    let url = updatePerformanceStatusUrl;
    let data = {
        user_id : user_id,
        status  : status,
    }
    return axios.post(url,data,config)
}