import axios from 'axios';
import { Configuration, UserUrl } from './Url';

const religionUrl               = UserUrl()+"get/religion/categories";
const denominationUrl           = UserUrl()+"get/denominations";
const getBookUrl                = UserUrl()+"get/search/books"
let config                      = Configuration();

export function getReligion(){
    let config = Configuration();
    let url = religionUrl;
    return axios.get(url,config)
}
export function getDenomination(religion_id){
    let config = Configuration();
    let url = denominationUrl;
    let params = {
        religion_id : religion_id
    }
    config.params = params;
    return axios.get(url,config)
}
export function getBooks(religion_id,search){
    let config = Configuration();
    let url = getBookUrl;
    let params = {
        religion_id : religion_id,
        search      : search
    }
    config.params = params;
    return axios.get(url,config)
}