import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from '../../layouts/header';
import Footer from '../../layouts/footer';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Icon from '@mui/material/Icon';
import { FileUpload } from '@mui/icons-material';




<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />

function valuetext() {
    return `${10}`;
}
const FaithHub = ({setIsShowHeader}) => {
    useEffect(()=>{
        setIsShowHeader(true);
    },[])
    return (
        <>
            {/* <Header></Header> */}
            <main className="main-content">
                <div className="contents no-left-pane">
                    <div className="container-fluid mt-20">
                        <div className='row'>
                            <div className='col-lg-2 col-md-12 col-sm-12'>
                                <div className='card'>
                                    <div className='card-header'>Search Filter</div>
                                    <div className='card-body faith-filter'>
                                        <form action="#">
                                            <div className="form-group">
                                                <input type="text" className="form-control ih-small ip-light radius-xs b-light px-15" placeholder="Post Name" />
                                            </div>

                                            <div className="form-group mb-0">
                                                <input type="text" className="form-control ih-small ip-light radius-xs b-light px-15" placeholder="Date of Post" Type='date' />
                                            </div>

                                        </form>
                                    </div>
                                    <div className='card-footer pt-0'><button className="btn btn-success btn-default btn-squared ">Submit</button></div>
                                </div>
                                <div className='card mt-10'>
                                    <div className='card-header'>Posts</div>
                                    <div className='card-body faithhub-post-height'>
                                        <div className="product-cart mb-sm-0 mb-20">
                                            <div className="table-responsive">
                                                <table id="cart" className="table table-borderless table-hover">

                                                    <tbody>
                                                        <tr>
                                                            <td className="Product-cart-title">
                                                                <div className="media  align-items-center">
                                                                    <img className="mr-3 wh-60 align-self-center radius-xl bg-opacity-primary" src="/assets/img/cart1.png" alt="" />
                                                                    <div className="media-body">
                                                                        <h5 className="mt-0">Post Title</h5>
                                                                        <p>Lorem Ipsum is simply dummy text industry. </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="Product-cart-title">
                                                                <div className="media  align-items-center">
                                                                    <img className="mr-3 wh-60 align-self-center radius-xl bg-opacity-primary" src="/assets/img/bottles.png" alt="" />
                                                                    <div className="media-body">
                                                                        <h5 className="mt-0">Post Title</h5>
                                                                        <p>Lorem Ipsum is simply dummy text industry.</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="Product-cart-title">
                                                                <div className="media  align-items-center">
                                                                    <img className="mr-3 wh-60 align-self-center radius-xl bg-opacity-primary" src="/assets/img/bottles.png" alt="" />
                                                                    <div className="media-body">
                                                                        <h5 className="mt-0">Post Title</h5>
                                                                        <p>Lorem Ipsum is simply dummy text industry.</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="Product-cart-title">
                                                                <div className="media align-items-center">
                                                                    <img className="mr-3 wh-60 align-self-center radius-xl bg-opacity-primary" src="/assets/img/bottles.png" alt="" />
                                                                    <div className="media-body">
                                                                        <h5 className="mt-0">Post Title</h5>
                                                                        <p>Lorem Ipsum is simply dummy text industry.</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="Product-cart-title">
                                                                <div className="media align-items-center">
                                                                    <img className="mr-3 wh-60 align-self-center radius-xl bg-opacity-primary" src="/assets/img/bottles.png" alt="" />
                                                                    <div className="media-body">
                                                                        <h5 className="mt-0">Post Title</h5>
                                                                        <p>Lorem Ipsum is simply dummy text industry.</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-5 col-md-12 col-sm-12'>
                                <div className="card">
                                    <div className="card-header color-dark fw-500">
                                        <h6>Posts </h6>
                                    </div>
                                    <div className="card-body p-0 faith-hubheight">
                                        <div className="row m-0 pt-20">
                                            <div className="col-xxl-6 col-lg-6 col-md-12 col-sm-12">
                                                <div className="feature-cards feature-card--1">
                                                    <div className="reply-editor media">
                                                        <div className="reply-editor__author">
                                                            <img src="/assets/img/landing-logo.png" className="bg-opacity-primary d-flex" alt="Reply Editor Author" />
                                                        </div>

                                                        <div className="reply-editor__form media-body">
                                                            <h6 className='comments-name'>Name</h6>
                                                            <p><span className='followers'>25 Followers</span><span className='secs'>36 secs ago</span></p>
                                                        </div>
                                                    </div>
                                                    <figure className="feather-cards__figure">
                                                        <img src="/assets/img/blog-single1.png" alt="" />
                                                        <figcaption>
                                                            <div className="feature-cards__top">
                                                                <span>02 June 2023</span><div className="dropdown  dropdown-click">
                                                                    <button className="btn-link border-0 bg-transparent p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <span data-feather="more-horizontal"></span>
                                                                    </button>
                                                                    <div className="dropdown-default dropdown-bottomRight dropdown-menu">
                                                                        <a className="dropdown-item" href="#">Edit</a>
                                                                        <a className="dropdown-item" href="#">Delete</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <h5 className='mt-0'>Technology Change the World</h5>
                                                            <p>Lorem Ipsum is simply dummy text of the printer took a galley of type and scrambled
                                                            </p>
                                                            <div className="feature-cards__bottom">
                                                                <div className="feedbackSection">

                                                                    <div className="cci__comment-actions">
                                                                        <a href="#" className="btn-like likes mr-10">
                                                                            <span className='like-icons' data-feather="thumbs-up"></span>
                                                                            <span className="line-count">75</span>
                                                                        </a>
                                                                        <a href="#" className="btn-dislike color-dark">
                                                                            <span className='dislike-icon' data-feather="thumbs-down"></span>
                                                                            <span className="line-count">5</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="feedback">
                                                                        <a href='#'> <span data-feather="message-square" className="comments-icon nav-icon"></span></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-body pb-10">
                                                                <div className="reply-editor media">
                                                                    <div className="reply-editor__author">
                                                                        <img src="/assets/img/landing-logo.png" className="bg-opacity-primary d-flex" alt="Reply Editor Author" />
                                                                    </div>
                                                                    <div className="reply-editor__form media-body">
                                                                        <div className="form-row">
                                                                            <div className="form-group col-12">
                                                                                <textarea name="reply-text" className="form-control mb-4"></textarea>
                                                                                <button className="btn btn-primary btn-lg btn-squared btn-shadow-primary fw-400">Add Comment</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </figcaption>
                                                    </figure>
                                                </div>
                                                <div className="feature-cards feature-card--1">
                                                    <div className="reply-editor media">
                                                        <div className="reply-editor__author">
                                                            <img src="/assets/img/landing-logo.png" className="bg-opacity-primary d-flex" alt="Reply Editor Author" />
                                                        </div>

                                                        <div className="reply-editor__form media-body">
                                                            <h6 className='comments-name'>Name</h6>
                                                            <p><span className='followers'>25 Followers</span><span className='secs'>36 secs ago</span></p>
                                                        </div>
                                                    </div>
                                                    <figure className="feather-cards__figure">
                                                        <img src="/assets/img/blog-single1.png" alt="" />
                                                        <figcaption>
                                                            <div className="feature-cards__top">
                                                                <span>02 June 2023</span><div className="dropdown  dropdown-click">
                                                                    <button className="btn-link border-0 bg-transparent p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <span data-feather="more-horizontal"></span>
                                                                    </button>
                                                                    <div className="dropdown-default dropdown-bottomRight dropdown-menu">
                                                                        <a className="dropdown-item" href="#">Edit</a>
                                                                        <a className="dropdown-item" href="#">Delete</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <h5 className='mt-0'>Technology Change the World</h5>
                                                            <p>Lorem Ipsum is simply dummy text of the printer took a galley of type and scrambled
                                                            </p>
                                                            <div className="feature-cards__bottom">
                                                                <div className="feedbackSection">

                                                                    <div className="cci__comment-actions">
                                                                        <a href="#" className="btn-like likes mr-10">
                                                                            <span className='like-icons' data-feather="thumbs-up"></span>
                                                                            <span className="line-count">2</span>
                                                                        </a>
                                                                        <a href="#" className="btn-dislike color-dark">
                                                                            <span className='dislike-icon' data-feather="thumbs-down"></span>
                                                                            <span className="line-count">15</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="feedback">
                                                                        <a href='#'> <span data-feather="message-square" className="comments-icon nav-icon"></span></a>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </figcaption>
                                                    </figure>
                                                </div>
                                            </div>
                                            <div className="col-xxl-6 col-lg-6 col-md-12 col-sm-12 mb-25">
                                                <div className="feature-cards feature-card--1">
                                                    <div className="reply-editor media">
                                                        <div className="reply-editor__author">
                                                            <img src="/assets/img/logo_dark.png" className="bg-opacity-primary d-flex" alt="Reply Editor Author" />
                                                        </div>

                                                        <div className="reply-editor__form media-body">
                                                            <h6 className='comments-name'>Name</h6>
                                                            <p><span className='followers'>25 Followers</span><span className='secs'>36 secs ago</span></p>
                                                        </div>
                                                    </div>
                                                    <figure className="feather-cards__figure">
                                                        <img src="/assets/img/blog-single1.png" alt="" />
                                                        <figcaption>
                                                            <div className="feature-cards__top">
                                                                <span>02 June 2023</span><div className="dropdown  dropdown-click">
                                                                    <button className="btn-link border-0 bg-transparent p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <span data-feather="more-horizontal"></span>
                                                                    </button>
                                                                    <div className="dropdown-default dropdown-bottomRight dropdown-menu">
                                                                        <a className="dropdown-item" href="#">Edit</a>
                                                                        <a className="dropdown-item" href="#">Delete</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <h5 className='mt-0'>Technology Change the World</h5>
                                                            <p>Lorem Ipsum is simply dummy text of the printer took a galley of type and scrambled
                                                            </p>
                                                            <div className="feature-cards__bottom">
                                                                <div className="feedbackSection">
                                                                    <div className="cci__comment-actions">
                                                                        <a href="#" className="btn-like mr-10">
                                                                            <span className='like-icons' data-feather="thumbs-up"></span>
                                                                            <span className="line-count">0</span>
                                                                        </a>
                                                                        <a href="#" className="btn-dislike">
                                                                            <span className='dislike-icon' data-feather="thumbs-down"></span>
                                                                            <span className="line-count">0</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="feedback">
                                                                        <a href='#'> <span data-feather="message-square" className="comments-icon nav-icon"></span></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-body pb-10">
                                                                <div className="reply-editor media">
                                                                    <div className="reply-editor__author">
                                                                        <img src="/assets/img/logo_dark.png" className="bg-opacity-primary d-flex" alt="Reply Editor Author" />
                                                                    </div>
                                                                    <div className="reply-editor__form media-body">
                                                                        <div className="form-row">
                                                                            <div className="form-group col-12">
                                                                                <textarea name="reply-text" className="form-control mb-4"></textarea>
                                                                                <button className="btn btn-primary btn-lg btn-squared btn-shadow-primary fw-400">Add Comment</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </figcaption>
                                                    </figure>
                                                </div>
                                                <div className="feature-cards feature-card--1">
                                                    <div className="reply-editor media">
                                                        <div className="reply-editor__author">
                                                            <img src="/assets/img/logo_dark.png" className="bg-opacity-primary d-flex" alt="Reply Editor Author" />
                                                        </div>

                                                        <div className="reply-editor__form media-body">
                                                            <h6 className='comments-name'>Name</h6>
                                                            <p><span className='followers'>25 Followers</span><span className='secs'>36 secs ago</span></p>
                                                        </div>
                                                    </div>
                                                    <figure className="feather-cards__figure">
                                                        <img src="/assets/img/blog-single1.png" alt="" />
                                                        <figcaption>
                                                            <div className="feature-cards__top">
                                                                <span>02 June 2023</span><div className="dropdown  dropdown-click">
                                                                    <button className="btn-link border-0 bg-transparent p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <span data-feather="more-horizontal"></span>
                                                                    </button>
                                                                    <div className="dropdown-default dropdown-bottomRight dropdown-menu">
                                                                        <a className="dropdown-item" href="#">Edit</a>
                                                                        <a className="dropdown-item" href="#">Delete</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <h5 className='mt-0'>Technology Change the World</h5>
                                                            <p>Lorem Ipsum is simply dummy text of the printer took a galley of type and scrambled
                                                            </p>
                                                            <div className="feature-cards__bottom">
                                                                <div className="feedbackSection">
                                                                    <div className="cci__comment-actions">
                                                                        <a href="#" className="btn-like mr-10">
                                                                            <span className='like-icons' data-feather="thumbs-up"></span>
                                                                            <span className="line-count">0</span>
                                                                        </a>
                                                                        <a href="#" className="btn-dislike color-dark">
                                                                            <span className='dislike-icon' data-feather="thumbs-down"></span>
                                                                            <span className="line-count">10</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="feedback">
                                                                        <a href='#'> <span data-feather="message-square" className="comments-icon nav-icon"></span></a>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </figcaption>
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-12 col-sm-12'>
                                <div className="card">
                                    <div className="card-header color-dark fw-500">
                                        <h6>Upload Post</h6>
                                    </div>
                                    <div className="card-body upload-post-height">
                                        <div className='row m-10 border p-10 border-radius'>
                                            <div className='col-lg-8 col-md-8 col-sm-12'><div className='position-absolute'><input className='form-control upload-heading' type='text' placeholder='Heading' /><a className="compose-close uploadheadclose" href="#" data-trigger="compose">
                                                <span data-feather="x"></span></a></div></div>
                                            <div className='col-lg-4 col-md-4 col-sm-12 p-0'>
                                                <div className='head-btn text-right'>
                                                    <Button className='custom-btn' variant="contained" component="label"> <Icon baseClassName="fas" className="fa-image" />
                                                        <input type="file" hidden />
                                                    </Button>
                                                    <Button className='video-btn custom-btn' variant="contained" component="label"> <Icon baseClassName="fas" className="fa-video" />
                                                        <input type="file" hidden />
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className='col-lg-12 mt-20 mb-20'>
                                                <div className='textarea-box'>
                                                    <textarea className='form-control border' rows={10}></textarea>
                                                </div>
                                            </div>
                                            <div className='col-lg-12 color-btn justify-content-evenly'>
                                                <div className="atbd-button-list d-flex flex-wrap">
                                                    <button className="btn btn-secondary btn-default btn-squared ">&nbsp;</button>
                                                    <button className="btn btn-success btn-default btn-squared ">&nbsp;</button>
                                                    <button className="btn btn-info btn-default btn-squared ">&nbsp;</button>
                                                    <button className="btn btn-warning btn-default btn-squared ">&nbsp;</button>
                                                    <button className="btn btn-danger btn-default btn-squared ">&nbsp;</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row m-10'>
                                            <div className="col-6">

                                                <div className="mb-3">
                                                    <div className="checkbox-theme-default custom-checkbox ">
                                                        <input className="checkbox" type="checkbox" id="check-un1" />
                                                        <label for="check-un1">
                                                            <span className="checkbox-text">Host live event</span>
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-6">
                                                <div className="mb-3">
                                                    <div className="checkbox-theme-default custom-checkbox ">
                                                        <input className="checkbox" type="checkbox" id="check-un1" />
                                                        <label for="check-un1">
                                                            <span className="checkbox-text">Duration</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12 mb-2'>
                                                <div className="with-icon">
                                                    <span className="las la-map-marker color-light"></span>
                                                    <input type="text" className="form-control  ih-medium ip-lightradius-xs b-light" id="inputNameIcon" placeholder="Location" />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="mb-3">
                                                    <div className="checkbox-theme-default custom-checkbox ">
                                                        <input className="checkbox" type="checkbox" id="check-un3" />
                                                        <label for="check-un3">
                                                            <span className="checkbox-text">Include Funding? </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row m-10'>
                                            <div className='col-12 border border-radius'>
                                                <div className='mt-2'>
                                                    <textarea className='form-control' rows={2} placeholder='Type to text'></textarea>
                                                </div>
                                                <div className='ml-2'>
                                                    <Box>
                                                        <Slider
                                                            aria-label="Small steps"
                                                            defaultValue={50}
                                                            getAriaValueText={valuetext}
                                                            step={10}
                                                            marks
                                                            min={10}
                                                            max={100}
                                                            valueLabelDisplay="auto"
                                                        />
                                                    </Box>
                                                </div>
                                                <div className='m-2'>
                                                    <input className='orange-border form-control' placeholder='$50' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card-footer justify-content-end'><button className='btn btn-default btn-warning'>Post</button></div>
                                </div>
                            </div>
                            <div className='col-lg-2 col-md-12 col-sm-12'>
                                <div className="card">
                                    <div className="card-header color-dark fw-500">
                                        <h6>Create Banner</h6>
                                    </div>
                                    <div className="card-body p-0 create-post-height">
                                        <div className='table-responsive'>
                                            <table className='table table-bordered'>
                                                <tr>
                                                    <td>
                                                        <div className="card">
                                                            <div className="d-flex justify-content-center">
                                                                <div className="card-body w-100 p-0">
                                                                    <div className='create-banner justify-content-evenly'>
                                                                        <div className="blue-box"><p>It has roots in a piece of classical Latin. <br /><span className='tag-author'>McClintock</span></p></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="card">
                                                            <div className="d-flex justify-content-center">
                                                                <div className="card-body w-100 p-0">
                                                                    <div className='create-banner justify-content-evenly'>
                                                                        <div className="blue-box"><p>It has roots in a piece of classical Latin. <br /><span className='tag-author'>McClintock</span></p></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="card">
                                                            <div className="d-flex justify-content-center">
                                                                <div className="card-body w-100 p-0">
                                                                    <div className='create-banner justify-content-evenly'>
                                                                        <div className="blue-box"><p>It has roots in a piece of classical Latin. <br /><span className='tag-author'>McClintock</span></p></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="card">
                                                            <div className="d-flex justify-content-center">
                                                                <div className="card-body w-100 p-0">
                                                                    <div className='create-banner justify-content-evenly'>
                                                                        <div className="blue-box"><p>It has roots in a piece of classical Latin. <br /><span className='tag-author'>McClintock</span></p></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div className='card-footer pl-0 pr-0 justify-content-evenly'>
                                        <div className="atbd-button-list d-flex flex-wrap">
                                            <button className="btn btn-info btn-default btn-squared ">Upload</button>
                                            <button className="btn btn-warning btn-default btn-squared ">Cancel </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='card'>
                                    <div className='card-body drag-file-height'>
                                        <div className='table-responsive'>
                                            <table className='table table-bordered'>
                                                <tr>
                                                    <td><select className='form-control'>
                                                        <option selected>Language</option>
                                                        <option>Tamil</option>
                                                        <option>Eng</option>
                                                        <option>Hindi</option>
                                                    </select></td>
                                                </tr>
                                                <tr>
                                                    <td><select className='form-control'>
                                                        <option selected>Religion</option>
                                                        <option>Hindu</option>
                                                        <option>Islam</option>
                                                        <option>Christan</option>
                                                    </select></td>
                                                </tr>
                                                <tr>
                                                    <td><select className='form-control'>
                                                        <option>Denomination</option>
                                                        <option>Hindu</option>
                                                        <option>Islam</option>
                                                        <option>Christan</option>
                                                    </select></td>
                                                </tr>
                                                <tr>
                                                    <td>

                                                        <textarea className='form-control' rows={2} placeholder='Type Quote'></textarea></td>
                                                </tr>
                                                <tr>
                                                    <td>

                                                        <div className="atbd-upload">
                                                            <div className="atbd-upload-avatar media-import dropzone-md-s">
                                                                <p className="color-light mt-0 fs-15">Upload .xlsx file for many quotes</p>
                                                            </div>
                                                            <div className="avatar-up">
                                                                <input type="file" name="upload-avatar-input" className="upload-avatar-input" />
                                                            </div>
                                                        </div></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div className='card-footer pl-0 pr-0 justify-content-evenly'>
                                        <div className="atbd-button-list d-flex flex-wrap">
                                            <button className="btn btn-success btn-default btn-squared ">Save</button>
                                            <button className="btn btn-warning btn-default btn-squared ">Cancel </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>

                <div className="modal fade" id="vivewdocument" tabindex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="example-Modal3">View Officer</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
                            </div>
                            <div className="modal-body hub-view-height">
                                <table className='table m-0'>
                                    <tr>
                                        <td>Profile Picture</td>
                                        <td><div className='user-img'><span className="avatar avatar-light avatar-lg avatar-circle">
                                            <img className="avatar-img" src="/assets/img/author/1.jpg" alt="" />
                                        </span></div></td>
                                    </tr>
                                    <tr>
                                        <td>Role</td>
                                        <td>Role Name</td>
                                    </tr>
                                    <tr>
                                        <td>Date Registered</td>
                                        <td>01/06/2023</td>
                                    </tr>
                                    <tr>
                                        <td>Main Language</td>
                                        <td>tamil</td>
                                    </tr>
                                    <tr>
                                        <td>Written Language</td>
                                        <td>Tamil</td>
                                    </tr>
                                    <tr>
                                        <td>Other Language</td>
                                        <td>English</td>
                                    </tr>
                                    <tr>
                                        <td>Books Associated</td>
                                        <td><div className="atbd-tag-wrap">
                                            <div className="tag-box"><span className="atbd-tag tag-light">Positive values <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                <span className="atbd-tag tag-light">Tag 3 <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                <span className="atbd-tag tag-light">Tag 3 <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                <span className="atbd-tag tag-light">Tag 3 <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                            </div>
                                        </div></td>
                                    </tr>
                                    <tr>
                                        <td>Religious channel profile name</td>
                                        <td>Profile Name</td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td>test@gmail.com</td>
                                    </tr>
                                    <tr>
                                        <td>Phone Number</td>
                                        <td>+91 987 456 1230</td>
                                    </tr>
                                    <tr>
                                        <td>Roles Assigned</td>
                                        <td><div className="list-box">
                                            <ul>
                                                <li className="list-box__item">Role Name1</li>
                                                <li className="list-box__item">Role Name2</li>
                                                <li className="list-box__item"><a href='#'>Add Role +</a></li>
                                            </ul>
                                        </div></td>
                                    </tr>
                                    <tr>
                                        <td>Login Time</td>
                                        <td>16:30:45</td>
                                    </tr>
                                    <tr>
                                        <td>Logout Time</td>
                                        <td>16:30:45</td>
                                    </tr>
                                    <tr>
                                        <td>Break Times</td>
                                        <td><table className='table'>
                                            <thead>
                                                <th>Break</th>
                                                <th>Time</th>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Lunch</td>
                                                    <td>12:30</td>
                                                </tr>
                                                <tr>
                                                    <td>Tea</td>
                                                    <td>16:30</td>
                                                </tr>
                                            </tbody>
                                        </table></td>
                                    </tr>
                                    <tr>
                                        <td>Set Target</td>
                                        <td><div className='atbd-button-list d-flex flex-wrap'> <span>145</span> <button className='btn btn-default btn-warning'>Edit</button></div></td>
                                    </tr>
                                    <tr>
                                        <td>Achieved target daily</td>
                                        <td><span>145</span></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-success btn-sm">Save changes</button>
                                <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </main >

        </>
    );
}
export default FaithHub;
