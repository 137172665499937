import React, { useEffect, useState } from 'react'
import { getStorageToken, setStorageToken } from '../Api/Storage';
import { LoginApi } from '../Api/Auth';
import { Navigate, useNavigate, Link } from 'react-router-dom';

const Login = ({setIsShowHeader, profile, setPathname}) => {
    setPathname(window.location.pathname);

    const [isResponseLoginError, setIsResponseLoginError] = useState(false);
    const [responseLoginErrors, setResponseLoginErrors] = useState({});
    const redirect = useNavigate();
    const initialLoginStateErrors = {
        password: { required: false, errMsg: "" },
        username: { required: false, errMsg: "" },
    };
    const [isLoginBtnDisabled, setIsLoginBtnDisabled] = useState(false)
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [loginErrors, setLoginErrors] = useState(initialLoginStateErrors);
    const [isMobile, setIsMobile] = useState(false);
    
    // const [isMobile, setIsMobile] = useState(isNaN(cookies.get("username")) || cookies.get("username") == "" ? false : true);
    // const [mobileCode, setMobileCode] = useState(mcc)
    const [hasError, setHasError] = useState(false);
    const [loginErrorNames, setLoginErrorNames] = useState([]);
    const [login, setLogin] = useState({});
    useEffect(()=>{
        if(!getStorageToken()){
            // redirect('/')
        }
        else if(!profile){
            // redirect('/')
        }
        else{
            // redirect('/auth')
        }
    },[profile])
    useEffect(()=>{
        setIsShowHeader(false);
    },[])
    function loginCredentials(e) {
        if (e.target.name === 'username') {
            if (isNaN(e.target.value) || e.target.value == "") {
                setIsMobile(false)
            }
            else {
                setIsMobile(true);
            }
            if(isMobile)
            {
                // if (!login.mcc_id)
                // {
                //     login.mcc_id = props.mobileCode[0].id;
                // }
            }
            else
            {
                // delete login.mcc_id;
            }
        }
        setHasError(false)
        setLogin({ ...login, [e.target.name]: e.target.value });
        }
        function userLogin(e) {
        e.preventDefault();
        let errors = initialLoginStateErrors;
        let hasError = false;
        if (!login.username) {
            errors.username.required = true;
            errors.username.errMsg = "username is required";
            hasError = true;
        }
        if (!login.password) {
            errors.password.required = true;
            errors.password.errMsg = "password is required";
            hasError = true;
        }
        
        setHasError(hasError);
        setLoginErrors(errors);
        if (!hasError) {
            setIsLoginBtnDisabled(true);
            LoginApi(login).then((response) => {
                if (response.data.message === "Success") {
                    let token = response.data.data.access_token;
                    // if(isRememberMe)
                    // {
                    //     cookies.set('username', login.username, { path: '/' });
                    //     cookies.set('password', login.password, { path: '/' });
                    // }
                    setStorageToken(token);
                    
                    // return redirect('/curator');
                    let accounts = JSON.parse(localStorage.getItem('accounts'));
                    if(!accounts){
                        accounts = [];
                    }
                    accounts.push(token);
                    localStorage.setItem('accounts', JSON.stringify(accounts));
                    return redirect('/auth');
                }
            }).catch((err) => {
                setIsLoginBtnDisabled(false);
                let reserr = [];
                let errors = err.response.data.error;
                setIsResponseLoginError(true);
                setResponseLoginErrors(errors);

            }).finally(() => {
                  
            });
        }
    }

    return (
        <>
            <main className="main-content">
                <div className="signUP-admin">
                    <div className="container-fluid">
                        <div className="row justify-content-center sign-height">
                            <div className="col-xl-7 col-lg-7 col-md-7 p-0 landlogo bg-orange">
                                <div className="">
                                    <div className="landlogoimg ">
                                        <img className="img-fluid svg login-logo" src="/assets/img/faithhub-orange-01.png" alt="img" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 signUp-admin-right">
                                <div className="signUp-admin-right p-md-40 p-10">
                                    {/* <div className="signUp-topbar d-flex align-items-center justify-content-md-end justify-content-center mt-md-0 mb-md-0 mt-20 mb-1">
                                        <p className="mb-0">
                                        Don't have an account? <a href="#" className="color-primary">Sign up</a>
                                        </p>
                                    </div> */}
                                    <div className="row justify-content-center ">
                                        <div className="col-xl-12 col-lg-12 col-md-12">
                                            <div className="edit-profile mt-md-25 mt-0 login-form-height">
                                                <div className="card border-0">
                                                    <div className="card-header border-0  pb-md-15 pb-10 pt-md-20 pt-10 ">
                                                        <div className="edit-profile__title">
                                                            <h6>Login</h6>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="edit-profile__body">
                                                            <div className="form-group mb-20">
                                                                <label for="username">Email Address</label>
                                                                <input type="text" className="form-control" id="username" placeholder="email" onChange={loginCredentials} value={login.username} name="username"/>
                                                                
                                                                {hasError && <span style={{ color: "red" }}>{loginErrors.username.errMsg}</span>}
                                                            </div>
                                                            <div className="form-group mb-15">
                                                                <label for="password-field">password</label>
                                                                <div className="position-relative">
                                                                    <input id="password-field" type={isShowPassword ? "text" : "password"} className="form-control" name="password" placeholder='password' onChange={loginCredentials} />
                                                                    {hasError && <span style={{ color: "red" }}>{loginErrors.password.errMsg}</span>}
                                                                    <div className={`fa fa-fw ${isShowPassword ? "fa-eye" : "fa-eye-slash"} text-light fs-16 field-icon toggle-password2`} onClick={()=>setIsShowPassword((prev)=>!prev)}></div>
                                                                </div>
                                                            </div>
                                                            <div className="signUp-condition signIn-condition">
                                                                <div className="checkbox-theme-default custom-checkbox ">
                                                                    {/* <input className="checkbox" type="checkbox" id="check-1" />
                                                                    <label for="check-1">
                                                                        <span className="checkbox-text">Keep me logged in</span>
                                                                    </label> */}
                                                                </div>
                                                                <a href="https://myaccount.evzone.app/forgotpage">forget password</a>
                                                            </div>
                                                            <div className="button-group d-flex pt-1 justify-content-md-start justify-content-center">
                                                                <button disabled={isLoginBtnDisabled} className="btn btn-success btn-default btn-squared mr-15 text-capitalize lh-normal px-50 py-15 signIn-createBtn "  onClick={userLogin}> 
                                                                    sign in
                                                                </button>
                                                            </div>
                                                            {/* <p className="social-connector text-center mb-sm-25 mb-15  mt-sm-30 mt-20"><span>Or</span></p>
                                                            <div className="button-group d-flex align-items-center justify-content-md-start justify-content-center">
                                                                <ul className="signUp-socialBtn">
                                                                    <li>
                                                                        <button className="btn text-dark px-30"><img className="svg" src="/assets/img/svg/google.svg" alt="img" /> Sign up with
                                                                            Google</button>
                                                                    </li>
                                                                    <li>
                                                                        <button className=" radius-md wh-48 content-center"><img className="svg" src="/assets/img/svg/facebook.svg" alt="img" /></button>
                                                                    </li>
                                                                    <li>
                                                                        <button className="radius-md wh-48 content-center"><img className="svg" src="/assets/img/svg/twitter.svg" alt="img" /></button>
                                                                    </li>
                                                                </ul>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
export default Login;