import React, { useEffect } from 'react'
import { Link, useNavigate, NavLink, useLocation, useParams } from "react-router-dom";

import { getStorageToken, removeStorageToken, setStorageToken } from '../Api/Storage';
import { RefreshTokenApi } from '../Api/Auth';
import { useState } from 'react';
import { adminUpdateActiveUserTime, adminUserStatusList, checkAdminLogoutTime, updateUserStatus } from '../Api/GeneralApi';
import { ReactSVG } from 'react-svg';
import { formatDate, formatTime, formatUTCDate } from '../Api/CommanJsFunction';
import useCheckUserStatus from '../CustomHooks/useCheckUserStatus';
import { getAuthIdFromUrl } from '../Api/Url';
const Header = ({ breakContents, address, profile, latlong, setBreakContents }) => {
    const authid  = getAuthIdFromUrl();
    const redirect = useNavigate();
    let inactiveTimeout;
    const location = useLocation();


    const [refreshToken, setRefreshToken] = useState();
    const [isActive, setIsActive] = useState(true);
    const [userStatusValue, setUserStatusValue] = useState();
    const [userStatusName, setUserStatusName] = useState();
    // const [breakContents, setBreakContents] = useState([]);
    const [userBreakDetails, setUserBreakDetails] = useState();
    const [title, setTitle] = useState('');


    useCheckUserStatus("", setUserBreakDetails);

    // useEffect(() => {
    //     if (!getStorageToken()) {
    //         redirect('/')
    //     }
    // }, [])

    useEffect(() => {
        checkAdminLogoutTime().then((res) => {
        }).catch((err) => {
        })
    }, [])
    useEffect(() => {
        let date = formatUTCDate(new Date());
        let interval = new Date().setHours(23, 59, 59, 999) - new Date();
        let interval_for_active_user_time = setInterval(() => {
            adminUpdateActiveUserTime(true, address, latlong, date).then((res) => {
                if (res.data.data.length == 0) {

                }
                else {
                    redirect(`/u/${authid}/auth`, {
                        state: {
                            // for: res.data.data
                            // errorMessage: 'You are inactive in 30 mins.',
                        },
                    });
                }
            }).catch((err) => {

            });
        }, interval)
        return () => {
            clearInterval(interval_for_active_user_time);
        };
    }, []);
    useEffect(() => {
        let date = formatUTCDate(new Date());
        let interval_for_active_user_time = setInterval(() => {
            adminUpdateActiveUserTime(false, address, latlong, date).then((res) => {
                if (res.data.data.length == 0) {

                }
                else {
                    redirect(`/u/${authid}/auth`, {
                        state: {
                            // for: res.data.data
                            // errorMessage: 'You are inactive in 30 mins.',
                        },
                    });
                }
            }).catch((err) => {

            });
        }, 60000)
        return () => {
            clearInterval(interval_for_active_user_time);
        };
    }, []);
    // useEffect(() => {
    //     document.addEventListener('mousemove', handleUserActivity);
    //     document.addEventListener('keydown', handleUserActivity);
    // }, [])
    useEffect(() => {
        document.addEventListener('mousemove', handleUserActivity);
        document.addEventListener('keydown', handleUserActivity);

        inactiveTimeout = setTimeout(() => {
            setUserStatusValue({ id: 9, name: 'inactive' });
        }, 100 * 60 * 1000);
        return () => {
            document.removeEventListener('mousemove', handleUserActivity);
            document.removeEventListener('keydown', handleUserActivity);
            clearTimeout(inactiveTimeout);
        };
    }, []);
    useEffect(()=>{
        adminUserStatusList(1).then((res)=>{
            setBreakContents(res.data.data);
            console.log(res.data.data);
        }).catch((err)=>{

        })
    },[])
    useEffect(() => {
        if (userStatusValue) {
            let date = formatUTCDate(new Date());
            updateUserStatus(userStatusValue.id, "", date).then((res) => {
                localStorage.removeItem('curator');
                localStorage.removeItem('moderator');
                redirect(`/u/${authid}/auth`, {
                    state: {
                        for: res.data.data
                        // errorMessage: 'You are inactive in 30 mins.',
                    },
                });
            }).catch(() => {

            })
        }
    }, [userStatusValue])
    // function handleUserActivity(){
    //     clearTimeout(inactiveTimeout);
    //     inactiveTimeout = setTimeout(() => {
    //         setUserStatusValue({id:9, name:"in active"})

    //     }, 1*60*1000);
    //       return () => {
    //         document.removeEventListener('mousemove', handleUserActivity);
    //         document.removeEventListener('keydown', handleUserActivity);
    //         clearTimeout(inactiveTimeout);
    //       };
    // }
    function handleUserActivity() {
        clearTimeout(inactiveTimeout);
        inactiveTimeout = setTimeout(() => {
            setUserStatusValue({ id: 9, name: 'inactive' });
            // Redirect to the login page or perform your desired action here
            // For example, you can use window.location.href to navigate to the login page
            //   window.location.href = '/login';
        }, 100 * 60 * 1000);
    }

    // useEffect(() => {
    //     let token = getStorageToken();
    //     if (token) {
    //         var base64Url = token.split('.')[1];
    //         var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    //         var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    //             return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    //         }).join(''));
    //         let data = JSON.parse(jsonPayload);
    //         var exp = data.exp * 1000;
    //         let expdate = (new Date(exp));
    //         let expiry = expdate.toUTCString();
    //         let date = new Date().toUTCString();
    //         var diff = (new Date(expiry) - new Date(date));
    //         diff = diff - 10000;
    //         // diff = 5000;
    //         setTimeout(() => {
    //             RefreshTokenApi().then((result) => {
    //                 let newToken = result.data.data.access_token;
    //                 setStorageToken(newToken);
    //                 setRefreshToken(newToken);
    //             }).catch((err) => {
    //                 // removeStorageToken();
    //                 // redirect('/login')
    //             });
    //         }, diff);
    //     }
    // }, [refreshToken])
    useEffect(() => {
        if (window.location.pathname === "/curator") {
            setTitle({ header_title: "Curation Manual", profile_title: "Curator" });
        }
        if (window.location.pathname === "/moderator") {
            setTitle({ header_title: "Moderation Manual", profile_title: "Moderator" });
        }
        if (window.location.pathname === "/Users") {
            setTitle({ header_title: "User List", profile_title: "User" });
        }
        if (window.location.pathname === "/leader") {
            setTitle({ header_title: "Leader Board", profile_title: "Leader" });
        }
        if (window.location.pathname === "/officer") {
            setTitle({ header_title: "Hub Officer", profile_title: "Hub Officer" });
        }
        if (window.location.pathname === "/book") {
            setTitle({ header_title: "Books", profile_title: "Book" });
        }
        if (window.location.pathname === "/qa") {
            setTitle({ header_title: "Quality Control", profile_title: "QA" });
        }
    }, [window.location.pathname])

    document.addEventListener('DOMContentLoaded', function () {
        // Function to update the bubble counter
        function updateCounter(count) {
            const bubbleCounter = document.getElementById('bubbleCounter');
            if (bubbleCounter) {
                if (count <= 99) {
                    bubbleCounter.textContent = count;
                } else {
                    bubbleCounter.textContent = '99+';
                }
            }
        }

        // Example usage:
        updateCounter(5); // Update counter to 5
    });

    return (
        <>
            <header className="header-top">
                <nav className="navbar navbar-light">
                    <div className="navbar-left">
                        <a className="navbar-brand"><img className="dark" src="/assets/img/logo.png" alt="logo" onClick={() => window.location.reload()} /><img className="light" src="/assets/img/logo.png" alt="Logo" /></a>
                        <h2 className='text-white'>{title.header_title}</h2>
                    </div>
                    <div className="navbar-right mobnav-right">
                        {/* <div className="feature-cards__top mob-enble">
                            <div className="dropdown dropdown-click">
                                <button className="border-0 bg-transparent p-0 mt-20 mr-20" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span data-feather="more-horizontal"></span>
                                </button>
                                <div className="dropdown-default dropdown-bottomRight dropdown-menu">
                                    <ul className='mob-links'>
                                        <li><Link to='/curator'> Curator</Link></li>
                                        <li><Link to='/moderator'> Moderator</Link></li>
                                        <li><Link to='/users'> User</Link></li>
                                        <li><Link to='/leader'> Leader</Link></li>
                                        <li><Link to='/officer'> Hub Officers</Link></li>
                                        <li><Link to='/book'> Books</Link></li>
                                        <li><Link to='/qa'> QA</Link></li>
                                    </ul>
                                </div>
                            </div>

                        </div> */}
                        {window.location.pathname !== `/u/${authid}/user-details` &&
                            <ul className="navbar-right__menu">
                                <ul className='head-nav mob-nav-none'>
                                    <li><NavLink className={location.pathname === `/u/${authid}/curator` ? 'nav-active' : ''}   to={`/u/${authid}/curator`}>Curator</NavLink></li>
                                    <li><NavLink className={location.pathname === `/u/${authid}/moderator` ? 'nav-active' : ''} to={`/u/${authid}/moderator`}>Moderator</NavLink></li>
                                    <li><NavLink className={location.pathname === `/u/${authid}/Users` ? 'nav-active' : ''}     to={`/u/${authid}/Users`}>User</NavLink></li>
                                    <li><NavLink className={location.pathname === `/u/${authid}/leader` ? 'nav-active' : ''}    to={`/u/${authid}/leader`}>Leaders</NavLink></li>
                                    <li><NavLink className={location.pathname === `/u/${authid}/officer` ? 'nav-active' : ''}   to={`/u/${authid}/officer`}> Hub Officers</NavLink></li>
                                    <li><NavLink className={location.pathname === `/u/${authid}/book` ? 'nav-active' : ''}      to={`/u/${authid}/book`}>Books</NavLink></li>
                                    <li><NavLink className={location.pathname === `/u/${authid}/qa` ? 'nav-active' : ''}        to={`/u/${authid}/qa`}>QA</NavLink></li>
                                    <li><span className='dived-line'>|</span></li>
                                </ul>

                                <li className="nav-message">

                                    <div className="dropdown-custom"> <a className="nav-item-toggle">
                                        <ReactSVG
                                            src="/assets/img/svg/clock.svg"
                                            alt=""
                                            style={{ width: 18, fill: 'white' }}
                                        />
                                        {/* <img src="/assets/img/svg/clock.svg" alt="" style={{ width: 18, fill: 'white' }} /> */}
                                        {/* <span style={{ width: 18, color: "#fff" }} data-feather="clock" className="nav-icon"></span> */}
                                    </a>
                                        <div className="dropdown-wrapper">
                                            <ul>
                                                {breakContents.data?.map((breakContent, key) => {
                                                    console.log()
                                                    return (
                                                        <li className="author-online has-new-message" key={key}>
                                                            <div className="atbd-switch-wrap">
                                                                <div className="custom-control custom-switch switch-primary switch-md ">
                                                                    <input type="checkbox" className="custom-control-input " id={breakContent.name} value={breakContent.id} onChange={() => setUserStatusValue({ id: breakContent.id, name: breakContent.name })}/>
                                                                    <label className={`custom-control-label text-capitalize ${breakContent.is_bta ? breakContents.is_break == true ? "text-success" : "text-danger" : "text-success"}`} htmlFor={breakContent.name}>{breakContent.name}</label>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })}

                                                {/* <li className="author-online has-new-message">
                                                <div className="atbd-switch-wrap">
                                                    <div className="custom-control custom-switch switch-primary switch-md ">
                                                        <input type="checkbox" className="custom-control-input" id="Tea" />
                                                        <label className="custom-control-label" htmlFor="Tea">Tea Break</label>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="author-online has-new-message">
                                                <div className="atbd-switch-wrap">
                                                    <div className="custom-control custom-switch switch-primary switch-md ">
                                                        <input type="checkbox" className="custom-control-input" id="Bio" />
                                                        <label className="custom-control-label" htmlFor="Bio">Bio Break</label>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="author-online has-new-message">
                                                <div className="atbd-switch-wrap">
                                                    <div className="custom-control custom-switch switch-primary switch-md ">
                                                        <input type="checkbox" className="custom-control-input" id="meeting" />
                                                        <label className="custom-control-label" htmlFor="meeting">Meeting</label>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="author-online has-new-message">
                                                <div className="atbd-switch-wrap">
                                                    <div className="custom-control custom-switch switch-primary switch-md ">
                                                        <input type="checkbox" className="custom-control-input" id="qa" />
                                                        <label className="custom-control-label" htmlFor="qa">QA</label>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="author-online has-new-message">
                                                <div className="atbd-switch-wrap">
                                                    <div className="custom-control custom-switch switch-primary switch-md ">
                                                        <input type="checkbox" className="custom-control-input" id="team" />
                                                        <label className="custom-control-label" htmlFor="team">Team Brefing</label>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="author-online has-new-message">
                                                <div className="atbd-switch-wrap">
                                                    <div className="custom-control custom-switch switch-primary switch-md ">
                                                        <input type="checkbox" className="custom-control-input" id="technical" />
                                                        <label className="custom-control-label" htmlFor="technical">Technical</label>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="author-online has-new-message">
                                                <div className="atbd-switch-wrap">
                                                    <div className="custom-control custom-switch switch-primary switch-md ">
                                                        <input type="checkbox" className="custom-control-input" id="unwell" />
                                                        <label className="custom-control-label" htmlFor="unwell">Un-Well</label>
                                                    </div>
                                                </div>
                                            </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-message">
                                    <div className="dropdown-custom ">
                                        <a className="nav-item-toggle ">
                                            <i className="fa fa-comments" aria-hidden="true"></i></a>
                                        <div className="dropdown-wrapper">
                                            <h2 className="dropdown-wrapper__title">Messages <span className="badge-circle badge-success ml-1">2</span></h2>
                                            <ul>
                                                <li className="author-online has-new-message">
                                                    <div className="user-avater">
                                                        <img src="/assets/img/team-1.png" alt="" />
                                                    </div>
                                                    <div className="user-message">
                                                        <p>
                                                            <a className="subject stretched-link text-truncate" >Web Design</a>
                                                            <span className="time-posted">3 hrs ago</span>
                                                        </p>
                                                        <p>
                                                            <span className="desc text-truncate" >Lorem ipsum
                                                                dolor amet cosec Lorem ipsum</span>
                                                            <span className="msg-count badge-circle badge-success badge-sm">1</span>
                                                        </p>
                                                    </div>
                                                </li>
                                                <li className="author-offline has-new-message">
                                                    <div className="user-avater">
                                                        <img src="/assets/img/team-1.png" alt="" />
                                                    </div>
                                                    <div className="user-message">
                                                        <p>
                                                            <a className="subject stretched-link text-truncate">Web Design</a>
                                                            <span className="time-posted">3 hrs ago</span>
                                                        </p>
                                                        <p>
                                                            <span className="desc text-truncate">Lorem ipsum
                                                                dolor amet cosec Lorem ipsum</span>
                                                            <span className="msg-count badge-circle badge-success badge-sm">1</span>
                                                        </p>
                                                    </div>
                                                </li>
                                                <li className="author-online has-new-message">
                                                    <div className="user-avater">
                                                        <img src="img/team-1.png" alt="" />
                                                    </div>
                                                    <div className="user-message">
                                                        <p>
                                                            <a className="subject stretched-link text-truncate">Web Design</a>
                                                            <span className="time-posted">3 hrs ago</span>
                                                        </p>
                                                        <p>
                                                            <span className="desc text-truncate">Lorem ipsum
                                                                dolor amet cosec Lorem ipsum</span>
                                                            <span className="msg-count badge-circle badge-success badge-sm">1</span>
                                                        </p>
                                                    </div>
                                                </li>
                                                <li className="author-offline">
                                                    <div className="user-avater">
                                                        <img src="/assets/img/team-1.png" alt="" />
                                                    </div>
                                                    <div className="user-message">
                                                        <p>
                                                            <a className="subject stretched-link text-truncate">Web Design</a>
                                                            <span className="time-posted">3 hrs ago</span>
                                                        </p>
                                                        <p>
                                                            <span className="desc text-truncate">Lorem ipsum
                                                                dolor amet cosec Lorem ipsum</span>
                                                        </p>
                                                    </div>
                                                </li>
                                                <li className="author-offline">
                                                    <div className="user-avater">
                                                        <img src="/assets/img/team-1.png" alt="" />
                                                    </div>
                                                    <div className="user-message">
                                                        <p>
                                                            <a className="subject stretched-link text-truncate">Web Design</a>
                                                            <span className="time-posted">3 hrs ago</span>
                                                        </p>
                                                        <p>
                                                            <span className="desc text-truncate">Lorem ipsum
                                                                dolor amet cosec Lorem ipsum</span>
                                                        </p>
                                                    </div>
                                                </li>
                                            </ul>
                                            <a className="dropdown-wrapper__more">See All Message</a>
                                        </div>
                                    </div>
                                </li>

                                <li className="nav-notification">
                                    <div className="dropdown-custom">
                                        <a className="nav-item-toggle">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell">
                                                <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                                                <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                                            </svg>
                                            <span id="bubbleCounter" class="bubble">4</span>
                                        </a>

                                        <div className="dropdown-wrapper">
                                            <h2 className="dropdown-wrapper__title">Notifications <span className="badge-circle badge-warning ml-1">4</span></h2>
                                            <ul>
                                                <li className="nav-notification__single nav-notification__single--unread d-flex flex-wrap">
                                                    <div className="nav-notification__type nav-notification__type--primary">
                                                        <span data-feather="inbox"></span>
                                                    </div>
                                                    <div className="nav-notification__details">
                                                        <p>
                                                            <a className="subject stretched-link text-truncate">James</a>
                                                            <span>sent you a message</span>
                                                        </p>
                                                        <p>
                                                            <span className="time-posted">5 hours ago</span>
                                                        </p>
                                                    </div>
                                                </li>
                                                <li className="nav-notification__single nav-notification__single--unread d-flex flex-wrap">
                                                    <div className="nav-notification__type nav-notification__type--secondary">
                                                        <span data-feather="upload"></span>
                                                    </div>
                                                    <div className="nav-notification__details">
                                                        <p>
                                                            <a className="subject stretched-link text-truncate">James</a>
                                                            <span>sent you a message</span>
                                                        </p>
                                                        <p>
                                                            <span className="time-posted">5 hours ago</span>
                                                        </p>
                                                    </div>
                                                </li>
                                                <li className="nav-notification__single nav-notification__single--unread d-flex flex-wrap">
                                                    <div className="nav-notification__type nav-notification__type--success">
                                                        <span data-feather="log-in"></span>
                                                    </div>
                                                    <div className="nav-notification__details">
                                                        <p>
                                                            <a className="subject stretched-link text-truncate">James</a>
                                                            <span>sent you a message</span>
                                                        </p>
                                                        <p>
                                                            <span className="time-posted">5 hours ago</span>
                                                        </p>
                                                    </div>
                                                </li>
                                                <li className="nav-notification__single nav-notification__single d-flex flex-wrap">
                                                    <div className="nav-notification__type nav-notification__type--info">
                                                        <span data-feather="at-sign"></span>
                                                    </div>
                                                    <div className="nav-notification__details">
                                                        <p>
                                                            <a className="subject stretched-link text-truncate">James</a>
                                                            <span>sent you a message</span>
                                                        </p>
                                                        <p>
                                                            <span className="time-posted">5 hours ago</span>
                                                        </p>
                                                    </div>
                                                </li>
                                                <li className="nav-notification__single nav-notification__single d-flex flex-wrap">
                                                    <div className="nav-notification__type nav-notification__type--danger">
                                                        <span data-feather="heart"></span>
                                                    </div>
                                                    <div className="nav-notification__details">
                                                        <p>
                                                            <a className="subject stretched-link text-truncate">James</a>
                                                            <span>sent you a message</span>
                                                        </p>
                                                        <p>
                                                            <span className="time-posted">5 hours ago</span>
                                                        </p>
                                                    </div>
                                                </li>
                                            </ul>
                                            <a className="dropdown-wrapper__more">See all incoming activity</a>
                                        </div>
                                    </div>
                                </li>

                                <li className="nav-flag-select">
                                    <div className="dropdown-custom hand-gesture">
                                        <a className="nav-item-toggle"><img src="/assets/img/flag.png" alt="" className="rounded-circle" /></a>
                                        <div className="dropdown-wrapper dropdown-wrapper--small">
                                            <a ><img src="img/eng.png" alt="" /> English</a>
                                            <a ><img src="img/ger.png" alt="" /> German</a>
                                            <a ><img src="img/spa.png" alt="" /> Spanish</a>
                                            <a ><img src="img/tur.png" alt="" /> Turkish</a>
                                        </div>
                                    </div>
                                </li>

                                <li className="nav-author">
                                    <div className="dropdown-custom">
                                        <a className="nav-item-toggle"><img src="/assets/img/author-nav.jpg" alt="" className="rounded-circle" /></a>
                                        <div className="dropdown-wrapper">
                                            <div className="nav-author__info">
                                                <div className="author-img">
                                                    <img src="/assets/img/author-nav.jpg" alt="" className="rounded-circle" />
                                                </div>
                                                <div>
                                                    {profile && <h6>{profile.name}</h6>}
                                                    <span>{title.profile_title}</span>
                                                </div>
                                            </div>
                                            <div className="nav-author__options">
                                                <ul>
                                                    <li>
                                                        <a href='#'><span data-feather="user"></span> Profile</a>
                                                    </li>

                                                    <li>
                                                    <a href='#'><span data-feather="bell"></span> Help</a>
                                                    </li>
                                                </ul>
                                                {/* <a className="nav-author__signout">
                                                <span data-feather="log-out" onClick={()=>setUserStatusValue({ id: 9, name: 'logout' })}></span> Sign Out</a> */}
                                                <a href="#" className="nav-author__signout" onClick={() => setUserStatusValue({ id: 9, name: 'logout' })}>
                                                    <span data-feather="log-out"></span> Sign Out
                                                </a>

                                            </div>
                                        </div>

                                    </div>
                                </li>

                            </ul>
                        }
                    </div>

                </nav>
            </header>
        </>
    );
}
export default Header;