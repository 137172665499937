import axios from 'axios';
import { Configuration, HubOfficerUrl } from './Url';

const getJobAppliedUserUrl              = HubOfficerUrl()+"get/job/applied/users";
const approveRolesUserUrl               = HubOfficerUrl()+"approve/roles";
const getAdminRolesStatusListUrl        = HubOfficerUrl()+"get/admin/role/status/list";
let config                              = Configuration();

export function getJobAppliedUser(page = 1, filter=""){
    let config = Configuration();
    let url = getJobAppliedUserUrl;
    let params = {
        page: page,
        limit:10,
    }
    if(filter !== "")
    {
        params.officer_name = filter.officer_name;
        params.role = filter.role;
        params.registration_date = filter.registration_date;
        params.language = filter.language;
    }
    config.params = params;
    return axios.get(url,config)
}
export function approveRoles(user_id,roles_details){
    let config = Configuration();
    let data = roles_details;
    data.user_id = user_id;
    let url = approveRolesUserUrl;
    return axios.post(url,data,config)
}
export function getAdminRolesStatusList(){
    let config = Configuration();
    let url = getAdminRolesStatusListUrl;
    return axios.get(url,config)
}