import axios from 'axios';
import { Configuration, DesignerUrl } from './Url';

const uploadAttachmentUrl           = DesignerUrl()+"upload/attachment";
const getAttachmentUrl              = DesignerUrl()+"get/attachments";
const deleteAttachmentUrl           = DesignerUrl()+"delete/attachment";
let config                          = Configuration();

export function uploadAttachment(attachment, text, type){
    let config = Configuration();
    let url = uploadAttachmentUrl;
    const data = {
        attachment: attachment, 
        text: text,
        type: type
    }
    return axios.post(url,data,config)
}
export function getAttachments(){
    let config = Configuration();
    let url = getAttachmentUrl;
    return axios.get(url,config)
}
export function deleteAttachment(id){
    let config = Configuration();
    let url = deleteAttachmentUrl;
    let params = {
        id: id
    }
    config.params = params;
    return axios.get(url,config)
}
