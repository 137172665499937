import axios from 'axios';
import { Configuration, UserUrl } from './Url';

const addUserDetailsUrl               = UserUrl()+"add/user/details";
let config                            = Configuration();

export function AddUserDetails(data){
    let config = Configuration();
    let url = addUserDetailsUrl;
    return axios.post(url,data,config)
}