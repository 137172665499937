import React, { useEffect, useState } from 'react'

const MediLoader = () => {
    const [image, setImage] = useState(1);
    useEffect(()=>{
        setTimeout(() => {
            setImage(2)
        }, 2250);
    },[])
    return (
        <>
            <div className='pre-loader'>
                <img src={`${image == 1 ? "/assets/video/loading01.gif" : "/assets/video/loading02.gif"}`} />
            </div>
            {/* <video autoPlay 
  src="../assets/video/Loading-animation-2.mp4" type="video/mp4"
/>  */}
        </>
    );
}
export default MediLoader;