import React, { useRef } from 'react'
import { CoreImageUrl } from '../Api/Url';
import { ProfileApi } from '../Api/ApiCore';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { removeStorageToken } from '../Api/Storage';
import { adminCheckUserStatus, checkAdminLogoutTime, updateUserStatus } from '../Api/GeneralApi';
import { EachWordCapitalize, addLeadingZero, convertUtcToLocalTime, formatTime, formatUTCDate, minutesToHHMM, secondsToHHMMSS } from '../Api/CommanJsFunction';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import useCheckUserStatus from '../CustomHooks/useCheckUserStatus';
import { duration } from 'moment/moment';
import { convertBlobToBase64 } from '../views/features/convertToBase64';

const LoginWelcome = ({ setIsShowHeader, address, setPathname, location }) => {
    const { authid } = useParams();
    const handleClick = () => {
        const mailtoLink = `mailto:alpha.evzone.gmail.com?subject=${encodeURIComponent("from faith hub")}&body=${encodeURIComponent("")}`;
        window.location.href = mailtoLink;
      };

    const getParameters = useLocation();
    // useEffect(()=>{        
    //     // const searchParams = new URLSearchParams(getParameters.search);
    //     // console.log(searchParams);
    //     // const xAuthUser = searchParams.get('X_AuthUser');
    //     // if (xAuthUser) {
    //     // localStorage.setItem('X_AuthUser', xAuthUser);
    //     // }
    //     // else{
    //     //     localStorage.setItem('X_AuthUser', 0);
    //     // }
    // },[getParameters.search])

    useEffect(()=>{
        setPathname(window.location.pathname);
    },[])

    const redirect = useNavigate();
    let initialErrorMessage = "";
    
    const passcodeInputBox = 6;
    const [profile, setProfile] = useState();
    const [isErrorCode, setIsErrorCode] = useState();
    const inputRefs = useRef(Array(passcodeInputBox).fill(null).map(() => React.createRef()));
    const [passcode, setPasscode] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);
    const [userBreakDetails, setUserBreakDetails] = useState();
    const [currentTime, setCurrentTime] = useState(new Date());
    const [timeDuration, setTimeDuration] = useState();
    const [remainingBreakTime, setRemainingBreakTime] = useState();
    const [isBreak, setIsBreak] = useState(false);
    const [showNonBtaModal, setShowNonBtaModal] = useState(false);
    const [isNonBtaOverTime, setIsNonBtaOverTime] = useState(false);
    const [breakTimeDetails, setbreakTimeDetails] = useState();
    const [isShowPasscodeModal, setIsShowPasscodeModal] = useState(false);
    const [passcodeError, setPasscodeError] = useState(null);
    const [breakContent, setBreakContent] = useState(null);
    const [breakTitle, setBreakTitle] = useState(null);
    const [meetTime, setMeetTime] = useState(120);
    const meet_time_counter = 60;
    const [meetTimeCounter, setMeetTimeCounter] = useState(meet_time_counter);
    // let meet_time = 1;

    let popup_show_time = meetTimeCounter;

    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [image, setImage] = useState(null);
    const [cameraEnabled, setCameraEnabled] = useState(false);
    useEffect(() => {
        setIsShowHeader(false);
        checkAdminLogoutTime().then((res) => {
            // console.log(res);
        }).catch((err) => {
            // console.log(err);
        })
    }, [])
    useCheckUserStatus(setIsBreak, setUserBreakDetails, setBreakContent, setBreakTitle);

    useEffect(() => {
        // adminCheckUserStatus().then((res)=>{
        //     redirect('/curator');
        // }).catch((err)=>{
        //     if(err.response.status == 402)
        //     {
        //         redirect('/user-details')
        //     }
        //     if(err.response.status == 403)
        //     {
        //         console.log(err.response.data.message)
        //         setAlertMessage({error:err.response.data.message})
        //     }
        //     setUserBreakDetails(err.response.data.data);
        //     if(err.response.data.data.is_show == 1)
        //     {
        //         setIsBreak(true);
        //     }
        // });
    }, [])
    let nonBtaInterval = null;
    let popupInterval = null;
    useEffect(() => {
        if ((userBreakDetails && userBreakDetails.is_bta == 0 && userBreakDetails.is_show == 1) && !showNonBtaModal) {
            nonBtaInterval = setInterval(() => {

                setShowNonBtaModal(true);
                clearInterval(nonBtaInterval);
            }, meetTime * 1000);
            return () => {
                clearInterval(nonBtaInterval);
            };
        }
    }, [userBreakDetails, showNonBtaModal])
    useEffect(() => {
        if (showNonBtaModal) {
            popupInterval = setInterval(() => {
                handleUpdateUserStatus(9, "", "check1")
                clearInterval(popupInterval);
            }, popup_show_time * 1000);
            return () => {
                clearInterval(popupInterval);
            };
        }
    }, [showNonBtaModal])
    useEffect(() => {
        if(showNonBtaModal){
            const intervalID = setInterval(() => {
            setMeetTimeCounter((prevCount) => prevCount > 0 ? prevCount - 1 : 0);
            }, 1000);
        
            return () => clearInterval(intervalID); 
        }
      }, [showNonBtaModal]);
    function handleNonBtaOverTime() {
        clearInterval(popupInterval);
        setShowNonBtaModal(false);
        setMeetTimeCounter(meet_time_counter)
    }
    useEffect(() => {
        if (userBreakDetails && (userBreakDetails.is_show == 1 && userBreakDetails.is_logout == 0)) {
            const intervalId = setInterval(() => {
                let duration_mins = Math.floor((new Date() - new Date(convertUtcToLocalTime(userBreakDetails.time_out))) / (1000 * 60));
                let total_duration_seconds = Math.floor((new Date() - new Date(convertUtcToLocalTime(userBreakDetails.time_out))) / (1000));
                let total_break_seconds = 0;
                if (userBreakDetails.is_bta == 1) {
                    // const [breakHours, breakMinutes] = userBreakDetails.break_time.split(":");
                    // total_break_seconds = Math.floor(((breakHours * 3600) + (breakMinutes * 60)) - (userBreakDetails.spend_time * 60));
                    total_break_seconds = userBreakDetails.break_time-userBreakDetails.spend_time;
                }
                else {
                    total_break_seconds = (meetTime);
                }
                // console.log(total_break_seconds);
                // console.log(total_duration_seconds);
                let remaining_seconds = Math.floor(total_break_seconds - total_duration_seconds);
                if(remaining_seconds <=0){
                    remaining_seconds = 0;
                }
                // if (remaining_seconds <= 0 && userBreakDetails.is_bta == 1) {
                //     handleUpdateUserStatus(9, "", "check2")
                //     setIsBreak(false);
                //     setbreakTimeDetails(null);
                //     clearInterval(intervalId);
                // }
                let currentDate = new Date();
                let hours = currentDate.getHours();
                let minutes = currentDate.getMinutes();
                let seconds = currentDate.getSeconds();
                let totalSeconds = (hours * 3600) + (minutes * 60) + seconds;

                setbreakTimeDetails({
                    break_type: userBreakDetails.name,
                    time_out: userBreakDetails.time_out || new Date(),
                    time_back: new Date(),
                    duration:duration_mins,
                    duration_seconds: total_duration_seconds,
                    break_time_allowed: userBreakDetails.is_bta == 1 ? userBreakDetails.break_time : meetTime,
                    remaining_break_time: remaining_seconds
                })
            }, 1000);
            return () => {
                clearInterval(intervalId);
            };
        }
    }, [userBreakDetails]);
    useEffect(() => {
        ProfileApi().then((res) => {
            setProfile(res.data.data);
            // removeStorageToken();
        }).catch((err) => {
            if (err.response.status == 401) {
                redirect('/');
            }
        })
    }, [])
    // const imageToBase64 = (file) => {
    //     return new Promise((resolve, reject) => {
    //       const reader = new FileReader();
    //       reader.onloadend = () => {
    //         const base64 = reader.result.replace("data:", "").replace(/^.+,/, "");
    //         resolve(base64);
    //       };
    //       reader.onerror = (error) => reject(error);
    //       reader.readAsDataURL(file);
    //     });
    //   };
    // const imageToBase64 = (file) => {
    //     return new Promise((resolve, reject) => {
    //       const reader = new FileReader();
    //       reader.onloadend = () => {
    //         const base64 = reader.result.replace("data:", "").replace(/^.+,/, "");
    //         resolve(base64);
    //       };
    //       reader.onerror = (error) => reject(error);
    //       reader.readAsDataURL(file);
    //     });
    //   };
    //   console.log(image);

    async function handleUpdateUserStatus(data = "", passcode = "", check) {
        const base64Image = await captureImage();
        let date = formatUTCDate(new Date());
        updateUserStatus(data, passcode, date, address, location, base64Image).then((res) => {
            if (data !== 9) {
                redirect(`/u/${authid}/curator`);
            }
            else {
                setUserBreakDetails((prevUserBreakDetails) => ({
                    ...prevUserBreakDetails, // Copy the existing state
                    is_show: 0, // Set new value for is_show
                    status: 9, // Set new value for status
                }));
                setShowNonBtaModal(false);
                setbreakTimeDetails(null);
            }
            setAlertMessage(null)
        }).catch((err) => {
            setAlertMessage({ error: err.response.data.message })
            inputRefs.current[0].current.focus();
            if(err.response.data.data.wrong_passcode >= 5){
                setIsShowPasscodeModal(false);
                setBreakContent({title: "You're account has been locked", content: "To have this resolved contact your superviser using your work email", role_status: 7})
            }
            setPasscode(null)
        })
    }
    function handlePassCode() {
        if (passcode) {
            handleUpdateUserStatus("", passcode, 'check3')
        }
        else {
            setAlertMessage({ error: "enter correct passcode" });
        }
        show_passcode_modal();
    }
    // useEffect(() => {
    //     if (alertMessage && alertMessage.error) {
    //         const timer = setTimeout(() => {
    //             setAlertMessage(null);
    //         }, 5000);
    //         return () => clearTimeout(timer);
    //     }
    // }, [alertMessage]);
    const backdropStyle = {
        backdropFilter: 'blur(3px)', // Adjust the blur radius as needed
        backgroundColor: 'rgba(87 82 82 / 50%)', // Adjust the opacity as needed
    };
    function show_passcode_modal() {
        if(cameraEnabled == false){
            navigator.mediaDevices.getUserMedia({ video: true })
            .then(stream => {
            videoRef.current.srcObject = stream;
            videoRef.current.play();
            setCameraEnabled(true);
            }).catch(err => {
                console.error("Error accessing camera: ", err);
            });
        }
        setPasscode(null)
        setIsShowPasscodeModal(!isShowPasscodeModal);
        setAlertMessage(null);
    }
    useEffect(() => {
        if (inputRefs.current[0] && inputRefs.current[0].current) {
            inputRefs.current[0].current.focus();
        }
    }, [isShowPasscodeModal])
    const handlePasscodeChange = (index, value) => {
        // const numericValue = value.replace(/\D/g, '');
        // const newPasscode = [...(passcode || Array(passcodeInputBox).fill(''))];
        // newPasscode[index] = numericValue;
        // setPasscode(newPasscode.join(''));

        // if (index < passcodeInputBox - 1 && numericValue !== '') {
        //     inputRefs.current[index + 1].current.focus();
        // }
    };
    function handlePasscodeClick(index){
        if(passcode){
            index = passcode.indexOf("");
            // inputRefs.current[index].current.focus();
            return index;
        }
        return 0;
    }
    const handlePasscodeKeyDown = (index, e) => {
        const numericValue = e.key.replace(/\D/g, '');
        if (e.key === 'Backspace' && index >= 0) {
            const newPasscode = [...(passcode || Array(passcodeInputBox).fill(''))];
            newPasscode[index] = numericValue;
            setPasscode(newPasscode);
            if(index !== 0){
                inputRefs.current[index - 1].current.focus();
            }
        }
        if(isNaN(e.key)){
            return false;
        }
        const newPasscode = [...(passcode || Array(passcodeInputBox).fill(''))];
        newPasscode[index] = numericValue;
        setPasscode(newPasscode);
        if (index < passcodeInputBox - 1 && numericValue !== '') {
            inputRefs.current[index + 1].current.focus();
        }
        else{
            // inputRefs.current[0].current.focus();
            // inputRefs.current[index].current.style.pointerEvents = 'none';
            const inputElement = inputRefs.current[index].current;
    inputElement.style.pointerEvents = 'none';
    inputElement.blur();  // Remove focu
        }
        setAlertMessage(null)
    }
    useEffect(()=>{
        if(passcode?.length == 6){
            const checkPasscode = passcode.join('');
            if(checkPasscode.length !== 6){
                setPasscodeError('please check your passcode');
            }
            else{
                setPasscodeError(null);
                handleUpdateUserStatus("", checkPasscode, 'check3')
                if(alertMessage){
                    setAlertMessage('please check your passcode');
        setPasscode(null)

                }
                else{
        // setPasscode(null)
                    
                }
            }
        }
    },[passcode])
    const blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result.split(',')[1]);
          };
          reader.onerror = (error) => reject(error);
          reader.readAsDataURL(blob);
        });
      };
    const captureImage = () => {
        const canvas = canvasRef.current;
        const video = videoRef.current;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        return new Promise((resolve, reject) => {
            canvas.toBlob(async (blob) => {
                if (blob) {
                    try {
                        const base64 = await blobToBase64(blob);
                        resolve(base64);
                    } catch (error) {
                        reject("Error converting Blob to Base64:", error);
                    }
                } else {
                    reject("No Blob generated");
                }
            }, 'image/png');
        });
    };
    const currentDomain = window.location.href;
    var url = new URL(currentDomain);

    var domainName = encodeURIComponent(url.protocol + "//" + url.hostname);
    console.log(url.protocol)
    console.log(url.hostname)
    const baseUrl = "https://myaccount.evzone.app";                                            
    const redirectUrl = `${baseUrl}?redirecturl=${encodeURIComponent(domainName)}`
    return (
        <>
            {/* {(alertMessage && alertMessage.error) &&
                <div className="alert alert-danger" role="alert">
                    {alertMessage.error}
                </div>} */}
            <main className="main-content">
                <div className="signUP-admin">
                    <div className="container-fluid">
                        <div className="row justify-content-center sign-height">
                            <div className="col-xl-7 col-lg-7 col-md-7 p-0 bg-orange">
                            <video ref={videoRef} style={{ display: 'none' }} />
                            <canvas ref={canvasRef} style={{ display: 'none' }} />
                            {image && <img src={URL.createObjectURL(image)} alt="Captured" />}
                                <div className="landlogo">
                                    <div className={`signUP-admin-left__img ${(breakTimeDetails && isBreak) && 'accout-timetable'}`}>
                                        <img className="img-fluid svg login-logo" src="/assets/img/faithhub-orange-01.png" alt="img" />
                                    </div>
                                </div>
                                {breakTimeDetails && isBreak &&
                                    <div className='orange-bg'>
                                        <div className='margin-left-auto'>
                                            <div className='table-responsive pb-50 pt-50'>
                                                <table className='table table-bordered table-bg-f4d8b7'>
                                                    <tr>
                                                        <th className='table-header'>Break Type</th>
                                                        {/* <td>{EachWordCapitalize(userBreakDetails.name)}</td> */}
                                                        <td>{EachWordCapitalize(breakTimeDetails.break_type)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className='table-header'>Time Out</th>
                                                        {/* <td>{formatTime(convertUtcToLocalTime(userBreakDetails.time_out))}</td> */}
                                                        <td>{formatTime(convertUtcToLocalTime(breakTimeDetails.time_out))}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className='table-header'>Time Back</th>
                                                        {/* <td>{formatTime(currentTime)}</td> */}
                                                        <td>{formatTime(breakTimeDetails.time_back)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className='table-header'>Duration</th>
                                                        {/* <td>{ addLeadingZero(timeDuration.hours)+":"+addLeadingZero(timeDuration.minutes)}</td> */}
                                                        
                                                        <td className={`${breakTimeDetails.remaining_break_time <= 0 && 'text-danger'}`}>{secondsToHHMMSS(breakTimeDetails.duration_seconds)}</td>
                                                    </tr>
                                                    {breakTimeDetails.remaining_break_time <= 0 && userBreakDetails.is_bta == 1 && 
                                                    <tr>
                                                    
                                                        <th className='table-header'>Break Time allowed</th>
                                                        {/* <td>{userBreakDetails.break_time}</td> */}
                                                        {breakTimeDetails.remaining_break_time <= 0 ? 
                                                        <td className='text-danger'>Maxed Out</td>
                                                        :
                                                        <td>{breakTimeDetails.break_time_allowed/60} {breakTimeDetails.break_time_allowed/60 == 1 ? "min" : "mins"}</td>}
                                                    </tr>
                                                    }

                                                    {userBreakDetails.is_bta == 1&&
                                                    <tr>
                                                        <th className='table-header'>Remaining Break Time</th>
                                                        {/* <td>{addLeadingZero(remainingBreakTime.remaining_hours)+":"+addLeadingZero(remainingBreakTime.remaining_minuets)}</td> */}
                                                        {userBreakDetails.is_bta == 1 &&
                                                            <td>{secondsToHHMMSS(breakTimeDetails.remaining_break_time)}</td>}
                                                    </tr>}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            {profile ?
                                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 signUp-admin-right">
                                    <div className="signUp-admin-right p-md-40 p-10">
                                        <div className="row justify-content-center">
                                            <div className="col-xl-12 col-lg-12 col-md-12 login-form-height">
                                                <div className="edit-profile mt-md-25 mt-0 text-center">
                                                    <div className="card border-0">
                                                        <div className="card-header border-0 pb-md-15 pb-10 pt-md-20 pt-10 ">
                                                            <div className="edit-profile__title w-100">
                                                                {breakContent && 
                                                                <h5 className='w-100 text-center'>{breakContent.title || null}</h5>}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {breakContent && 
                                                            <p>{breakContent.content || null}</p>}
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="edit-profile__body">
                                                                <div className="panel panel-default">

                                                                </div>
                                                                <div className="form-group mb-20 text-center">
                                                                    <img className="img-fluid img-rounded svg" src={CoreImageUrl() + profile.image} alt="img" />
                                                                </div>
                                                                
                                                                {breakContent && (breakContent.role_status == 6 || breakContent.role_status == 7 ) ? 
                                                                <div className="form-group mb-15" onClick={handleClick} style={{cursor: "pointer"}}>
                                                                    <h6 >alpha.evzone.gmail.com</h6>
                                                                </div>
                                                                : null}
                                                                <div className="panel-body p-0">
                                                                    <div className="btn-group mt-2 mb-2">
                                                                        <button type="button" className="btn btn-light btn-pill dropdown-toggle line-height" data-toggle="dropdown">
                                                                            <p className='drop-content'><strong>Continue as {profile && profile.name}</strong></p>
                                                                            <p className='drop-content'>{profile && profile.email}</p>
                                                                            <span className="caret"></span>
                                                                        </button>
                                                                        {profile && profile.email && (
                                                                            <ul className="dropdown-menu" role="menu">
                                                                                <li><a href="#">{profile.email}</a></li>
                                                                            </ul>
                                                                        )}
                                                                            {/* <li><a href="#">ponmariappan@heptotechnologies.org</a></li>
                                                                            <li><a href="#">ponmariappan@heptotechnologies.org</a></li> */}
                                                                            
                                                                        <div className="text-light fs-16 field-btn toggle-password2">
                                                                        {breakContent && (breakContent.role_status == 3 || breakContent.role_status == 7 )?
                                                                        <button className='btn btn-default btn-rounded btn-outline-danger continue'>forbidden</button>
                                                                        : 
                                                                        <button onClick={show_passcode_modal} className='btn btn-default btn-rounded btn-outline-dark continue'>Continue</button>
                                                                        }
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                                {/* <div className="form-group mb-15">
                                                                    <div className="position-relative">
                                                                        <select className="form-control border-radius">
                                                                            <option>Continue as {profile && profile.name} - {profile && profile.email}</option>
                                                                        </select>
                                                                        <div className="text-light fs-16 field-btn toggle-password2"><button onClick={show_passcode_modal} className='btn btn-default btn-rounded btn-outline-dark'>Continue</button></div>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                         {/* {(alertMessage && alertMessage.error) &&
                                                        
                                                            <span className="text-danger">{alertMessage.error}</span>
                                                        
                                                        } */}
                                                        <div className='card-footer'><h6><a href={redirectUrl} className='cursor-pointer'>Not you? Log in with a different account</a></h6></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>
                </div>
            </main>
            {/* <div className="modal fade" id="yourpassword" tabindex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="example-Modal3">Enter Your Passcode</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
                        </div>
                        <div className="modal-body">
                            <input type='text' className='form-control' placeholder='Enter Your Passcode'name="passcode" onChange={(e)=>setPasscode(e.target.value)} autoComplete='off'/>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success btn-sm" onClick={handlePassCode} data-dismiss="modal">Submit</button>

                        </div>
                    </div>
                </div>
            </div> */}
            <div>
                {isShowPasscodeModal &&
                    <Modal className='modal-center' show={isShowPasscodeModal} onHide={show_passcode_modal}>
                        {/* <Modal.Header className='custom-header' closeButton>
                            <Modal.Title ><h4 className='pass-title'>Account Verification</h4></Modal.Title>
                        </Modal.Header> */}
                        {location && cameraEnabled ? 
                        <Modal.Body className='content'>
                            
                            <div className="pass-contain">
                                <div className='lock-img'> <img src="../assets/img/passcode.png" alt="img" /></div>
                                <div className='otp-title'><h4 className='mb-1'>Passcode Required</h4>
                                <p className='mb-1'>To access your Work Account, Please enter your Access Passcode</p>
                                </div>
                                <div className='otp-input mb-1'>
                                {Array(passcodeInputBox).fill(null).map((_, index) => (
                                    <input
                                        type="text"
                                        className={`pass-code form-control ${alertMessage &&"border-danger"}`}
                                        name="passcode"
                                        value={(passcode && passcode[index]) || ''}
                                        onChange={(e) => handlePasscodeChange(index, e.target.value)}
                                        autoComplete="off"
                                        ref={inputRefs.current[index]}
                                        maxLength={1}
                                        onKeyDown={(e) => handlePasscodeKeyDown(index, e)}
                                        onClick={(()=>{handlePasscodeClick(index)})}
                                        key={index}
                                        // tabIndex={-1}
                                        style={handlePasscodeClick(index) !== index ? {pointerEvents: "none"} : null}
                                        autoFocus={handlePasscodeClick(index)}
                                    />
                                ))}
                                </div>
                                {/* {(alertMessage && alertMessage.error) &&
                                    <span className="text-danger tex">{alertMessage.error}</span>                
                                }  */}
                            </div>
                            <div className='forgot-acc'>
                                <a href='mailto:admin@gmail.com?subject=Forgot%20Access%20Code'>
                                    Forgot Access Code?
                                    <p>   
                                        Contact your supervisor
                                    </p>
                                </a> 
                            </div>
                            
                        </Modal.Body>
                        : 
                        <Modal.Body>
                                <div className="pass-contain">
                                    {!location && 
                                    <h1>Please enable the Loacation</h1> }
                                    {!cameraEnabled && 
                                    <h1>Please enable the Camera</h1> }
                                </div>
                                
                                </Modal.Body>
}
                        {/* <Modal.Footer>
                            <Button variant="success" onClick={handlePassCode}>
                                Submit
                            </Button>
                        </Modal.Footer> */}
                    </Modal>
                }
            </div>
            {showNonBtaModal &&
                <div className="modal" style={{ display: 'block' }}>
                    <div className="modal-backdrop" style={backdropStyle}>
                        <Modal.Dialog>
                            <Modal.Header >
                                <Modal.Title>Are you still in {breakTimeDetails.break_type}?</Modal.Title>
                                <Modal.Title> {meetTimeCounter}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {/* <p>This break will end in 5 minutes. do you want to extend the break?</p> */}
                                <p>The {breakTimeDetails.break_type} will end in {breakTimeDetails.break_time_allowed/60} {breakTimeDetails.break_time_allowed/60 == 1 ? "min" : "mins"}. do you want to continue?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                {/* <Button variant="secondary">Close</Button> */}
                                <Button variant="primary" onClick={handleNonBtaOverTime}>Yes</Button>
                            </Modal.Footer>
                        </Modal.Dialog>
                    </div>
                </div>
            }
        </>
    );
}
export default LoginWelcome;