import React,{useState} from 'react';
import { Image } from 'antd';
import zIndex from '@mui/material/styles/zIndex';
const ImageViewer = () => {
    const [isPreviewVisible, setPreviewVisible] = useState(false);
    const handleEyeClick = () => {
        // setSelectedImage(image);
        setPreviewVisible(true);
        // setShow1(false);
      };
    return (
        <>
        <Image
            src="/assets/img/login-bg.png"
            style={{zIndex:'99999 !important'}}
            preview={{
                visible: isPreviewVisible,
                onVisibleChange: setPreviewVisible, // Update the state directly
                onClose: () => {
                  setPreviewVisible(false); // Close the modal
                   // Show another modal if needed
                },
              }}
        />
        <button className='eye-btn' onClick={handleEyeClick}><i class="fa fa-eye view-eye" aria-hidden="true"></i></button>
        </>
    );
}

export default ImageViewer;