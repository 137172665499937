import React, { useState, useCallback } from 'react'
import ReactDOM from 'react-dom'
import Cropper from 'react-easy-crop'

const VideoEditor = ({attachment, text, setCroppedAreaPixels, croppedImage, type, textStyle}) => {
  console.log(textStyle);
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  // const [croppedImage, setCroppedImage] = useState(null);
  // const [CroppedAreaPixels, setCroppedAreaPixels] = useState(null)
  // const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
  //   console.log(croppedArea, croppedAreaPixels)
  // }, [])
  // const onCropComplete = async (croppedArea, croppedAreaPixels) => {
  //   try {
  //     const croppedImageBlob = await getCroppedImg(croppedAreaPixels);
  //     setCroppedImage(croppedImageBlob);
  //   } catch (e) {
  //     console.error('Error cropping image:', e);
  //   }
  // };
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }
  // const getCroppedImg = async (croppedAreaPixels) => {
  //   const canvas = document.createElement('canvas');
  //   const image = document.createElement('img');
  //   image.src = attachment; // Assuming `attachment` is your image source
  //   const scaleX = image.naturalWidth / image.width;
  //   const scaleY = image.naturalHeight / image.height;
  //   canvas.width = croppedAreaPixels.width;
  //   canvas.height = croppedAreaPixels.height;
  //   const ctx = canvas.getContext('2d');

  //   ctx.drawImage(
  //     image,
  //     croppedAreaPixels.x * scaleX,
  //     croppedAreaPixels.y * scaleY,
  //     croppedAreaPixels.width * scaleX,
  //     croppedAreaPixels.height * scaleY,
  //     0,
  //     0,
  //     croppedAreaPixels.width,
  //     croppedAreaPixels.height
  //   );
  //   // const showCroppedImage = async () => {
  //   //   try {
  //   //     const croppedImage = await getCroppedImg(
  //   //       dogImg,
  //   //       croppedAreaPixels,
  //   //       rotation
  //   //     )
  //   //     console.log('donee', { croppedImage })
  //   //     setCroppedImage(croppedImage)
  //   //   } catch (e) {
  //   //     console.error(e)
  //   //   }
  //   // }
  //   return new Promise((resolve, reject) => {
  //     canvas.toBlob((blob) => {
  //       if (!blob) {
  //         // Reject if blob is null
  //         reject(new Error('Canvas is empty'));
  //         return;
  //       }
  //       resolve(blob);
  //     }, 'image/jpeg'); // Change format if needed
  //   });
  // };
  return (
    <div className="App">
      <div className="crop-container">
        {type == "image" &&
        <Cropper
        image={attachment}
          // video="/assets/video/sample-video.mp4"
          crop={crop}
          zoom={zoom}
          aspect={4 / 3}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          // onZoomChange={setZoom}
          // mediaProps={{ controls: true }}
        />}
        {type == "video" &&
        <Cropper
          video="/assets/video/sample-video.mp4"
          crop={crop}
          zoom={zoom}
          aspect={4 / 4}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          mediaProps={{ controls: true }}
        />}
        <div className="position-text">
          {/* Adjust the styles of the text as needed */}
          <p style={textStyle}>{text}</p>
        </div>
      </div>
      <div className="controls">
        <input
          type="range"
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e) => {
            setZoom(e.target.value)
          }}
          className="zoom-range"
        />
      </div>
    </div>
  )
}
export default VideoEditor;
