import React, { useEffect, useState ,useRef} from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from '../../layouts/header';
import Footer from '../../layouts/footer';
import { getCompletedPostList, getOpenItemAnswers, getPerformanceDetails, getPostDetails, getPostTagDetails, getPostTagsDescription, getTimeManagmentDetails, updateMarks, updatePerformanceStatus, userLists } from '../../Api/ApiQA';
import { CheckImage, EachWordCapitalize, average, currentMonthDates, decimalNumber, findValue, formatTime, minutesToHHMM, utcTimetoLocalTime } from '../../Api/CommanJsFunction';
import { ImageUrl } from '../../Api/Url';
import { adminUserStatusList } from '../../Api/GeneralApi';
import MediLoader from '../../Components/medi-loading';


const QA = ({setIsShowHeader}) => {
    const tabLists = [{name:"curator", id:"tab-v-1", tab_name: "tab-v-1-tab", for: "curator"},{name:"moderator", id:"tab-v-2", tab_name: "tab-v-2-tab", for:"moderator"}];
    const [selectTabId, setselectTabId] = useState('tab-v-1');
    const [breakContents, setBreakContents] = useState([]);

    const [rolesuserLists, setRolesuserLists] = useState([]);
    const [completedPostLists, setCompletedPostLists] = useState([]);
    const [postDetails, setPostDetails] = useState();
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [postDescription, setPostDescription] = useState();
    const [curModPostId, setCurModPostId] = useState();
    const [curModePostTag, setcurModePostTag] = useState();
    const [curModePostSubTag, setCurModePostSubTag] = useState();
    const [isShowTag, setIsShowTag] = useState(true);
    const [tagTotalPage, setTagTotalPage] = useState(0);
    const [openItems, setOpenItems] = useState();
    const [userDailyActiviyTracker, setUserDailyActiviyTracker] = useState(null);
    const [userPerformance, setUserPerformance] = useState(null);
    const [tagShowCount, setTagShowCount] = useState({tags: 1, description: 1, openItems: 1});
    const [tagMarks, setTagMarks] = useState([]);
    const [subTagMarks, setSubTagMarks] = useState([]);
    const [descriptionMarks, setDescriptionMarks] = useState({});
    const [openItemMarks, setOpenItemMarks] = useState([]);
    const [lastCalibrationMark, setLastCalibrationMark] = useState(0);
    const [selectedUser, setSelectedUser] = useState();
    const [isEnableNextPrvButton, setIsEnableNextPrvButton] = useState(true);
    const defineDescriptionMarks = 10;
    const defineTagsMarks = selectTabId == "tab-v-1" ? 90 : 80;
    const defineOpenItemsMarks = 20;
    const disabledLinkStyle = {
        pointerEvents: 'none', // Disable mouse events
        opacity: 0.5, // Make it semi-transparent to visually indicate it's disabled
        cursor: 'not-allowed', // Change cursor to indicate it's not clickable
    }

    const [userListLoading, setUserListLoading] = useState(false);
    const [getCompletedPostListLoading, setGetCompletedPostListLoading] = useState(false);
    const [getOpenItemAnswersLoading, setGetOpenItemAnswersLoading] = useState(false);
    const [getPerformanceDetailsLoading, setGetPerformanceDetailsLoading] = useState(false);
    const [getPostDetailsLoading, setGetPostDetailsLoading] = useState(false);
    const [getPostTagDetailsLoading, setGetPostTagDetailsLoading] = useState(false);
    const [getPostTagsDescriptionLoading, setGetPostTagsDescriptionLoading] = useState(false);
    const [getTimeManagmentDetailsLoading, setGetTimeManagmentDetailsLoading] = useState(false);
    const [updateMarksLoading, setUpdateMarksLoading] = useState(false);
    const [updatePerformanceStatusLoading, setUpdatePerformanceStatusLoading] = useState(false);

      useEffect(()=>{
        adminUserStatusList().then((res)=>{
            setBreakContents(res.data.data);
        }).catch((err)=>{

        })
    },[])
    
    useEffect(()=>{
        setUserListLoading(true);
        setIsShowHeader(true);
        userLists().then((res)=>{
            setRolesuserLists(res.data.data.values);
            setUserListLoading(false);
        }).catch((err)=>{
            setUserListLoading(false);
        })
        setSelectedUser(null);
    },[selectTabId]);
    function changeSelectTabId(tab_list_id){
        
        setselectTabId(tab_list_id);
        setCompletedPostLists([]);
        setPostDetails(null);
        setPostDescription(null);
        setcurModePostTag(null);
        setCurModePostSubTag(null);
        setIsShowTag(true);
        setTagTotalPage(0)
        setOpenItems();
        setTagShowCount({tags: 1, description: 1, openItems: 1});
        setTagMarks([]);
        setSubTagMarks([]);
        setDescriptionMarks({});
        setOpenItemMarks([]);
        setUserDailyActiviyTracker(null);
        setUserPerformance(null);
    }
    function handleTabUserClick(tab, user_id){
        setSelectedUser(user_id);
        if(completedPostLists.length === 0 || (user_id !== completedPostLists[0].user_id)){
            setGetCompletedPostListLoading(true)
            setGetPerformanceDetailsLoading(true)
            setGetTimeManagmentDetailsLoading(true)
            changeSelectTabId(selectTabId);
            let tab2 = tabLists.find((tabList) => {
                return tabList.id === tab;
            });
            getCompletedPostList(user_id, tab2.for).then((res)=>{
                setCompletedPostLists(res.data.data);
                setGetCompletedPostListLoading(false)
            }).catch((err)=>{
                setGetCompletedPostListLoading(false)
            });

            getTimeManagmentDetails(user_id).then((res)=>{
                setUserDailyActiviyTracker(res.data.data);
                setGetTimeManagmentDetailsLoading(false);
            }).catch((err)=>{
                setGetTimeManagmentDetailsLoading(false);
            });
            
            getPerformanceDetails(user_id, selectTabId === "tab-v-1" ? "curator" : "moderator").then((res)=>{
                setUserPerformance(res.data.data);
                let last_calibration_tags = res.data.data.last_calibration_tags;
                let last_calibration_description = res.data.data.last_calibration_description;
                let last_calibration_open_items = res.data.data.last_calibration_open_items;
                
                let tag_mark = decimalNumber((defineTagsMarks / (res.data.data.parameter_count + res.data.data.sub_parameter_count)) *  last_calibration_tags);
                let description_mark = defineDescriptionMarks * last_calibration_description;
                let open_item_mark = selectTabId == "tab-v-1" ? 0 : ((defineOpenItemsMarks / res.data.data.open_item_count) * last_calibration_open_items);
                setLastCalibrationMark(tag_mark + description_mark + open_item_mark);
                setGetPerformanceDetailsLoading(false);
            }).catch((err)=>{
                setGetPerformanceDetailsLoading(false);
            });
        }
    }
    function handlePostClick(post_id, cur_mod_post_id){
        setGetPostDetailsLoading(true);
        setGetPostTagsDescriptionLoading(true);
        setTagShowCount({tags: 1, description: 1, openItems: 1});
        setTagMarks([]);
        setSubTagMarks([]);
        setDescriptionMarks({});
        setOpenItemMarks([]);
        getPostDetails(post_id).then((res)=>{
            setPostDetails(res.data.data);
            let mark_tags = [];
            let mark_sub_tags = [];

            if(selectTabId == "tab-v-1" && res.data.data.curator_post[0].qa_evaluated_at){
                mark_tags = res.data.data.curator_post[0].curator_post_parameter.map(item => ({
                    post_parameter: {
                    id: item.id,
                    mark: item.marks,
                    name: item.parameter.name,
                    },
                }));
                mark_sub_tags = res.data.data.curator_post[0].curator_post_sub_parameter.map(item=>({
                    sub_parameter: {
                        id: item.sub_parameter_id,
                        mark: item.marks,
                        name: item.sub_parameter.name
                    },
                }))
                setDescriptionMarks({curator_post_id: res.data.data.curator_post[0].id,description_marks:res.data.data.curator_post[0].description_mark})
            }
            if(selectTabId == "tab-v-2" && res.data.data.moderator_post[0].qa_evaluated_at){
                mark_tags = res.data.data.moderator_post[0].moderator_post_parameter.map(item => ({
                    post_parameter: {
                        id: item.id,
                        mark: item.marks,
                        name: item.parameter.name,
                    },
                }));
                mark_sub_tags = res.data.data.moderator_post[0].moderator_post_sub_parameter.map(item=>({
                    sub_parameter: {
                        id: item.sub_parameter_id,
                        mark: item.marks,
                        name: item.sub_parameter.name
                    },
                }));
                let mark_open_item = res.data.data.moderator_post[0].moderator_post_open_item.map(item=>({
                    open_item: {
                        id: item.id,
                        mark: item.marks,
                        name: item.moderator_open_item.question
                    }
                }))
                setOpenItemMarks(mark_open_item);
            }
            setTagMarks(mark_tags);
            setSubTagMarks(mark_sub_tags);
            setGetPostDetailsLoading(false);
        }).catch((err)=>{
            setGetPostDetailsLoading(false);
        });
        getPostTagsDescription(cur_mod_post_id).then((res)=>{
            setPostDescription(res.data.data.description)
            setGetPostTagsDescriptionLoading(false);
        }).catch((err)=>{
            setGetPostTagsDescriptionLoading(false);
        });
        getTagsDetails(cur_mod_post_id);
        getOpenItemDetails(cur_mod_post_id);
    }
    function getOpenItemDetails(cur_mod_post_id, page=1, calc){
        setGetOpenItemAnswersLoading(true);
        getOpenItemAnswers(cur_mod_post_id, page).then((res)=>{
            setOpenItems(res.data.data);
            if(calc && openItems.total >= tagShowCount.openItems){
                setTagShowCount((prev)=>  ({
                    ...prev, 
                    openItems:calc === "add" ? prev.openItems + 1 : prev.openItems - 1}))  
            }
        setGetOpenItemAnswersLoading(false);
        }).catch((err)=>{
        setGetOpenItemAnswersLoading(false);
            console.log(err)
        })
    }
    function getTagsDetails(cur_mod_post_id, page=1, calc){
        setGetPostTagDetailsLoading(false)
        setIsEnableNextPrvButton(false);
        getPostTagDetails(cur_mod_post_id, selectTabId === "tab-v-1" ? "curator" : "moderator",page).then((res)=>{
            setTagTotalPage(res.data.data.total_pages);
            if(res.data.data.total_pages < page){
                setIsShowTag(false)
            }
            else{
                setIsShowTag(true)
            }
            setcurModePostTag(res.data.data);
            let sub_tag = res.data.data.values.map((item)=>{
                if (item.parameter.is_sub_tag === 1) {
                    const curModPostId = item.moderator_post_id;
                    const subParameter = item.parameter.sub_parameters[0];
                    const subTags = item.moderator_post_tag.flatMap(tag => (
                      tag.moderator_post_sub_tag.map(subTag => ({name:subTag.sub_tag.name}))
                    ));
        
                    return {
                        curModPostId:curModPostId,   
                        subParameterId: subParameter.id,
                        name: subParameter.name,
                        subTags: subTags,
                    };
                  }
            });
            setCurModePostSubTag((prev) => {
                if (sub_tag.length > 0 && sub_tag[0]) {
                  return { ...prev, sub_tag };
                } else {
                  return prev;
                }
            });
            setIsEnableNextPrvButton(true);
            if(calc && userPerformance.parameter_count + userPerformance.sub_parameter_count >= tagShowCount.tags){
                setTagShowCount((prev)=>  ({
                    ...prev, 
                    tags:calc === "add" ? prev.tags + 1 : prev.tags - 1}))  
            }
            setGetPostTagDetailsLoading(false);
        }).catch((err)=>{
            setGetPostTagDetailsLoading(false);
        })
    }
    function markTagValues(post_parameter_id, mark, name, curModPostId, currentPage, add){
        setTagMarks((prev) => {
            const existingMarkIndex = prev.findIndex((item) => item.post_parameter.id === post_parameter_id);
            if (existingMarkIndex !== -1) {
                return [
                    ...prev.slice(0, existingMarkIndex),
                    {
                        post_parameter: {
                            id: post_parameter_id,
                            mark: mark,
                            name: name
                        },
                    },
                    ...prev.slice(existingMarkIndex + 1),
                ];
            } 
            else {
                return [
                    ...prev,
                    {
                        post_parameter: {
                            id: post_parameter_id,
                            mark: mark,
                            name: name
                        },
                    },
                ];
            }
        });
        if(tagTotalPage==0 || currentPage <= tagTotalPage)          
        getTagsDetails(curModPostId, currentPage, add)
    }
    function markSubTagValues(cur_mod_post_id, sub_parameter_id, mark, name){
        setSubTagMarks((prev) => {
            const existingMarkIndex = prev.findIndex((item) => item.sub_parameter.id === sub_parameter_id);
            if (existingMarkIndex !== -1) {
                return [
                    ...prev.slice(0, existingMarkIndex),
                    {
                        sub_parameter: {
                            id: sub_parameter_id,
                            mark: mark,
                            name: name
                        },
                    },
                    ...prev.slice(existingMarkIndex + 1),
                ];
            } 
            else {
                return [
                    ...prev,
                    {
                        sub_parameter: {
                            id: sub_parameter_id,
                            mark: mark,
                            name: name
                        },
                    },
                ];
            }
        });          
    }
    function markOpenItemValues(open_item_id, mark, name){
        setOpenItemMarks((prev) => {
            const existingMarkIndex = prev.findIndex((item) => item.open_item.id === open_item_id);
            if (existingMarkIndex !== -1) {
                return [
                    ...prev.slice(0, existingMarkIndex),
                    {
                        open_item: {
                            id: open_item_id,
                            mark: mark,
                            name: name
                        },
                    },
                    ...prev.slice(existingMarkIndex + 1),
                ];
            } 
            else {
                return [
                    ...prev,
                    {
                        open_item: {
                            id: open_item_id,
                            mark: mark,
                            name: name
                        },
                    },
                ];
            }
        });          
    } 
    function callUpdateMarksApi(cur_mode_post_id){
        setUpdateMarksLoading(true);
        const currentDateTime = new Date().toISOString().slice(0, 19).replace("T", " ");
        updateMarks(selectTabId === "tab-v-1" ? "curator" : "moderator", tagMarks, subTagMarks, descriptionMarks, openItemMarks).then((res)=>{
            if(selectTabId === "tab-v-1"){
                setCompletedPostLists((prev) =>
                    prev.map((c_m_p) =>
                        c_m_p.curator_post[0].id === cur_mode_post_id
                        ? {
                            ...c_m_p,
                            curator_post: [
                                {
                                ...c_m_p.curator_post[0],
                                qa_evaluated_at: currentDateTime, // Make sure it's a string
                                },
                            ],
                            }
                        : c_m_p
                    )
                );
                let last_calibration_tags = tagMarks.filter((item)=>item.post_parameter.mark=== 1).length + subTagMarks.filter((item)=>item.sub_parameter.mark === 1).length;
                let last_calibration_description = descriptionMarks.description_marks || 0;
                let last_calibration_open_items = openItemMarks.filter((item)=>item.open_item.mark === 1).length;
                setUserPerformance((prev)=>({...prev,
                    last_calibration_date : currentDateTime,
                    last_calibration_tags : last_calibration_tags,
                    last_calibration_description : last_calibration_description,
                    last_calibration_open_items : last_calibration_open_items,
                    sum_of_tag_marks : prev.sum_of_tag_marks + last_calibration_tags,
                    sum_of_description : prev.sum_of_description + last_calibration_description,
                    sum_of_open_items : prev.sum_of_open_items + last_calibration_open_items,
                    today_sum_of_tag_marks : prev.today_sum_of_tag_marks + last_calibration_tags,
                    today_sum_of_description : prev.today_sum_of_description + last_calibration_description,
                    today_sum_of_open_items : prev.today_sum_of_open_items + last_calibration_open_items,
                    
                }))
                let tag_mark = decimalNumber((defineTagsMarks / (userPerformance.parameter_count + userPerformance.sub_parameter_count)) *  last_calibration_tags);
                let description_mark = defineDescriptionMarks * last_calibration_description;
                let open_item_mark = selectTabId == "tab-v-1" ? 0 : ((defineOpenItemsMarks / res.data.data.open_item_count) * last_calibration_open_items);
                setLastCalibrationMark(tag_mark + description_mark + open_item_mark);
            }else{
                setCompletedPostLists((prev) =>
                    prev.map((c_m_p) =>
                        c_m_p.moderator_post[0].id === cur_mode_post_id
                        ? {
                            ...c_m_p,
                            moderator_post: [
                                {
                                ...c_m_p.moderator_post[0],
                                qa_evaluated_at: currentDateTime, // Make sure it's a string
                                },
                            ],
                            }
                        : c_m_p
                    )
                );
            }
            setUpdateMarksLoading(false);
        }).catch((err)=>{
            setUpdateMarksLoading(false);
        })
    }
    function handleAdminPerformanceStatus(e){
        setUpdatePerformanceStatusLoading(true);
        updatePerformanceStatus(e.target.value, userDailyActiviyTracker.user_id).then((res)=>{
        setUpdatePerformanceStatusLoading(false);
        }).catch((err)=>{
            setUpdatePerformanceStatusLoading(false);
        })
        setUserPerformance((prev)=>({...prev, [e.target.name]:e.target.value}))
    }
    function resetAllMarks(cur_mod_post_id){
        setOpenItemMarks([]);
        setTagMarks([]);
        setSubTagMarks([]);
        setDescriptionMarks({});
        getTagsDetails(cur_mod_post_id, 1, "");
        setTagShowCount({tags: 1, description: 1, openItems: 1});
    }
    const videoRef = useRef(null);
    const [heightVideo, setHeightVideo] = useState(0);
  const handleMetadataLoaded = () => {
    
    const width = videoRef.current.videoWidth;
    const heightvideo = videoRef.current.videoHeight;
    setHeightVideo(heightvideo);
  
    console.log("Video Width:", width);
    console.log("Video Height:", heightvideo);
  };
  const mainLoading = userListLoading || getCompletedPostListLoading || getOpenItemAnswersLoading || getPerformanceDetailsLoading || getPostDetailsLoading || getPostTagDetailsLoading || getPostTagsDescriptionLoading || getTimeManagmentDetailsLoading || updateMarksLoading || updatePerformanceStatusLoading;
    return (
        <>
            {/* <Header></Header> */}
            {mainLoading ? <MediLoader /> :
            <main className="main-content main-top">
                <div className="">
                    <div className="container-fluid mt-20">
                        <div className='row'>
                            <div className='col-lg-2 col-md-3 col-sm-12'>
                                <div className="card">
                                    <div className="card-body qa-curator-height">
                                        <div className="tab-wrapper">
                                            <div className="atbd-tab tab-horizontal">
                                                <ul className="nav nav-tabs vertical-tabs" role="tablist">
                                                    {tabLists?.map((tabList, key) => {
                                                        return (
                                                            <li className="nav-item" key={key} onClick={() => changeSelectTabId(tabList.id)}>
                                                                <a className={`nav-link ${tabList.id === selectTabId ? "active" : ""}`} id={tabList.tab_name} data-toggle="tab" href={`#${tabList.id}`} role="tab" aria-controls={tabList.id} aria-selected="true">{tabList.name}</a>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                                <div className="tab-content">
                                                    <div className="tab-pane fade show active" id="tab-v-1" role="tabpanel" aria-labelledby="tab-v-1-tab">
                                                        <div className="table4 bg-white mb-30">
                                                            <div className="table-responsive">
                                                                <table className="table mb-0">
                                                                    {rolesuserLists?.map((userList, key) => {
                                                                        return (
                                                                            <tr key={key} onClick={() => handleTabUserClick(selectTabId, userList.user_id)} className={`${selectedUser === userList.user_id && 'bg-success'} Product-cart-title hand-gesture` }>
                                                                                <td><a>{userList.profile_name}</a></td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='card mt-20'>
                                    <div className='card-body qa-post-height qa-post'>
                                        {selectedUser ? (
                                            completedPostLists.length === 0 ? (
                                                <div className="qa-post-contents">
                                                    <h6>{selectTabId === "tab-v-1" ? `No post` : `No post`}</h6>
                                                </div>
                                            ) : (
                                                <div className="product-cart mb-sm-0 mb-20">
                                                    <div className="table-responsive">
                                                        <table id="cart" className="table table-borderless table-hover">
                                                            <tbody>
                                                                {completedPostLists?.map((cpl, key) => {
                                                                    return(
                                                                        <tr key={key} onClick={() => handlePostClick(cpl.post.id, selectTabId === "tab-v-1" ? cpl.curator_post[0].id : cpl.moderator_post[0].id)} >
                                                                            {/* <td className={`hand-gesture ${selectTabId === "tab-v-1" ? (cpl.curator_post[0].qa_evaluated_at ? "Product-cart-title" : "Product-cart-title") : (cpl.moderator_post[0].qa_evaluated_at ? "Product-cart-title " : "Product-cart-title")}`}> */}
                                                                            <td className={`hand-gesture Product-cart-title ${selectTabId === "tab-v-1" ? (postDetails && cpl.post.id == postDetails.id ? "bg-success" : "") : (postDetails && cpl.post.id == postDetails.id ? "bg-success" : "")}`}>
                                                                                <div className="media  align-items-center">
                                                                                    {cpl.post.attachments.length > 0 ? (
                                                                                        <div>
                                                                                            {CheckImage(cpl.post.attachments[0].attachment) === "image" ? (
                                                                                                <img className="mr-3 wh-60 align-self-center radius-xl bg-opacity-primary" src={ImageUrl() + cpl.post.attachments[0].attachment} alt="" />
                                                                                            ) : CheckImage(cpl.post.attachments[0].attachment) === "video" ? (
                                                                                                <video controls className="mr-3 wh-60 align-self-center radius-xl bg-opacity-primary">
                                                                                                    <source src={ImageUrl() + cpl.post.attachments[0].attachment} type="video/mp4" />
                                                                                                    Your browser does not support the video tag.
                                                                                                </video>
                                                                                            ) : (
                                                                                                <a href={ImageUrl() + cpl.post.attachments[0].attachment} target="_blank" rel="noopener noreferrer">
                                                                                                    View Attachment
                                                                                                </a>
                                                                                            )}
                                                                                        </div>
                                                                                    ) : (
                                                                                        <img className="mr-3 wh-60 align-self-center radius-xl bg-opacity-primary" src="" alt="no attachment" />
                                                                                    )}
                                                                                    <div className="media-body">
                                                                                        <h5 className="mt-0">{cpl.post.title || ""}</h5>
                                                                                        {cpl.post.description &&
                                                                                            <p>{cpl.post.description.length > 20 ? cpl.post.description.slice(0, 20) + "..." : cpl.post.description}</p>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            )
                                        ) : (
                                            <div className="qa-post-content">
                                                <h6 style={{ cursor: selectedUser ? 'auto' : 'not-allowed' }}>Select Moderator/curator</h6>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {postDetails ? 
                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className="card">
                                    <div className="card-header color-dark fw-500">
                                        <h6>{postDetails.title || "-"}</h6>
                                    </div>
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                                {postDetails.attachments.length > 0 ?  
                                                <div className='few-post-img'>
                                                {CheckImage(postDetails.attachments[0].attachment) === 'image' && (
                                                    <img src={ImageUrl() + postDetails.attachments[0].attachment} />
                                                )}
                                                {CheckImage(postDetails.attachments[0].attachment) === 'video' && (
                                                    <video
                                                        ref={videoRef}
                                                        controls
                                                        className='w-100 '
                                                         src={ImageUrl() + postDetails.attachments[0].attachment}
                                                        onLoadedMetadata={handleMetadataLoaded}
                                                    />
                                                )}
                                                {CheckImage(postDetails.attachments[0].attachment) == "others" &&
                                                    (
                                                        <a href={ImageUrl() + postDetails.attachments[0].attachment} target="_blank" rel="noopener noreferrer">
                                                            Download Attachment
                                                        </a>
                                                    )}
                                                </div>
                                                : <img src="" alt="No Image" srcset="" /> }
                                                {postDetails.description &&
                                                <div className='post-dis mt-10'>
                                                    <h5>{postDetails.title ? EachWordCapitalize(postDetails.title) : "-"}</h5>
                                                    <p>
                                                    {showFullDescription ?
                                                    postDetails.description :
                                                        postDetails.description.length > 200 ?
                                                            postDetails.description.slice(0, 200) + "..." : postDetails.description}

                                                    {postDetails.description ? postDetails.description.length > 200 ? !showFullDescription ?
                                                        <a onClick={()=>setShowFullDescription(!showFullDescription)}>Read More</a>
                                                        :
                                                        <a onClick={()=>setShowFullDescription(!showFullDescription)}>show Less</a>
                                                        : null
                                                        : null
                                                    }
                                                    </p>
                                                </div>
                                                }
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                                <div className='table-responsive  border-left' 
                                                style={{ height: heightVideo < 300 ? `${heightVideo}px` : '100%' }}
                                                >
                                                    <table className='table'>
                                                        <tr>
                                                        {console.log(postDetails)}
                                                            <td className='table-title'>Owner</td>
                                                            <td>{postDetails.user ? postDetails.user.name : "-"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='table-title'>Post Date</td>
                                                            <td>{postDetails.date}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='table-title'>Location</td>
                                                            <td>{postDetails.location}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='table-title'>Views</td>
                                                            <td>{postDetails.post_view_count}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='table-title'>Likes</td>
                                                            <td>{postDetails.likes_count}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='table-title'>Dislikes</td>
                                                            <td>{postDetails.dislikes_count}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='table-title'>Inspiration</td>
                                                            <td>{postDetails.type === "inspirations" ? "Yes" : "No"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='table-title'>Ministry</td>
                                                            <td>{postDetails.type === "ministry" ? "Yes" : "No"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='table-title'>Hosting</td>
                                                            <td>{postDetails.is_live ? "Yes" : "No"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='table-title'>Funding</td>
                                                            <td>{postDetails.post_funding ? "Yes" : "No" }</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-20'>
                                    {selectTabId == "tab-v-2" && openItems &&
                                    <div className='card '>
                                        <div className='card-header'>
                                            <div className='row w-100'>
                                                <div className='col-6'><h6>Open items</h6></div>
                                                <div className='col-6 text-right'>
                                                    <a href='#'>{tagShowCount.openItems} / {openItems.total}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-body'>
                                            <textarea className='form-control'  readOnly value={openItems.values[0].moderator_open_item.question}></textarea>
                                        </div>
                                        <div className='card-footer'>
                                            <div className='row '>
                                                <div className='col-8'><p>{openItems.values[0].value}</p></div>
                                                <div className='col-2'>
                                                    <label className="colorinput mr-2">
                                                        <input name="color1" type="radio" value="green" className="colorinput-input" onChange={()=>markOpenItemValues(openItems.values[0].id, 1, openItems.values[0].moderator_open_item.question )} checked={openItemMarks?.find((om)=>om.open_item.id === openItems.values[0].id && om.open_item.mark === 1) ? true : false}/>
                                                        <span className="colorinput-color bg-green"></span>
                                                    </label>

                                                    <label className="colorinput">
                                                        <input name="color1" type="radio" value="orange" className="colorinput-input" onChange={()=>markOpenItemValues(openItems.values[0].id, 0, openItems.values[0].moderator_open_item.question )} checked={openItemMarks?.find((om)=>om.open_item.id === openItems.values[0].id && om.open_item.mark === 0) ? true : false}/>
                                                        <span className="colorinput-color bg-orange"></span>
                                                    </label>
                                                </div>
                                                <div className='col-2 nxt-prv-btn'>
                                                   <a className='mr-20' style={openItems.current_page == 1 ? disabledLinkStyle : {}} onClick={()=>getOpenItemDetails(curModePostTag.values[0].moderator_post_id, openItems.current_page-1, "sub")}><img src="/assets/img/back-01.svg" alt="" />
                                                   </a>
                                                   <a style={openItems.current_page >= openItems.total_pages ? disabledLinkStyle : {}} onClick={()=>getOpenItemDetails(curModePostTag.values[0].moderator_post_id, openItems.current_page+1, "add")}><img src="/assets/img/next-01.svg" alt="" /></a>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {selectTabId == "tab-v-1" && 
                                    <div className='card mt-20'>
                                        <div className='card '>
                                        <div className='card-header'>
                                            <div className='row w-100'>
                                                <div className='col-6'><h6>Post Description</h6></div>
                                                <div className='col-6 text-right'>
                                                    <a href='#'>1 / 1</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-body'>
                                            <textarea className='form-control' readOnly value={postDescription}></textarea>
                                        </div>
                                        <div className='card-footer'>
                                            <div className='row '>
                                                <div className='col-8'></div>
                                                <div className='col-2'>
                                                    <label className="colorinput mr-2">
                                                        <input name="color2" type="radio" value="green" className="colorinput-input" onChange={()=>setDescriptionMarks({curator_post_id: curModePostTag.values[0].curator_post_id,description_marks:1})} checked={descriptionMarks.description_marks === 1 ? true : false}/>
                                                        <span className="colorinput-color bg-green" ></span>
                                                    </label>
                                                    <label className="colorinput">
                                                        <input name="color2" type="radio" value="orange" className="colorinput-input" onChange={()=>setDescriptionMarks({curator_post_id: curModePostTag.values[0].curator_post_id,description_marks:0})} checked={descriptionMarks.description_marks === 0 ? true : false}/>
                                                        <span className="colorinput-color bg-danger"></span>
                                                    </label>
                                                </div>
                                                {/* <div className='col-2 nxt-prv-btn'>
                                                   <a href='#' className='mr-20'><img src="/assets/img/prv-btn.png" alt="" /></a><a href='#'><img src="/assets/img/nxt-btn.png" alt="" /></a>
                                                </div>  */}
                                            </div>
                                        </div>
                                    </div>
                                    </div>}
                                    
                                    {isShowTag && curModePostTag && curModePostTag.values.length > 0 &&
                                    <div className='card mt-20'>
                                        <div className='card-header'><div className='row w-100'><div className='col-6'>{selectTabId == "tab-v-1" ? <h6>Curations</h6> : <h6>Moderations</h6>}</div><div className='col-6 text-right'>
                                            <a href='#'>
                                                {tagShowCount.tags} / {userPerformance.parameter_count + userPerformance.sub_parameter_count}
                                            </a>
                                        </div></div></div>
                                        <div className='card-body'> 
                                            <div className='row'>
                                                <div className='col-8'>
                                                    <p>{EachWordCapitalize(curModePostTag.values[0].parameter.name)}</p>
                                                </div>
                                                <div className='col-2'>
                                                    <label className="colorinput mr-2">
                                                        <input name="color3" type="radio" value="green" className="colorinput-input" onChange={()=>markTagValues(curModePostTag.values[0].id, 1, curModePostTag.values[0].parameter.name, selectTabId === "tab-v-1" ?curModePostTag.values[0].curator_post_id : curModePostTag.values[0].moderator_post_id, curModePostTag.current_page+1, "add")} 
                                                        checked={tagMarks?.find((tag)=>tag.post_parameter.id === curModePostTag?.values[0].id && tag.post_parameter.mark === 1) ? true : false}
                                                        />
                                                        <span className="colorinput-color bg-green"></span>
                                                    </label>

                                                    <label className="colorinput">
                                                        <input name="color3" type="radio" value="orange" className="colorinput-input" onChange={()=>markTagValues(curModePostTag.values[0].id, 0, curModePostTag.values[0].parameter.name, selectTabId === "tab-v-1" ?curModePostTag.values[0].curator_post_id : curModePostTag.values[0].moderator_post_id, curModePostTag.current_page+1, "add") } 
                                                        checked={tagMarks?.find((tag)=>tag.post_parameter.id === curModePostTag?.values[0].id && tag.post_parameter.mark === 0) ? true : false}
                                                        />
                                                        <span className="colorinput-color bg-danger"></span>
                                                    </label>
                                                </div>
                                                <div className='col-2 nxt-prv-btn'>
                                                    <a style={curModePostTag.current_page == 1 || !isEnableNextPrvButton ? disabledLinkStyle : {}}className='mr-20' onClick={()=>getTagsDetails(selectTabId === "tab-v-1" ?curModePostTag.values[0].curator_post_id : curModePostTag.values[0].moderator_post_id, curModePostTag.current_page-1, "sub")}><img src="/assets/img/back-01.svg" alt="" /></a><a style={!isEnableNextPrvButton || (!curModePostSubTag &&( curModePostTag.current_page === tagTotalPage)) ? disabledLinkStyle : {}} onClick={()=>getTagsDetails(selectTabId === "tab-v-1" ?curModePostTag.values[0].curator_post_id : curModePostTag.values[0].moderator_post_id, curModePostTag.current_page+1, "add")}><img src="/assets/img/next-01.svg" alt="" /></a>
                                                </div> 
                                            </div>
                                        </div>
                                        {curModePostTag.values.length > 0  &&
                                        <div className='card-footer'>
                                            {curModePostTag.values[0].moderator_post_tag?.map((tag, key)=>{return(
                                            <span className="atbd-tag tag-light " key={key}>{tag.tag.name}</span> )})}
                                            {curModePostTag.values[0].curator_post_tag?.map((tag, key)=>{return(
                                            <span className="atbd-tag tag-light " key={key}>{tag.tag.name}</span> )})}
                                        </div>}
                                    </div>}
                                    {selectTabId == "tab-v-2" && !isShowTag && curModePostSubTag &&
                                    <div className='card mt-20'>
                                        <div className='card-header'><div className='row w-100'><div className='col-6'>{selectTabId == "tab-v-1" ? <h6>Curations</h6> : <h6>Moderations</h6>}</div><div className='col-6 text-right'><a href='#'>{tagShowCount.tags} / {userPerformance.parameter_count + userPerformance.sub_parameter_count}</a></div></div></div>
                                        <div className='card-body'> 
                                            <div className='row'>
                                                <div className='col-8'>
                                                    <p>{EachWordCapitalize(curModePostSubTag.sub_tag[0].name)}</p>
                                                </div>
                                                <div className='col-2'>
                                                
                                                    <label className="colorinput mr-2">
                                                        <input name="color4" type="radio" value="green" className="colorinput-input" onChange={()=>markSubTagValues(curModePostSubTag.sub_tag[0].curModPostId,curModePostSubTag.sub_tag[0].subParameterId, 1, curModePostSubTag.sub_tag[0].name)}/>
                                                        <span className="colorinput-color bg-green"></span>
                                                    </label>

                                                    <label className="colorinput">
                                                        <input name="color4" type="radio" value="orange" className="colorinput-input" onChange={()=>markSubTagValues(curModePostSubTag.sub_tag[0].curModPostId,curModePostSubTag.sub_tag[0].subParameterId, 0, curModePostSubTag.sub_tag[0].name)}/>
                                                        <span className="colorinput-color bg-danger"></span>
                                                    </label>
                                                </div>
                                                <div className='col-2 nxt-prv-btn'>
                                                    <a className='mr-20' onClick={()=>getTagsDetails(curModePostSubTag.sub_tag[0].curModPostId, tagTotalPage, "sub")}><img src="/assets/img/back-01.svg" alt="" /></a>
                                                    <a style={disabledLinkStyle}><img src="/assets/img/next-01.svg" alt="" /></a>
                                                </div> 
                                            </div>
                                        </div>
                                        {curModePostSubTag.sub_tag[0].subTags.length > 0  &&
                                        <div className='card-footer'>
                                            {curModePostSubTag.sub_tag[0].subTags.map((tag, key)=>{return(
                                            <span className="atbd-tag tag-light " key={key}>{tag.name}</span> )})}
                                        </div>}
                                    </div>}
                                </div>
                            </div>
                            : <div className='col-lg-6 col-md-6 col-sm-12' >
                            <div className='selectcenter card'>
                                {selectedUser ? (
                                    completedPostLists.length === 0 ? (
                                        <h6>{selectTabId === "tab-v-1" ? "The Curator selected, doesn’t have any posts made yet" : "The Moderator selected, doesn’t have any posts made yet"}</h6>
                                    ) : (
                                        <h3 >Select Post</h3>
                                    )
                                ) : (
                                    <h3 style={{ cursor: selectedUser ? 'auto' : 'not-allowed' }}>Select Post</h3>
                                )}
                            </div>
                        </div>}
                            <div className='col-lg-4 col-md-4 col-sm-12'>
                            <div className="card">
                            <div className="card">
                                {userDailyActiviyTracker ? (
                                    <div className="card-header color-dark fw-500 padding-left-5 border-0">
                                    <h6>Tracker</h6>
                                    </div>
                                ) : (
                                    <div className="card-body select-mod-cur d-flex justify-content-center align-items-center">
                                    <h6 style={{ cursor: selectedUser ? 'auto' : 'not-allowed' }}>Select Moderator/Curator</h6>
                                    </div>
                                )}
                                
                                
                                    {userDailyActiviyTracker && 
                                    <div className="card-body border border-radius m-10">
                                        <p className="sm-title">Time Managment</p>
                                        <div className='table-responsive qa-tracks-height'>
                                            <table className='table'>
                                                <tr>
                                                    <td className='table-title'>Over Night Logout</td>
                                                    <td>{formatTime(new Date().toDateString() + ' ' +utcTimetoLocalTime(userDailyActiviyTracker.last_logout_time, userDailyActiviyTracker.timezone)) || "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>Official Login</td>
                                                    <td>{userDailyActiviyTracker.official_login_time || "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>First Login</td>
                                                    
                                                    <td>{formatTime(new Date().toDateString() + ' ' +utcTimetoLocalTime(userDailyActiviyTracker.first_login, userDailyActiviyTracker.timezone)) || "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>First logout</td>
                                                    <td>{formatTime(new Date().toDateString() + ' ' +utcTimetoLocalTime(userDailyActiviyTracker.first_logout, userDailyActiviyTracker.timezone)) || "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>Recent Login</td>
                                                    <td>{formatTime(new Date().toDateString() + ' ' +utcTimetoLocalTime(userDailyActiviyTracker.recent_login, userDailyActiviyTracker.timezone)) || "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>Login Count</td>
                                                    <td>{userDailyActiviyTracker.login_count || 0}</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>Logout Count</td>
                                                    <td>{userDailyActiviyTracker.logout_count || 0}</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>Previous Break</td>
                                                    <td>{userDailyActiviyTracker.previous_break ? findValue(breakContents.data, userDailyActiviyTracker.previous_break).name || "-" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>Previous Break Duration</td>
                                                    <td>{minutesToHHMM(userDailyActiviyTracker.previous_break_duration)|| "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>Previous Break Time Taken</td>
                                                    <td>{formatTime(new Date().toDateString() + ' ' +utcTimetoLocalTime(userDailyActiviyTracker.previous_break_start_time, userDailyActiviyTracker.timezone)) || "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>Current Break</td>
                                                    <td>{userDailyActiviyTracker.current_break ? findValue(breakContents, userDailyActiviyTracker.current_break).name || "-" : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>Break Duration</td>
                                                    <td>{minutesToHHMM(userDailyActiviyTracker.break_duration)|| "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>Break Time Remaining</td>
                                                    <td>{minutesToHHMM(userDailyActiviyTracker.break_time_remaining) || "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>Official Session Duration</td>
                                                    <td>{minutesToHHMM(userDailyActiviyTracker.official_session_duration)}</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>session count down</td>
                                                    {console.log(userDailyActiviyTracker.session_count_down)}
                                                    <td>{minutesToHHMM(userDailyActiviyTracker.session_count_down)}</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>Last Login Location</td>
                                                    <td>India</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>Current Login Location</td>
                                                    <td>Chennai</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <div className='d-flex'><button className='btn btn-warning btn-default mr-2'>Lock</button><button className='btn btn-default btn-danger'>Logout</button></div></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div> }
                                    {(userDailyActiviyTracker && userPerformance) && 
                                    <div className='card-body mt-20 border border-radius m-10'>
                                        <p className="sm-title">Performance</p>
                                        <div className='table-responsive qa-per-height'>
                                            <table className='table'>
                                                <tr>
                                                    <td className='table-title'>Monthly set Target</td>
                                                    <td>{userDailyActiviyTracker.today_target ? currentMonthDates() * userDailyActiviyTracker.today_target : 0}</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>Daily set Target</td>
                                                    <td>{userDailyActiviyTracker.today_target || 0}</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>Average Time per Post</td>
                                                    <td>{minutesToHHMM(average(userDailyActiviyTracker.total_session-userDailyActiviyTracker.spend_break_time, userPerformance.today_achivement_count+userPerformance.today_extra_count))}</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>Month to Date</td>
                                                    <td>{userPerformance.today_achivement_count+userPerformance.today_extra_count || 0}</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>cumulative Extra</td>
                                                    <td>{userPerformance.today_extra_count || 0}</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>Cumulative Wasted</td>
                                                    <td>{userPerformance.monthly_wasted_at || 0}</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>Target-Today</td>
                                                    <td>{userDailyActiviyTracker.today_target || 0}</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>Monthly Calibration Average</td>
                                                    <td>{ Math.floor(((userPerformance.sum_of_tag_marks*(decimalNumber(defineTagsMarks/(userPerformance.parameter_count + userPerformance.sub_parameter_count),2)))+(userPerformance.sum_of_description*defineDescriptionMarks)+(selectTabId == "tab-v-2" ? (userPerformance.sum_of_open_items*(defineOpenItemsMarks / (userPerformance.open_item_count))) : 0 ))/new Date().getDate())} %</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>Daily Calibration Average</td>
                                                    <td>
                                                    {
                                                        Math.floor(
                                                        (
                                                            (userPerformance.today_sum_of_tag_marks * decimalNumber(defineTagsMarks / (userPerformance.parameter_count + userPerformance.sub_parameter_count), 2)) +
                                                            (userPerformance.today_sum_of_description * defineDescriptionMarks) +
                                                            (selectTabId == "tab-v-2" ? (userPerformance.today_sum_of_open_items * (userPerformance.open_item_count !== 0 ? (defineOpenItemsMarks / userPerformance.open_item_count) : 0)) : 0)
                                                        ) /
                                                        (userPerformance.today_completed_post_count !== 0 ? userPerformance.today_completed_post_count : 1) // Avoid division by zero
                                                        )
                                                    } %
                                                    </td>
                                                    
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>Date for last Calibration</td>
                                                    
                                                    <td>{(selectTabId == "tab-v-1" ? postDetails?.curator_post[0].qa_evaluated_at : postDetails?.moderator_post[0].qa_evaluated_at) ? "-" : userPerformance.last_calibration_date }</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>Last Calibration</td>
                                                    <td>{(selectTabId == "tab-v-1" ? postDetails?.curator_post[0].qa_evaluated_at : postDetails?.moderator_post[0].qa_evaluated_at) ? "-" : lastCalibrationMark}</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>Current Calibration</td>
                                                    <td>{Math.floor(((tagMarks.filter((item)=>item.post_parameter.mark=== 1).length + subTagMarks.filter((item)=>item.sub_parameter.mark).length) * (decimalNumber(defineTagsMarks/(userPerformance.sub_parameter_count+userPerformance.parameter_count),2))+((openItemMarks.filter((item)=>item.open_item.mark === 1)).length * (defineOpenItemsMarks / userPerformance.open_item_count) || 0) + (descriptionMarks.description_marks || 0 ) * defineDescriptionMarks)) }%</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>Status</td>
                                                    <td>
                                                        <select className='form-control' name="admin_performance_status" onChange={handleAdminPerformanceStatus}>
                                                            <option>select status</option>
                                                            {userPerformance.admin_performance_status_lists?.map((apsl, key)=>{return(
                                                            <option key={key} value={apsl.id} selected={userPerformance.admin_performance_status === apsl.id ? true : false} >{apsl.name} </option>
                                                            )})}
                                                            {/* <option>Training</option>
                                                            <option>Active</option>
                                                            <option>Attrition</option> */}
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <div className='d-flex'><button className='btn btn-warning btn-default mr-2'>Comment</button><button className='btn btn-default btn-danger'>email</button></div></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}><textarea className='form-control' rows={2} placeholder='Its will do development team'></textarea></td>
                                                </tr>

                                            </table>
                                        </div>
                                    </div>}
                                    {postDetails && 
                                    <div className='card-body mt-20 border border-radius m-10'>
                                        <p className="sm-title text-center">Current Calibration</p>
                                        <div className='table-responsive qa-per-height'>
                                            <table className='table'>
                                                <tr>
                                                    
                                                    <td className='table-title'>Total Score</td>
                                                    {curModePostTag &&
                                                    <td> {Math.floor(((tagMarks.filter((item)=>item.post_parameter.mark=== 1).length + subTagMarks.filter((item)=>item.sub_parameter.mark).length) * (decimalNumber(defineTagsMarks/(userPerformance.sub_parameter_count+userPerformance.parameter_count),2))+((openItemMarks.filter((item)=>item.open_item.mark === 1)).length * (defineOpenItemsMarks / userPerformance.open_item_count) || 0) + (descriptionMarks.description_marks || 0 ) * defineDescriptionMarks)) } </td>}
                                                </tr>

                                                <tr className=' table-title'>
                                                    <td className='' colSpan='2'>{selectTabId == "tab-v-1" ? "Curation Tags" : "Moderation Tags"}</td>
                                                </tr>
                                                {tagMarks?.map((tagMark)=>{return(
                                                <tr>
                                                    <td className='table-title'>{tagMark.post_parameter.name}</td>
                                                    <td className='table-title'>{decimalNumber(tagMark.post_parameter.mark * (defineTagsMarks / (userPerformance.parameter_count + userPerformance.sub_parameter_count)),2)}</td>
                                                </tr>
                                                )})}
                                                {subTagMarks?.map((tagMark)=>{return(
                                                <tr>
                                                    <td className='table-title'>{tagMark.sub_parameter.name}</td>
                                                    <td className='table-title'>{decimalNumber(tagMark.sub_parameter.mark * (defineTagsMarks / (userPerformance.parameter_count + userPerformance.sub_parameter_count)),2)}</td>
                                                </tr>
                                                )})}
                                                {selectTabId == "tab-v-1" && 
                                                <>
                                                <tr className='table-title'>
                                                    <td className=''colSpan='2'>Description</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title'>Post Overview</td>
                                                    <td>{descriptionMarks.description_marks != null ? (descriptionMarks.description_marks * defineDescriptionMarks) : ""}</td>
                                                </tr>
                                                </>
                                                }
                                                {selectTabId == "tab-v-2" && 
                                                <>
                                                <tr className='text-center'>
                                                    <td className='' colSpan='2'>Open Items</td>
                                                </tr>
                                                {openItems && openItemMarks?.map((oip)=>{
                                                    return(
                                                <tr>
                                                    <td className='table-title'>{oip.open_item.name}</td>
                                                    <td className='table-title'>{oip.open_item.mark * (defineOpenItemsMarks / openItems.total) }</td>
                                                </tr>
                                                )})} 
                                                </>
                                                
                                                }
                                            </table>
                                        </div>
                                    </div>}
                                    {postDetails &&
                                    <div className="card-body border border-radius m-10">
                                        <div className='d-flex justify-content-end'>
                                            {!(selectTabId === "tab-v-1" ? postDetails.curator_post[0].qa_evaluated_at : postDetails.moderator_post[0].qa_evaluated_at) && 
                                            <button className='btn btn-warning btn-default mr-2' onClick={()=>resetAllMarks(selectTabId === "tab-v-1" ?curModePostTag.values[0].curator_post_id : curModePostTag.values[0].moderator_post_id)}>Reset</button>
                                            }
                                            {((userPerformance.parameter_count + userPerformance.sub_parameter_count === tagMarks.length + subTagMarks.length) && (selectTabId === "tab-v-2" ? userPerformance.open_item_count === openItemMarks.length : descriptionMarks)) && 
                                            <button className='btn btn-default btn-success' onClick={()=>callUpdateMarksApi(selectTabId === "tab-v-1" ? postDetails.curator_post[0].id : postDetails.moderator_post[0].id)}>Save</button>}
                                        </div>
                                    </div>}
                                </div>
                            </div>
                         </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
                <div className="modal-basic modal fade show" id="modal-basic" tabindex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content modal-bg-white ">
                            <div className="modal-header">
                                <h6 className="modal-title">Set Target</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span data-feather="x"></span></button>
                            </div>
                            <div className="modal-body">
                                <div className="table4 bg-white pop-up-height">
                                    <div className="table-responsive">
                                        <table className='table mb-0'>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Target</th>
                                                    <td><input type='text' className='form-control' /></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Date</th>
                                                    <td><input type='date' className='form-control' /></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Start Time</th>
                                                    <td> <div className="form-group row form-group-calender mb-20">
                                                        <div className="col-sm-12">
                                                            <div className="time-picker">
                                                                <div className="form-group mb-0">
                                                                    <div className="input-container icon-right position-relative w-100 color-light ">
                                                                        <span className="input-icon icon-right">
                                                                            <span data-feather="clock"></span>
                                                                        </span>
                                                                        <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15 color-light" id="time-picker" placeholder="12:00 AM" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">End Time</th>
                                                    <td> <div className="form-group row form-group-calender mb-20">
                                                        <div className="col-sm-12">
                                                            <div className="time-picker">
                                                                <div className="form-group mb-0">
                                                                    <div className="input-container icon-right position-relative w-100 color-light ">
                                                                        <span className="input-icon icon-right">
                                                                            <span data-feather="clock"></span>
                                                                        </span>
                                                                        <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15 color-light" id="time-picker" placeholder="12:00 AM" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-success btn-sm">Save changes</button>
                                <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </main > }
        </>
    );
}
export default QA;