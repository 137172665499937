import axios from 'axios';
import { Configuration, ModeratorUrl } from './Url';

const moderatorQuestionUrl          = ModeratorUrl()+"get/questions";
const moderatorTargetUrl            = ModeratorUrl()+"get/target";
const moderatorParameterUrl         = ModeratorUrl()+"get/parameter";
const moderatorTagSearchUrl         = ModeratorUrl()+"search/parameter/tags";
const moderatorInsertTagUrl         = ModeratorUrl()+"post/create/tags";
const moderatorgetTagUrl            = ModeratorUrl()+"post/get/tags";
const moderatorDeleteTagUrl         = ModeratorUrl()+"post/delete/tag";
const moderatorPostOpenItemUrl      = ModeratorUrl()+"post/create/open/item";
const moderatorFinishModerationUrl  = ModeratorUrl()+"post/finish/moderation";
const moderatorGetPostCompletedUrl  = ModeratorUrl()+"post/completed/get";
const moderatorGetPostItemAnswerUrl = ModeratorUrl()+"post/get/open/item";
const moderatorPostHideUrl          = ModeratorUrl()+"post/hide";
const moderatorTagSearchSubUrl      = ModeratorUrl()+"search/parameter/sub/tags"
let config                          = Configuration();


export function moderatorTarget(page = 1, is_complete="", curator_target_id=""){
    let config = Configuration();
    let url = moderatorTargetUrl;
    let params = {
        limit:1
    }
    if(is_complete === 1)
    {
        params.is_complete = is_complete;
    }
    if(curator_target_id !== ""){
        params.curator_target_id = curator_target_id;
    }
    config.params = params;
    return axios.get(url,config)
}
export function moderatorQuestion(){
    let config = Configuration();
    let url = moderatorQuestionUrl;
    return axios.get(url,config)
}
export function moderatorParameter(page = 1){
    let config = Configuration();
    let url = moderatorParameterUrl;
    return axios.get(url,config)
}
export function moderatorSearchTag(search,parameter_id,sub_parameter_id,tag_ids=[]){
    let config = Configuration();
    let url = moderatorTagSearchUrl;
    let data = {
        parameter_id: parameter_id,
        search:search
    }
    if(sub_parameter_id !== "")
    {
        data.sub_parameter_id = sub_parameter_id;
        data.tag_ids = tag_ids;
    }
    config.params = data
    return axios.get(url,config)
}
export function moderatorInsertTag(post_id,tag_id,target_id,parameter_id,sub_parameter_id,sub_tag_id){
    let config = Configuration();
    let data = {
        "post_id"       :   post_id,
        "tags"          :   tag_id,
        "target_id"     :   target_id,
        "parameter_id"  :   parameter_id
    };
    if(sub_parameter_id !== "")
    {
        data.sub_parameter_id = sub_parameter_id;
        data.sub_tags = sub_tag_id;
    }
    let url = moderatorInsertTagUrl;
    return axios.post(url,data,config)
}
export function moderatorGetTag(post_id){
    let config = Configuration();
    let url = moderatorgetTagUrl;
    const params = {
        "post_id": post_id
    }
    config.params = params;
    return axios.get(url,config);
}
export function moderatorDeleteTag(post_id,tag_id,parameter_id, sub_parameter_id, sub_tag_id){
    let config = Configuration();
    let url = moderatorDeleteTagUrl;
    let data = {
        "post_id":post_id,
        "tag_id":tag_id
    }
    if(sub_parameter_id)
    {
        data.sub_parameter_id = sub_parameter_id;
        data.sub_tag_id = sub_tag_id;
    }
    config.params = data;
    return axios.get(url,config);
}
export function moderatorPostOpenItem(openItems){
    let config = Configuration();
    let url = moderatorPostOpenItemUrl;    
    let data = {
        data: openItems
    }
    return axios.post(url,data,config);
}
export function moderatorFinishModeration(post_id,parameters, open_items, status, description, skip_description, post_edit_id ){
    let config = Configuration();
    let data = {
            "post_id"   :   post_id,
            "parameters":   parameters,
            "status"    :   status,
            "description":  description,
            "skip_reason":  skip_description,
            "post_edit_id": post_edit_id
        };
        if(open_items.length > 0){
            data.open_items = open_items
        }
    let url = moderatorFinishModerationUrl;
    return axios.post(url,data,config);
}
export function moderatorGetPostCompleted(){
    let config = Configuration();
    let url = moderatorGetPostCompletedUrl;
    return axios.get(url,config);

}
export function moderatorGetPostItemAnswer(post_id){
    let config = Configuration();
    let url  = moderatorGetPostItemAnswerUrl;
    let data = {post_id:post_id}
    config.params = data;
    return axios.get(url,config);
}
export function moderatorPostHide(post_id){
    let config = Configuration();
    let url = moderatorPostHideUrl;
    const params = {
        "post_id": post_id
    }
    config.params = params;
    return axios.get(url,config);
}
export function moderatorSearchSubTag(search, parameter_id, tag_id){
    let config = Configuration();
    let url = moderatorTagSearchSubUrl;
    let data = {
        parameter_id: parameter_id,
        search: search,
        tag_id: tag_id
    }
    config.params = data;
    return axios.get(url,config)
}