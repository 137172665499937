import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from '../../layouts/header';
import Footer from '../../layouts/footer';
import Preloader from '../../Components/pre-loader';

const HomeBooks = ({ setIsShowHeader }) => {
    useEffect(() => {
        setIsShowHeader(true);
    }, [])
    return (
        <>
            {/* <Preloader></Preloader> */}
            {/* <Header></Header> */}
            <main className="main-content">

                {/* <div className="contents no-left-pane"> */}
                    <div className="container-fluid mt-10">
                        <div className='row w-100 mb-10'><div className='col-lg-6'><h6>&nbsp;</h6></div><div className='col-lg-6 justify-content-end'><button className='btn btn-warning btn-default' data-toggle="modal" data-target="#modal-basic"><i className="la la-plus"></i> Upload Book</button></div></div>
                        <div className='row'>
                            <div className='col-xxl-2 col-lg-3 col-md-3 col-sm-12'>
                                <div className='card'>
                                    <div className='card-header'>Archive</div>
                                    <div className='card-body book-archive-area'>
                                        <div className="tab-wrapper">
                                            <div className="atbd-tab tab-horizontal">
                                                <ul className="nav nav-tabs vertical-tabs mb-0" role="tablist">
                                                    <li className="nav-item"> <a className="nav-link active" id="tab-v-1-tab" data-toggle="tab" href="#tab-v-1" role="tab" aria-controls="tab-v-1" aria-selected="true">Pending</a> </li>
                                                    <li className="nav-item"> <a className="nav-link" id="tab-v-2-tab" data-toggle="tab" href="#tab-v-2" role="tab" aria-controls="tab-v-2" aria-selected="false">Pushed</a> </li>
                                                    <li className="nav-item"> <a className="nav-link" id="tab-v-3-tab" data-toggle="tab" href="#tab-v-3" role="tab" aria-controls="tab-v-3" aria-selected="false">Pulled</a> </li>
                                                </ul>
                                                <div className="tab-content">
                                                    <div className="tab-pane fade show active" id="tab-v-1" role="tabpanel" aria-labelledby="tab-v-1-tab">
                                                        <div className="table4 bg-white mb-30">
                                                            <div className="table-responsive">
                                                                <table className="table mb-0">
                                                                    <tr>
                                                                        <td><a href='#'>Test 01</a></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><a href='#'>Test 02</a></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><a href='#'>Test 03</a></td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="tab-v-2" role="tabpanel" aria-labelledby="tab-v-2-tab">
                                                        <div className="table4 bg-white mb-30">
                                                            <div className="table-responsive">
                                                                <table className="table mb-0">
                                                                    <tr>
                                                                        <td><a href='#'>Test 04</a></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><a href='#'>Test 05</a></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><a href='#'>Test 06</a></td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="tab-v-3" role="tabpanel" aria-labelledby="tab-v-3-tab">
                                                        <div className="table4 bg-white mb-30">
                                                            <div className="table-responsive">
                                                                <table className="table mb-0">
                                                                    <tr>
                                                                        <td><a href='#'>Test 07</a></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><a href='#'>Test 08</a></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><a href='#'>Test 09</a></td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xxl-7 col-lg-6 col-md-6 col-sm-12'>
                                <div className="card">
                                    <div className="card-header color-dark fw-500">
                                        Book Name -(It's will come dynamic)
                                    </div>
                                    <div className="card-body p-10 book-archive-area">
                                        <object data={"/assets/sample.pdf" + "#toolbar=0"} type="application/pdf" width="100%" height="460px"></object>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-3 col-sm-12'>
                                <div className="card">
                                    <div className="card-header border-0 color-dark fw-500 padding-left-5 ">
                                        <h6>Meta Data</h6>
                                    </div>
                                    <div className="card-body p-0 border border-radius m-10">
                                        <p className='sm-title'>Book Specific</p>
                                        <div className="table-responsive book-archive-data">
                                            <table className="table table-bordered">
                                                <tr>
                                                    <td>Cover Photo</td>
                                                    <td><img className="img-thumb" src="/assets/img/brightland_3744.png" alt="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>Book Name</td>
                                                    <td>Name</td>
                                                </tr>
                                                <tr>
                                                    <td>Book Type</td>
                                                    <td>Type</td>
                                                </tr>
                                                <tr>
                                                    <td>Language</td>
                                                    <td>Tamil</td>
                                                </tr>
                                                <tr>
                                                    <td>Religion(s)</td>
                                                    <td>Hindu</td>
                                                </tr>
                                                <tr>
                                                    <td>Denomination(s)</td>
                                                    <td>Hindu</td>
                                                </tr>
                                                <tr>
                                                    <td>Pages</td>
                                                    <td>120</td>
                                                </tr>
                                                <tr>
                                                    <td>Reads</td>
                                                    <td>26</td>
                                                </tr>
                                                <tr>
                                                    <td>Reviews</td>
                                                    <td>55</td>
                                                </tr>
                                                <tr>
                                                    <td>Ratings</td>
                                                    <td><div className="rating-half-star"></div></td>
                                                </tr>
                                                <tr>
                                                    <td>Date of upload</td>
                                                    <td>20-05-2023</td>
                                                </tr>
                                                <tr>
                                                    <td>Related books</td>
                                                    <td>Hindu</td>
                                                </tr>
                                                <tr>
                                                    <td>Status</td>
                                                    <td><span className="atbd-tag tag-warning tag-transparented">Pending</span></td>
                                                </tr>
                                                <tr>
                                                    <td>Action</td>
                                                    <td><select className='form-control'>
                                                        <option>Pull</option>
                                                        <option>Push</option>
                                                    </select></td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className='justify-content-end mb-2'><button className='btn btn-success btn-default btn-squared btn-sm mr-2'>Save</button><button className='btn btn-warning btn-default btn-squared btn-sm mr-3'>cancel</button></div>
                                    </div>
                                    <div className="card-body border border-radius m-10 mt-20">
                                        <p className='sm-title'>Uploader</p>
                                        <div className="table-responsive book-archive-upload">
                                            <table className="table table-bordered">
                                                <tr>
                                                    <td>Book Name</td>
                                                    <td>Name</td>
                                                </tr>
                                                <tr>
                                                    <td>Is Leader</td>
                                                    <td><div className="checkbox-theme-2 custom-checkbox  check-all">
                                                        <input className="checkbox" type="checkbox" id="check-3" checked />
                                                        <label for="check-3">
                                                            <span className="checkbox-text">
                                                                Yes
                                                            </span>
                                                        </label>
                                                    </div></td>
                                                </tr>
                                                <tr>
                                                    <td>Author</td>
                                                    <td>author name</td>
                                                </tr>
                                                <tr>
                                                    <td>Publisher</td>
                                                    <td>Publisher Name</td>
                                                </tr>
                                                <tr>
                                                    <td>Status</td>
                                                    <td><span className="atbd-tag tag-info tag-transparented">Limited</span></td>
                                                </tr>
                                                <tr>
                                                    <td>Total Books</td>
                                                    <td>40</td>
                                                </tr>
                                                <tr>
                                                    <td>Actions</td>
                                                    <td><select className='form-control'>
                                                        <option>Limit</option>
                                                        <option>lift</option>
                                                        <option>Ground</option>
                                                        <option>email</option>
                                                    </select></td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className='justify-content-end'><button className='btn btn-success btn-default btn-squared btn-sm mr-2'>Save</button><button className='btn btn-warning btn-default btn-squared btn-sm mr-3'>cancel</button></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                {/* </div> */}
                
                <div className="modal-basic modal fade show" id="modal-basic" tabindex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content modal-bg-white ">
                            <div className="modal-header">
                                <h6 className="modal-title">Add Books</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span data-feather="x"></span></button>
                            </div>
                            <div className="modal-body">
                                <div className="table4 bg-white pop-up-height">
                                    <div className="table-responsive">
                                        <table className='table mb-0'>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Cover Photo</th>
                                                    <td><input type='file' className='form-control' name="myImage" accept="image/png, image/gif, image/jpeg" /><span><img className="img-thumb" src="/assets/img/brightland_3744.png" alt="" /></span></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Upload Book</th>
                                                    <td> <input className='form-control' type='file' accept="application/pdf" /></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Book Type</th>
                                                    <td><select className='form-control'>
                                                        <option>Bible</option>
                                                        <option>Scripture</option>
                                                        <option>History</option>
                                                    </select></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Book Name</th>
                                                    <td> <input className='form-control' type='text' /></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Pages</th>
                                                    <td> <input className='form-control' type='number' /></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Author Name</th>
                                                    <td> <input className='form-control' type='text' /></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Language</th>
                                                    <td><select className='form-control'>
                                                        <option>Tamil</option>
                                                        <option>English</option>
                                                        <option>Hindi</option>
                                                    </select></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Religion</th>
                                                    <td><select className='form-control'>
                                                        <option>Hindu</option>
                                                        <option>Islam</option>
                                                        <option>Hindi</option>
                                                    </select></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Denomination</th>
                                                    <td><select className='form-control'>
                                                        <option>Sunni</option>
                                                        <option>Islam</option>
                                                        <option>Hindi</option>
                                                    </select></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Publisher</th>
                                                    <td> <input className='form-control' type='text' /></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Synopsis</th>
                                                    <td><textarea className='form-control' rows={3}></textarea></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-success btn-sm">Save</button>
                                <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </main >
            <Footer></Footer>
        </>
    );
}
export default HomeBooks;