import axios from 'axios';
import { Configuration, CoreUrl } from "./Url";
const refreshTokenUrl = CoreUrl()+'auth/refresh/token';
// const loginUrl = CoreUrl()+'auth/login';
const loginUrl = 'https://api.v1.evzone.app/api/v1.0/app/core/auth/login';
let config                          = Configuration();


export function RefreshTokenApi(){
    let config = Configuration();
    return axios.post(refreshTokenUrl,"",config)
}
export function LoginApi(data){
    let config = Configuration();
    return axios.post(loginUrl,data,config);
}
