import axios from 'axios';
import { Configuration, CuratorUrl } from './Url';

const curatorTargetUrl              = CuratorUrl()+"get/target";
const curatorParameterUrl           = CuratorUrl()+"get/parameter";
const curatorTagSearchUrl           = CuratorUrl()+"search/parameter/tags";
const curatorInsertTagUrl           = CuratorUrl()+"post/create/tags";
const curatorgetTagUrl              = CuratorUrl()+"post/get/tags";
const curatorDeleteTagUrl           = CuratorUrl()+"post/delete/tag";
const curatorUpdateDescriptionUrl   = CuratorUrl()+"post/update/description";
const curatorFinishCurationUrl      = CuratorUrl()+"post/finish/curation";
const curatorGetPostCompletedUrl    = CuratorUrl()+"post/completed/get";
const curatorPostHideUrl            = CuratorUrl()+"post/hide";
const curatorTagSearchSubUrl        = CuratorUrl()+"search/parameter/sub/tags"
let config                          = Configuration();

export function curatorTarget(page = 1, is_complete="", post_id=""){
    let config = Configuration();
    let url = curatorTargetUrl;
    let params = {
        // page: page,
        limit:1
    }
    if(is_complete === 1)
    {
        params.is_complete = is_complete;
    }
    if(post_id !== ""){
        params.post_id = post_id;
    }
    config.params = params;
    return axios.get(url,config)
}
export function curatorParameter(page = 1){
    let config = Configuration();
    let url = curatorParameterUrl;
    return axios.get(url, config)
}
export function curatorSearchTag(search,parameter_id,sub_parameter_id,tag_ids=[]){
    let config = Configuration();
    // let url = curatorTagSearchUrl;
    // const token = getStorageToken();
    // const config = {
    //     headers: { 'Authorization': `Bearer ${token}`,'Accept': 'application/json' },
    //     params: {
    //         parameter_id: parameter_id,
    //         search:search
    //       }
    // };   
    let url = curatorTagSearchUrl;
    let data = {
        parameter_id: parameter_id,
        search:search
      }
    if(sub_parameter_id !== "")
    {
        data.sub_parameter_id = sub_parameter_id;
        data.tag_ids = tag_ids;
    }
    config.params = data
    return axios.get(url,config)
}
export function curatorInsertTag(post_id,tag_id,target_id,parameter_id,sub_parameter_id,sub_tag_id){
    let config = Configuration();
    let data = {
        "post_id"   :   post_id,
        "tags"      :   tag_id,
        "target_id" :   target_id,
        "parameter_id"  :   parameter_id
    };
    if(sub_parameter_id !== "")
    {
        data.sub_parameter_id = sub_parameter_id;
        data.sub_tags = sub_tag_id;
    }
    let url = curatorInsertTagUrl;
    return axios.post(url,data,config)
}
export function curatorGetTag(post_id){
    let config = Configuration();
    let url = curatorgetTagUrl;
    const data =  {
        "post_id": post_id
    }
    config.params = data;
    return axios.get(url,config);
}
export function curatorDeleteTag(post_id,tag_id,parameter_id, sub_parameter_id, sub_tag_id){
    let config = Configuration();
    let url = curatorDeleteTagUrl;
    let data = {
        "post_id":post_id,
        "tag_id":tag_id
    }
    if(sub_parameter_id)
    {
        data.sub_parameter_id = sub_parameter_id;
        data.sub_tag_id = sub_tag_id;
    }
    config.params = data;  
    return axios.get(url,config);
}
export function curatorUpdateDescription(post_id,description){
    let config = Configuration();
    let data = {
        "post_id"   :   post_id,
        "description" :   description
    };
    let url = curatorUpdateDescriptionUrl;
    return axios.post(url,data,config)
}
export function curatorFinishCuration(post_id,parameters, open_items, status, description, skip_description, post_edit_id ){
    let config = Configuration();
    let data = {
            "post_id"   :   post_id,
            "parameters":   parameters,
            "status"    :   status,
            "description":  description,
            "skip_reason":  skip_description,
            "post_edit_id": post_edit_id
        };
        if(open_items.length > 0){
            data.open_items = open_items
        }
    let url = curatorFinishCurationUrl;
    return axios.post(url,data,config);
}
// export function curatorFinishCuration(post_id,is_complete,skip_reason,tags, sub_tags, description="",open_items="", action){
//     let data = {
//         "post_id"   :   post_id,
//         "is_complete" :   is_complete,
//         "action": action
//     };
//     if(tags.length > 0)
//     {
//         data.tags = tags
//     }
//     if(is_complete === 0)
//     {
//         data.skip_reason = skip_reason;
//     }
//     if(description !== "")
//     {
//         data.description = description;
//     }
//     // if(is_wasted){
//     //     data.is_wasted = is_wasted;
//     // }
//     let url = curatorFinishCurationUrl;
//     const token = getStorageToken();
//     const config = {
//         headers: { 'Authorization': `Bearer ${token}`,'Accept': 'application/json' },
//     };   
//     return axios.post(url,data,config);
// }
export function curatorGetPostCompleted(){
    let config = Configuration();
    let url = curatorGetPostCompletedUrl;
    return axios.get(url,config);
}
export function curatorPostHide(post_id){
    let config = Configuration();
    let url = curatorPostHideUrl;
    const data = {
        "post_id": post_id
    }
    config.params = data
    return axios.get(url,config);
}
export function curatorSearchSubTag(search, parameter_id, tag_id){
    let config = Configuration();
    let url = curatorTagSearchSubUrl;
    let data = {
        parameter_id: parameter_id,
        search: search,
        tag_id: tag_id
    }
    config.params = data;
    return axios.get(url,config)
}