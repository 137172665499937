import axios from 'axios';
import { Configuration, CoreUrl } from "./Url";

const mccUrl = CoreUrl()+'general/mcc/list/all';
const nationalityUrl = CoreUrl()+'general/nationality/list/all';


export function getMcc(){
  let url = mccUrl;
  return axios.get(url,Configuration());
}
export function getNationality(){
  let url = nationalityUrl;
  return axios.get(url,Configuration());
}