import axios from 'axios';
import { Configuration, CoreUrl } from "./Url";

const profileUrl = CoreUrl()+'user/profile';
const updateProfileUrl = CoreUrl()+'user/profile/update';


export function ProfileApi(data=""){
    let url = profileUrl;
  return axios.get(url,Configuration());
}

export function UpdateUserProfle(data=""){
  let url = updateProfileUrl;
return axios.post(url,data,Configuration());
}