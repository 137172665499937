import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import Home from '../views/curator';
import HomeModerator from '../views/moderator/moderator';
import HomeUserAccounts from '../views/useraccounts/users';
import HomeLeaders from '../views/leaders/leaders';
import HomeHubOfficers from '../views/hub-officers/hub-officers';
import HomeBooks from '../views/books/books';
import Calendar from '../views/calendar/calendar';
import Features from '../views/features/features';
import Login from '../authentication/login';
import QA from '../views/qa/qa';
import MainLanding from '../views/landing';
import FaithHub from '../views/faithhub/faithhub';
import UserDetails from '../Components/user-details';
import LoginWelcome from '../authentication/login-welcome';
import { adminUserStatusList, getLanguageList } from '../Api/GeneralApi';
import Header from './header';

import { useGeolocated } from "react-geolocated";
// import Geocode from "react-geocode";
import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from "react-geocode";
// import { geocode, RequestType } from "react-geocode";
import { GoogleApiKey } from '../Api/Url';
import { ProfileApi } from '../Api/ApiCore';
import LanWelcome from '../views/lanwelcome';

const BaseLayouts = () => {
    const [languageLists, setLanguageLists] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [breakContents, setBreakContents] = useState([]);
    const [isShowHeader, setIsShowHeader] = useState(false);
    const [location, setLocation] = useState(null)
    const [address, setAddress] = useState(null);
    const [profile, setProfile] = useState(null);
    const [pathname, setPathname] = useState(window.location.pathname);
    const [isLocationEnabled, setLocationEnabled] = useState(true);

    const [mainLoading, setMainLoading] = useState(true);
    
  useEffect(() => {
    // Check if geolocation is available
    if (!navigator.geolocation) {
      setLocationEnabled(false);
      return;
    }

    // Function to check permission status
    const checkPermissionStatus = () => {
      navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
        if (permissionStatus.state === 'granted') {
          setLocationEnabled(true);
        } else {
          setLocationEnabled(false);
        }
      });
    };
    checkPermissionStatus();
    const intervalId = setInterval(checkPermissionStatus, 1000);
    return () => clearInterval(intervalId);
  }, []);

    function fetchLocation(pathnameValue) {
        if ((pathnameValue !== '/' && pathnameValue !== "/login") && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    });
                },
                (error) => {
                setLocation(null);
                }
            );
        }
    }
    useEffect(() => {
            fetchLocation(pathname);
      }, [pathname, isLocationEnabled]);
      useEffect(()=>{
            if(location){
                geocode(RequestType.LATLNG, location.latitude + "," + location.longitude)
                .then(({ results }) => {
                    if (results.length > 0) {
                        const addressComponents = results[0].address_components;
                        let city, state, country;
                        for (const component of addressComponents) {
                            if (component.types.includes("locality")) {
                            city = component.long_name;
                            } else if (component.types.includes("administrative_area_level_1")) {
                            state = component.long_name;
                            } else if (component.types.includes("country")) {
                            country = component.long_name;
                            }
                        }
                        setAddress({city: city, state: state, country: country} )
                    } else {
                    console.log("No results found");
                    }
                })
                .catch(console.error);
            }
        },[location])

    useEffect(()=>{
        getLanguageList().then((res)=>{
            setLanguageLists(res.data.data)
        }).catch((err)=>{
    
        })
    },[])
    
    useEffect(() => {
        const checkTime = () => {
            const now = new Date();
            const currentHour = now.getHours();
            const currentMinute = now.getMinutes();
            if ((currentHour === 8 && currentMinute >= 0 && currentMinute < 15) ||
                (currentHour === 0 && currentMinute >= 0 && currentMinute < 15)) {
                setShowLoading(true);
            } else {
                setShowLoading(false);
            }
        };
        checkTime();
        const intervalId = setInterval(checkTime, 1000 * 60);    
        return () => {
            clearInterval(intervalId);
        };
    }, []);
    // useEffect(()=>{
    //     adminUserStatusList(1).then((res)=>{
    //         setBreakContents(res.data.data);
    //     }).catch((err)=>{

    //     })
    // },[])
    // const isHeaderVisible = () => {
    //     const hiddenRoutes = ['/landing', '/auth', '/login'];
    //     return !hiddenRoutes.includes(window.location.pathname);
    //   };
    useEffect(()=>{
        ProfileApi().then((res) => {
            setProfile(res.data.data);
        }).catch((err) => {
            setProfile(null);
        })
    },[])
    return (
        <>
            <React.Fragment>
                <BrowserRouter>
                {isShowHeader && <Header breakContents={breakContents} address={address} profile={profile} setPathname={setPathname} latlong={location} setBreakContents={setBreakContents} />}
                    <Routes>
                        <Route path='/' element={ <LanWelcome setIsShowHeader={setIsShowHeader} profile={profile} setPathname={setPathname}/> } />
                        <Route path='login' element={<Login setIsShowHeader={setIsShowHeader} profile={profile} setPathname={setPathname}/>} />
                        <Route path='/u/:authid/auth' element={<LoginWelcome setIsShowHeader={setIsShowHeader} address={address} setPathname={setPathname} location={location}/>} />
                        <Route path='/u/:authid/curator' element={showLoading ? <MainLanding /> : <Home setIsShowHeader={setIsShowHeader} setShowLoading={setShowLoading}/>} />
                        <Route path='/u/:authid/moderator' element={showLoading ? <MainLanding /> :<HomeModerator setIsShowHeader={setIsShowHeader}/>} />
                        <Route path='/u/:authid/users' element={<HomeUserAccounts setIsShowHeader={setIsShowHeader}/>} />
                        <Route path='/u/:authid/leader' element={<HomeLeaders setIsShowHeader={setIsShowHeader}/>} />
                        <Route path='/u/:authid/officer' element={<HomeHubOfficers  allLanguageLists={languageLists} setIsShowHeader={setIsShowHeader}/>} />
                        <Route path='/u/:authid/book' element={<HomeBooks setIsShowHeader={setIsShowHeader}/>} />
                        <Route path='/u/:authid/calendar' element={<Calendar setIsShowHeader={setIsShowHeader}/>} />
                        <Route path='/u/:authid/features' element={<Features setIsShowHeader={setIsShowHeader}/>} />
                        <Route path='/u/:authid/faithhub' element={<FaithHub setIsShowHeader={setIsShowHeader}/>} />
                        <Route path='/u/:authid/user-details' element={<UserDetails allLanguageLists={languageLists}/>} />
                        <Route path='/u/:authid/qa' element={<QA  setIsShowHeader={setIsShowHeader}/>} />
                        <Route path='/u/:authid/lanwelcome' element={<LanWelcome/>}/> 
                    </Routes>
                </BrowserRouter>
            </React.Fragment>
        </>
    );
}
export default BaseLayouts;