export function getAuthIdFromUrl() {
    console.log(window.location.pathname);
    const path = window.location.pathname;
    const parts = path.split('/');
    const authid = parts[2];
    return authid;
}
function domain(){
    return process.env.REACT_APP_API_URL;
}
function publicDomain()
{
    return process.env.REACT_APP_PUBLIC_URL;
}
export function CoreUrl(){
    return domain()+"/api/v1.0/app/core/";
}
export function CoreImageUrl(){
    // return "https://ev.public.heptotechnologies.org/core/";
    return publicDomain()+"/core/";
}
export function CuratorUrl(){
    return domain()+"/api/v1.0/app/hub/admin/curator/";
}
export function HubOfficerUrl(){
    return  domain()+"/api/v1.0/app/hub/admin/hub-officer/";
}
export function ModeratorUrl(){
    return  domain()+"/api/v1.0/app/hub/admin/moderator/";
}
export function DesignerUrl(){
    return  domain()+"/api/v1.0/app/hub/admin/designer/";
}
export function qa(){
    return  domain()+"/api/v1.0/app/hub/admin/qa/";
}
export function AdminUrl(){
    return  domain()+"api/v1.0/app/hub/admin/";
}
export function ImageUrl(){
    return publicDomain()+"/";
}
export function UserUrl(){
    return  domain()+"/api/v1.0/app/hub/user/";
}
export function GeneralUrl(){
    return  domain()+"/api/v1.0/app/hub/general/"
}
export function GoogleApiKey(){
    // return "AIzaSyBCCR-G5jmWLLIbhKRzlU_JgoVXLheTNtQ";
    return "AIzaSyCg5qv9YV0LE6pbDnI8d3qxkBNWNQ2BMxc";
}
export function Configuration(){
    // return  {
    //     headers: { 'Accept': 'application/json', "X-Authuser" : getAuthIdFromUrl() },
    //     withCredentials: true,
    // };  
     return  {
        headers: { 'Accept': 'application/json', "X-Authuser" : getAuthIdFromUrl(), "X-Request-Agent":"APP", "X-SID":"sid_v2zbYpCrUbkiuQrAbjaYdG", "X-MUID":"mut_Inmd0TG4hsJutSpFdHISbiMZp" }
     };
}
