import React from 'react'

const LeftColorPane = (props) => {
    return (
        <div className='left-pane'>
            <ul>
                <li className='bg-031828'>
                    <a href="#" className="text-white">

                        <span className="menu-text">Target</span><span className="badge white-bg text-dark menuItem">{props.allTargetCounts.target}</span>
                    </a>
                </li>
                <li className='bg-0B2838'>
                    <a href="#" className="text-white">

                        <span className="menu-text">Achievement</span><span className="badge white-bg text-dark menuItem">{props.allTargetCounts.achievement}</span>
                    </a>
                </li>
                <li className='bg-083248'>
                    <a href="#" className="text-white">

                        <span className="menu-text">Extra</span><span className="badge white-bg text-dark menuItem">{props.allTargetCounts.extra}</span>
                    </a>
                </li>
                <li className='bg-880c0c'>
                    <a href="#" className="text-white">

                        <span className="menu-text">Skipped</span><span className="badge white-bg text-dark menuItem">{props.allTargetCounts.skipped}</span>
                    </a>
                </li>
                <li className='bg-dd952f'>
                    <a href="#" className="text-white">

                        <span className="menu-text">Wasted</span><span className="badge white-bg text-dark menuItem">{props.allTargetCounts.wasted}</span>
                    </a>
                </li>
                <li className="mt-20 menu-title">
                    <span>{window.location.pathname == "/curator" ? "Curated" : "Moderated"} posts</span>
                </li>
            </ul>
        </div>
    );
}
export default LeftColorPane;