import React, { useEffect, useState } from 'react'
import Header from '../../layouts/header';
import Footer from '../../layouts/footer';
import VideoEditor from '../../Components/video-editor';
import getCroppedImg from './cropImage';
import { convertBlobToBase64, convertMp3ToBase64 } from './convertToBase64';
import { deleteAttachment, getAttachments, uploadAttachment } from '../../Api/ApiDesigner';
import { ImageUrl } from '../../Api/Url';



const Features = ({setIsShowHeader}) => {
    useEffect(()=>{
        setIsShowHeader(true);
    },[])
    const [croppedImage, setCroppedImage] = useState(null);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [completedDesigns, setCompletedDesigns] = useState([])
    const [selectedFont, setSelectedFont] = useState('Arial');
    const [textStyle, setTextStyle] = useState({position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', maxWidth: '10%', fontFamily: 'Arial'});
    const [attachment, setAttachment] = useState(null);
    const [text, setText] = useState("Your Text Here");
    const [file, setFile] = useState(null);
    const [type, setType] = useState('image');

    const showCroppedImage = async () => {
        try {
            let out = null;
            if(type == "image"){
                const croppedImage = await getCroppedImg(
                    attachment,
                    croppedAreaPixels,
                )
                const reader = new FileReader();
                const response = await fetch(croppedImage);
                const blob = await response.blob();
                out=  await convertBlobToBase64(blob);
            }
            if(type == "mp3"){
                out = await convertMp3ToBase64(file);
            }
            if(out){
                uploadAttachment(out, text, type).then((res)=>{
                    setCroppedImage(croppedImage)
                }).catch((err)=>{
                    console.log(err);
                });
            }
        } catch (e) {
          console.error(e)
        }
    }

    const handleFontChange = (e) => {
    //   setSelectedFont(e.target.value);
      setTextStyle((prev)=>({...prev,[e.target.name]:e.target.value}))
    };
    useEffect(() => {
        getAttachments().then((res)=>{
            setCompletedDesigns(res.data.data);
        }).catch((err)=>{
            console.log(err);
        })
    }, [croppedImage])
    
    
    
    const handleImageChange = (e) => {
        setFile(e.target.files[0]);
        const reader = new FileReader();
    
        reader.onloadend = () => {
            setAttachment(reader.result);
        };
    
        if (e.target.files[0]) {
          reader.readAsDataURL(e.target.files[0]);
        }
      };
    function handleChange(e){
        setText(e.target.value)
    }
    function deleteDesigns(id){
        deleteAttachment(id).then((res)=>{
            setCompletedDesigns(prev => prev.filter(item => item.id !== id));
        }).catch((err)=>{

        })
    }

    return (
        <>
            {/* <Header></Header> */}
            <main className="main-content">
                <div className="contents no-left-pane">
                    <div className="container-fluid mt-20">
                        <div className='row'>
                            <div className='col-lg-3 col-md-3 col-sm-12'>
                                <div className='card'>
                                    <div className="row">
                                        <div className='card-header col-md-6'><h6>Gallery</h6></div>
                                        <div className='card-header col-md-6'><input type="file" name="" id="" onChange={handleImageChange}/></div>
                                        
                                    </div>
                                    {/* <img src={image} alt="Uploaded" style={{ maxWidth: '100%', maxHeight: '300px' }} /> */}
                                    
                                    <div className='card-body'>
                                        <div className="tab-wrapper">
                                            <div className="atbd-tab tab-horizontal">
                                                <ul className="nav nav-tabs vertical-tabs" role="tablist">
                                                    <li className="nav-item" onClick={()=>setType('image')}> <a className="nav-link active" id="tab-v-1-tab" data-toggle="tab" href="#tab-v-1" role="tab" aria-controls="tab-v-1" aria-selected="true">Wallpaper</a> </li>
                                                    <li className="nav-item" onClick={()=>setType('video')}> <a className="nav-link" id="tab-v-2-tab" data-toggle="tab" href="#tab-v-2" role="tab" aria-controls="tab-v-2" aria-selected="false">Silent Video</a> </li>
                                                    <li className="nav-item" onClick={()=>setType('video')}> <a className="nav-link" id="tab-v-3-tab" data-toggle="tab" href="#tab-v-3" role="tab" aria-controls="tab-v-3" aria-selected="false">Sound Video</a> </li>
                                                </ul>
                                                <div className="tab-content">
                                                    <div className="tab-pane fade show active" id="tab-v-1" role="tabpanel" aria-labelledby="tab-v-1-tab">
                                                        <div className="table4 bg-white mb-30">
                                                            <div className="product-cart mb-sm-0 mb-20 wallpaper-height">
                                                                <div className="table-responsive">
                                                                    <table className="table table-borderless table-hover">
                                                                        <tbody>
                                                                            {completedDesigns?.map((cd, key)=>{return(
                                                                            <tr key={key}>
                                                                                <td className="Product-cart-title" >
                                                                                    <div className="media align-items-center">
                                                                                        <img className="mr-3 wh-50 align-self-center radius-xl bg-opacity-primary" src={ImageUrl()+cd.attachment} alt="" />
                                                                                    </div>
                                                                                </td>

                                                                                <td className="actions">
                                                                                    <button type="button" className="action-btn float-right" onClick={()=>deleteDesigns(cd.id)}>
                                                                                        <i className="las la-trash-alt"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>)})}
                                                                            {/* <tr>
                                                                                <td className="Product-cart-title">
                                                                                    <div className="media align-items-center">
                                                                                        <img className="mr-3 wh-60 align-self-center radius-xl bg-opacity-primary" src="/assets/img/cart1.png" alt="" />
                                                                                    </div>
                                                                                </td>

                                                                                <td className="actions">
                                                                                    <button type="button" className="action-btn float-right">
                                                                                        <i className="las la-trash-alt"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="Product-cart-title">
                                                                                    <div className="media align-items-center">
                                                                                        <img className="mr-3 wh-60 align-self-center radius-xl bg-opacity-primary" src="/assets/img/cart1.png" alt="" />
                                                                                    </div>
                                                                                </td>

                                                                                <td className="actions">
                                                                                    <button type="button" className="action-btn float-right">
                                                                                        <i className="las la-trash-alt"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="Product-cart-title">
                                                                                    <div className="media align-items-center">
                                                                                        <img className="mr-3 wh-60 align-self-center radius-xl bg-opacity-primary" src="/assets/img/cart1.png" alt="" />
                                                                                    </div>
                                                                                </td>

                                                                                <td className="actions">
                                                                                    <button type="button" className="action-btn float-right">
                                                                                        <i className="las la-trash-alt"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="Product-cart-title">
                                                                                    <div className="media align-items-center">
                                                                                        <img className="mr-3 wh-60 align-self-center radius-xl bg-opacity-primary" src="/assets/img/cart1.png" alt="" />
                                                                                    </div>
                                                                                </td>

                                                                                <td className="actions">
                                                                                    <button type="button" className="action-btn float-right">
                                                                                        <i className="las la-trash-alt"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="Product-cart-title">
                                                                                    <div className="media align-items-center">
                                                                                        <img className="mr-3 wh-60 align-self-center radius-xl bg-opacity-primary" src="/assets/img/cart1.png" alt="" />
                                                                                    </div>
                                                                                </td>

                                                                                <td className="actions">
                                                                                    <button type="button" className="action-btn float-right">
                                                                                        <i className="las la-trash-alt"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr> */}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="tab-v-2" role="tabpanel" aria-labelledby="tab-v-2-tab">
                                                        <div className="table4 bg-white mb-30">
                                                            <div className="product-cart mb-sm-0 mb-20 wallpaper-height">
                                                                <div className="table-responsive">
                                                                    <table className="table table-borderless table-hover">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="Product-cart-title">
                                                                                    <div className="media align-items-center">
                                                                                        <img className="mr-3 wh-50 align-self-center radius-xl bg-opacity-primary" src="/assets/img/design.png" alt="" />
                                                                                    </div>
                                                                                </td>

                                                                                <td className="actions">
                                                                                    <button type="button" className="action-btn float-right">
                                                                                        <i className="las la-trash-alt"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="Product-cart-title">
                                                                                    <div className="media align-items-center">
                                                                                        <img className="mr-3 wh-50 align-self-center radius-xl bg-opacity-primary" src="/assets/img/design.png" alt="" />
                                                                                    </div>
                                                                                </td>

                                                                                <td className="actions">
                                                                                    <button type="button" className="action-btn float-right">
                                                                                        <i className="las la-trash-alt"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="Product-cart-title">
                                                                                    <div className="media align-items-center">
                                                                                        <img className="mr-3 wh-50 align-self-center radius-xl bg-opacity-primary" src="/assets/img/design.png" alt="" />
                                                                                    </div>
                                                                                </td>

                                                                                <td className="actions">
                                                                                    <button type="button" className="action-btn float-right">
                                                                                        <i className="las la-trash-alt"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="Product-cart-title">
                                                                                    <div className="media align-items-center">
                                                                                        <img className="mr-3 wh-50 align-self-center radius-xl bg-opacity-primary" src="/assets/img/design.png" alt="" />
                                                                                    </div>
                                                                                </td>

                                                                                <td className="actions">
                                                                                    <button type="button" className="action-btn float-right">
                                                                                        <i className="las la-trash-alt"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="Product-cart-title">
                                                                                    <div className="media align-items-center">
                                                                                        <img className="mr-3 wh-50 align-self-center radius-xl bg-opacity-primary" src="/assets/img/design.png" alt="" />
                                                                                    </div>
                                                                                </td>

                                                                                <td className="actions">
                                                                                    <button type="button" className="action-btn float-right">
                                                                                        <i className="las la-trash-alt"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="tab-v-3" role="tabpanel" aria-labelledby="tab-v-3-tab">
                                                        <div className="table4 bg-white mb-30">
                                                            <div className="product-cart mb-sm-0 mb-20 wallpaper-height">
                                                                <div className="table-responsive">
                                                                    <table className="table table-borderless table-hover">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="Product-cart-title">
                                                                                    <div className="media align-items-center">
                                                                                        <img className="mr-3 wh-50 align-self-center radius-xl bg-opacity-primary" src="/assets/img/food-restaurant-media.png" alt="" />
                                                                                    </div>
                                                                                </td>

                                                                                <td className="actions">
                                                                                    <button type="button" className="action-btn float-right">
                                                                                        <i className="las la-trash-alt"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="Product-cart-title">
                                                                                    <div className="media align-items-center">
                                                                                        <img className="mr-3 wh-50 align-self-center radius-xl bg-opacity-primary" src="/assets/img/food-restaurant-media.png" alt="" />
                                                                                    </div>
                                                                                </td>

                                                                                <td className="actions">
                                                                                    <button type="button" className="action-btn float-right">
                                                                                        <i className="las la-trash-alt"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="Product-cart-title">
                                                                                    <div className="media align-items-center">
                                                                                        <img className="mr-3 wh-50 align-self-center radius-xl bg-opacity-primary" src="/assets/img/food-restaurant-media.png" alt="" />
                                                                                    </div>
                                                                                </td>

                                                                                <td className="actions">
                                                                                    <button type="button" className="action-btn float-right">
                                                                                        <i className="las la-trash-alt"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="Product-cart-title">
                                                                                    <div className="media align-items-center">
                                                                                        <img className="mr-3 wh-50 align-self-center radius-xl bg-opacity-primary" src="/assets/img/food-restaurant-media.png" alt="" />
                                                                                    </div>
                                                                                </td>

                                                                                <td className="actions">
                                                                                    <button type="button" className="action-btn float-right">
                                                                                        <i className="las la-trash-alt"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="Product-cart-title">
                                                                                    <div className="media align-items-center">
                                                                                        <img className="mr-3 wh-50 align-self-center radius-xl bg-opacity-primary" src="/assets/img/food-restaurant-media.png" alt="" />
                                                                                    </div>
                                                                                </td>

                                                                                <td className="actions">
                                                                                    <button type="button" className="action-btn float-right">
                                                                                        <i className="las la-trash-alt"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="Product-cart-title">
                                                                                    <div className="media align-items-center">
                                                                                        <img className="mr-3 wh-50 align-self-center radius-xl bg-opacity-primary" src="/assets/img/food-restaurant-media.png" alt="" />
                                                                                    </div>
                                                                                </td>

                                                                                <td className="actions">
                                                                                    <button type="button" className="action-btn float-right">
                                                                                        <i className="las la-trash-alt"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="Product-cart-title">
                                                                                    <div className="media align-items-center">
                                                                                        <img className="mr-3 wh-50 align-self-center radius-xl bg-opacity-primary" src="/assets/img/food-restaurant-media.png" alt="" />
                                                                                    </div>
                                                                                </td>

                                                                                <td className="actions">
                                                                                    <button type="button" className="action-btn float-right">
                                                                                        <i className="las la-trash-alt"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="Product-cart-title">
                                                                                    <div className="media align-items-center">
                                                                                        <img className="mr-3 wh-50 align-self-center radius-xl bg-opacity-primary" src="/assets/img/food-restaurant-media.png" alt="" />
                                                                                    </div>
                                                                                </td>

                                                                                <td className="actions">
                                                                                    <button type="button" className="action-btn float-right">
                                                                                        <i className="las la-trash-alt"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-9 col-md-9 col-sm-12'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="card">
                                            <div className="card-header color-dark fw-500">
                                                <h6>Preview</h6>
                                            </div>
                                            <div className="card-body p-0 video-height">
                                                
                                               <VideoEditor attachment={attachment} text={text} setCroppedAreaPixels={setCroppedAreaPixels} croppedImage={croppedImage} type={type} textStyle={textStyle}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className="card mt-20">
                                            <div className="card-header color-dark fw-500">
                                                <h6>Test Message</h6>
                                            </div>
                                            <div className="card-body p-0 text-editor-height">
                                                <textarea className="content" name="example" onChange={handleChange}></textarea>
                                            </div>
                                            <div>
                                        <select value={textStyle.fontFamily} onChange={handleFontChange} name="fontFamily">
                                        <option value="Arial">Arial</option>
                                            <option value="Verdana" style={{fontFamily: "Verdana"}}>Verdana</option>
                                            <option value="Helvetica" style={{fontFamily: "Helvetica"}}>Helvetica</option>
                                            <option value="Times New Roman" style={{fontFamily: "Times New Roman"}}>Times New Roman</option>
                                            <option value="Comic Sans MS" style={{fontFamily: "Comic Sans MS"}}>Comic Sans MS</option>
                                            <option value="Courier New" style={{fontFamily: "Courier New"}}>Courier New</option>
                                            <option value="Georgia" style={{fontFamily: "Georgia"}}>Georgia</option>
                                            <option value="Palatino" style={{fontFamily: "Palatino"}}>Palatino</option>
                                            <option value="Tahoma" style={{fontFamily: "Tahoma"}}>Tahoma</option>
                                            <option value="Trebuchet MS" style={{fontFamily: "Trebuchet MS"}}>Trebuchet MS</option>
                                            <option value="Impact" style={{fontFamily: "Impact"}}>Impact</option>
                                        </select>
                                        {/* <select name="" id="" className='atbd-select'>
                                            <option value="">color 1</option>
                                            <option value="">color 1</option>
                                            <option value="">color 1</option>
                                            <option value="">color 1</option>
                                        </select> */}
                                        <button onClick={()=>setTextStyle((prev)=>({...prev, fontWeight: prev.fontWeight === "bold" ? "normal" : "bold"}))}><i className="las la-bold"></i></button>
                                        <button onClick={()=>setTextStyle((prev)=>({...prev, fontStyle: prev.fontStyle === "italic" ? "normal" : "italic"}))} ><i className="las la-italic"></i></button>
                                        <button onClick={()=>setTextStyle((prev)=>({...prev, textDecoration: prev.textDecoration == "underline" ? "none" : "underline"}))} ><i className="las la-underline"></i></button>
                                        <button onClick={()=>setTextStyle((prev)=>({...prev, textAlign: prev.textAlign == "right" ? "left" : prev.textAlign == "center" ? "right" : "center" }))} >
                                            <i className="las la-align-center"></i></button>
                                        </div>
                                        <div>
                                        

                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className="card mt-20">
                                            <div className="card-header color-dark fw-500">
                                                <h6>Design Spec</h6>
                                            </div>
                                            <div className="card-body p-0 design-spec-height">
                                                <div className='table-responsive'>
                                                    <table className='table'>
                                                    {file && 
                                                        <tbody>
                                                            <tr>
                                                                
                                                                <th scope="row">Name</th>
                                                                <td>{file.name}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Size</th>
                                                                <td>{file.size}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Font-Type</th>
                                                                <td>Arial</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Font-Size</th>
                                                                <td>12px</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Font Color</th>
                                                                <td>Blue</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Bold</th>
                                                                <td>Yes</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Italics</th>
                                                                <td>Yes</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Underline</th>
                                                                <td>No</td>
                                                            </tr>
                                                        </tbody>}
                                                    </table>
                                                </div>
                                            </div>
                                            <div className='card-footer justify-content-end'><button className='btn btn-success' onClick={showCroppedImage}>Save</button></div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
                <div className="modal-basic modal fade show" id="modal-basic" tabindex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content modal-bg-white ">
                            <div className="modal-header">
                                <h6 className="modal-title">Add Features</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span data-feather="x"></span></button>
                            </div>
                            <div className="modal-body">
                                <div className='pop-up-height'>
                                    <table className='table m-0'>
                                        <tr>
                                            <td>Religion</td>
                                            <td><div className="atbd-select ">
                                                <select className="form-control" >
                                                    <option value="01">Islam</option>
                                                    <option value="02">Hindu</option>
                                                </select>
                                            </div></td>
                                        </tr>
                                        <tr>
                                            <td>Denomination</td>
                                            <td><div className="atbd-select ">
                                                <select className="form-control" >
                                                    <option value="01">Christianity</option>
                                                    <option value="02">Islam</option>
                                                    <option value="03">Hindu</option>
                                                </select>
                                            </div></td>
                                        </tr>
                                        <tr>
                                            <td>Country</td>
                                            <td> <div className="atbd-select ">
                                                <select className="form-control" >
                                                    <option value="01">Eng</option>
                                                    <option value="02">China</option>
                                                    <option value="03">Eng</option>
                                                </select>
                                            </div></td>
                                        </tr>
                                        <tr>
                                            <td>Upload Design</td>
                                            <td><div className="atbd-upload">
                                                <div className="atbd-upload-avatar media-import dropzone-md-s">
                                                    <p className="color-light mt-0 fs-14">Drop files here to upload</p>
                                                </div>
                                                <div className="avatar-up">
                                                    <input type="file" name="upload-avatar-input" className="upload-avatar-input" />
                                                </div>
                                            </div></td>
                                        </tr>
                                        <tr>
                                            <td>Inspiration posts</td>
                                            <td><input type="text" className="form-control ih-small" id="" placeholder="Inspiration posts" /></td>
                                        </tr>
                                        <tr>
                                            <td>Ministry Posts</td>
                                            <td><input type="text" className="form-control ih-small" id="" placeholder="Inspiration posts" /></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-success btn-sm">Save changes</button>
                                <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </main >

        </>
    );
}
export default Features;