import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route, useNavigate, useParams } from 'react-router-dom';
import Header from '../../layouts/header';
import Aside from '../../layouts/aside';
import Footer from '../../layouts/footer';
import { moderatorDeleteTag, moderatorGetPostItemAnswer, moderatorGetTag, moderatorInsertTag, moderatorParameter, moderatorPostOpenItem, moderatorQuestion, moderatorSearchTag, moderatorTarget } from '../../Api/ApiModerator';
import Post from '../../Components/Post';
import { EachWordCapitalize, FilterParameterTags } from '../../Api/CommanJsFunction';
import Tags from '../../Components/Tags';
import { getDailyTrack } from '../..//Api/GeneralApi';
import useGetDailyTrack from '../../CustomHooks/useGetDailyTrack';
import MediLoader from '../../Components/medi-loading';

const HomeModerator = ({ setIsShowHeader }) => {
    const { authid } = useParams();

    const redirect = useNavigate();

    const [targets, setTargets] = useState();
    const [postNextPage, setPostNextPage] = useState(1);
    const [postDescription, setPostDescription] = useState();
    const [questions, setQuestions] = useState([]);
    const [parameters, setParameters] = useState([]);
    const [postTags, setPostTags] = useState({})
    const [recommendTags, setRecommendTags] = useState();
    const [parameterTags, setParameterTags] = useState([]);
    const [parameterSubTags, setParameterSubTags] = useState([]);
    const [openItemAnswer, setOpenItemAnswer] = useState([]);
    const [editPostId, setEditPostId] = useState();
    const [isShowModeratorTags, setIsShowModeratorTags] = useState(false);
    const [IsTagDeleteCall, setIsTagDeleteCall] = useState(0);
    const [allTargetCounts, setAllTargetCounts] = useState({});
    const [subParameters, setSubParameters] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);


    const [isLoading1, setIsLoading1] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [isLoading3, setIsLoading3] = useState(false);
    
    const [moderatorgetPostCompletedLoading, setModeratorGetPostCompletedLoading] = useState(false)
    const [getTagLoading, SetGetTagLoading] = useState(false);
    const [postHideLoading, setPostHideLoading] = useState(false)

    const [finishTargetLoading, setFinishTargetLoading] = useState(false)
    const [searchSubTagLoading, setSearchSubTagLoading] = useState(false)

    useEffect(() => {
        setIsShowHeader(true);
        setIsLoading1(true);
        moderatorTarget(postNextPage, '', editPostId).then((res) => {
            setTargets(res.data.data);
            setIsLoading1(false);
            // setAllTargetCounts({total_target:res.data.data.total_target,completed_target:res.data.data.completed_target,skipped_target:res.data.data.skipped_target});
        }).catch((err) => {
            if (err.response.status == 402) {
                redirect(`/u/${authid}/user-details`, {
                    state: {
                        // for:err.response.data.data.name
                        //   errorMessage: err.response.data.message,
                    },
                });
            }
            if (err.response.status == 422) {
                redirect(`/u/${authid}/auth`, {
                    state: {
                        // for:err.response.data.data
                        //   errorMessage: err.response.data.message,
                    },
                });
            }
            if (err.response.status == 401) {
                redirect('/');
            }
            console.error('Request failed:', err.response);
        })
    }, [postNextPage]);
    useEffect(() => {
        setIsLoading2(true);
        moderatorQuestion().then((res) => {
            setQuestions(res.data.data);
            setIsLoading2(false);
        }).catch((err) => {
            setIsLoading2(false);
        });
    }, [])
    useEffect(() => {
        if (parameters.length == 0) {
        setIsLoading3(true);
            moderatorParameter().then((res) => {
                const modifiedData = res.data.data.map(item => {
                    const modifiedParameterTag = item.parameter_tag.map(paramTag => ({
                        value: paramTag.tag.id,
                        label: paramTag.tag.name,
                        sub_tags: paramTag.tag.sub_tags.map(subTag => ({
                            value: subTag.id,
                            label: subTag.name,
                            // Add additional properties as needed
                        })),
                    }));
                    return {
                        id: item.id,
                        name: item.name,
                        for: item.for,
                        is_sub_tag: item.is_sub_tag,
                        status: item.status,
                        parameter_tag: modifiedParameterTag,
                    };
                });
                localStorage.setItem('moderator', JSON.stringify(modifiedData))
                setParameters(modifiedData);
                setIsLoading3(false);
            }).catch((err) => {
                setParameters(JSON.parse(localStorage.getItem('moderator')));
                setIsLoading3(false);
            })
        } else {
            setIsLoading3(false);
        }
    }, [postNextPage])
    useGetDailyTrack(setAllTargetCounts);
    function getAnswer(e, id, validation = null) {
        if (validation) {
            if (validation === 'numeric' && isNaN(e.target.value)) {
                return false;
            }
        }
        setOpenItemAnswer(prev => {
            const existingItemIndex = prev.findIndex(item => item.open_item_id === id);

            if (e.target.value === '') {
                if (existingItemIndex !== -1) {
                    const updatedItems = prev.filter(item => item.open_item_id !== id);
                    return updatedItems;
                } else {
                    return prev;
                }
            } else {
                if (existingItemIndex !== -1) {
                    const updatedItems = [...prev];
                    updatedItems[existingItemIndex] = {
                        ...updatedItems[existingItemIndex],
                        value: e.target.value,
                        post_id: targets.values[0].id
                    };
                    return updatedItems;
                } else {
                    return [
                        ...prev,
                        { open_item_id: id, value: e.target.value, post_id: targets.values[0].id }
                    ];
                }
            }
        });
    }
    // function openItemSubmit() {
    //     if (openItemAnswer.length > 0) {
    //         setIsShowModeratorTags(true);
    //         // moderatorPostOpenItem(openItemAnswer).then((res)=>{
    //         //     setIsShowModeratorTags(true);
    //         // }).catch((err)=>{

    //         // })
    //     }
    //     // else {
    //     //     alert("Enter Open Item Answer")
    //     // }
    // }
    // function handleRevertBtn() {
    //     setOpenItemAnswer([]);
    //     setSelectedTags([]);
    //     setIsShowModeratorTags(false);
    // }
    const mainLoading = isLoading1 || isLoading2 || isLoading3 || getTagLoading || postHideLoading || finishTargetLoading || searchSubTagLoading ;
    return (
        <>
            {/* <Header></Header> */}
            {mainLoading ? <MediLoader /> :
            <main className="main-content main-top">
                <div className="">
                    <div className="container-fluid mt-20">
                        <div className='row'>
                            <Aside targets={targets} allTargetCounts={allTargetCounts} setEditPostId={setEditPostId} content="moderator" setTargets={setTargets} setIsShowModeratorTags={setIsShowModeratorTags} setParameterTags={setParameterTags} setParameterSubTags={setParameterSubTags} parameterSubTags={parameterSubTags} setSubParameters={setSubParameters} subParameters={subParameters} setOpenItemAnswer={setOpenItemAnswer} parameters={parameters} setParameters={setParameters} selectedTags={selectedTags} setSelectedTags={setSelectedTags} setGetPostCompletedLoading={setModeratorGetPostCompletedLoading} SetGetTagLoading={SetGetTagLoading} setPostHideLoading={setPostHideLoading}></Aside>
                            {targets && targets.values.length > 0 ?
                                <>
                                    <Post targets={targets} setPostNextPage={setPostNextPage} setParameterTags={setParameterTags} parameterTags={parameterTags} setEditPostId={setEditPostId} allTargetCounts={allTargetCounts} for="moderator" setIsShowModeratorTags={setIsShowModeratorTags} setOpenItemAnswer={setOpenItemAnswer} openItemAnswer={openItemAnswer} parameterSubTags={parameterSubTags} parameters={parameters} editPostId={editPostId} subParameters={subParameters} setAllTargetCounts={setAllTargetCounts} setParameters={setParameters} selectedTags={selectedTags} setSelectedTags={setSelectedTags}  setFinishTargetLoading={setFinishTargetLoading}/>

                                    <div className="col-lg-6 col-md-6 col-sm-12 mb-25">
                                        {questions.length > 0 &&
                                            <div className='row mb-20'>
                                                <div className='col-lg-12'>
                                                    <div className='card'>
                                                        <div className='card-header'><h6>Open items</h6></div>
                                                        <div className='card-body moderopenitem-scroll'>
                                                            {questions.map((question, key) => {
                                                                let answer = openItemAnswer.find(row => row.open_item_id == question.id)
                                                                return (
                                                                    <div className='form-group row mb-30 border-bottom pl-20' key={key}>
                                                                        <div className='col-lg-12'><h6>{question.title}</h6></div>
                                                                        <div className='col-lg-8'><p>{question.question}</p></div>
                                                                        {question.type == 1 ?
                                                                            <>
                                                                                <div className='col-lg-2'>
                                                                                    <div className="radio-theme-default custom-radio">
                                                                                        <input
                                                                                            className="radio"
                                                                                            type="radio"
                                                                                            value="yes"
                                                                                            id="radio-un2"
                                                                                            onChange={(e) => getAnswer(e, question.id)}
                                                                                            name="answer"
                                                                                            checked={answer?.value === "yes"}
                                                                                        />
                                                                                        <label htmlFor="radio-un2">
                                                                                            <span className="radio-text">Yes</span>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-lg-2'>
                                                                                    <div className="radio-theme-default custom-radio">
                                                                                        <input
                                                                                            className="radio"
                                                                                            type="radio"
                                                                                            value="no"
                                                                                            id="radio-un4"
                                                                                            onChange={(e) => getAnswer(e, question.id)}
                                                                                            name="answer"
                                                                                            checked={answer?.value === "no"}
                                                                                        />
                                                                                        <label htmlFor="radio-un4">
                                                                                            <span className="radio-text">No</span>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <div className='col-lg-3'>
                                                                                <input type="text" className="form-control ip-lightradius-xs b-light" id="inputNameIcon" placeholder="Number" onChange={(e) => getAnswer(e, question.id, 'numeric')} value={answer?.value || ""} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                )
                                                            })}
                                                            {/* <div className="row d-flex justify-content-between flex-row-reverse">
                                                                <div className='col-lg-3'>
                                                                    {isShowModeratorTags ?
                                                                        <button className="btn btn-warning btn-default btn-squared" onClick={handleRevertBtn} >Cancel
                                                                        </button>
                                                                        :
                                                                        <button className="btn btn-success btn-default btn-squared " onClick={openItemSubmit}>Submit
                                                                        </button>
                                                                    }
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {/* {isShowModeratorTags && */}
                                        {openItemAnswer.length > 0 &&
                                            <Tags parameters={parameters} setParameters={setParameters} setParameterTags={setParameterTags} setParameterSubTags={setParameterSubTags} targets={targets} parameterTags={parameterTags} parameterSubTags={parameterSubTags} setIsTagDeleteCall={setIsTagDeleteCall} for="moderator" setSubParameters={setSubParameters} subParameters={subParameters} selectedTags={selectedTags} setSelectedTags={setSelectedTags} setSearchSubTagLoading={setSearchSubTagLoading}/>}

                                        {/* <div className="social-overview-wrap">
                                    <div className="card border-0">
                                        <div className="card-header">
                                            <h6>Suggest Audience</h6>
                                        </div> */}
                                        {/* <div className="card-body moderator-scroll-tags">
                                        {parameters.map((parameter)=>{return(
                                            <>
                                            <div className="form-group row">
                                                <div className="col-sm-3 d-flex aling-items-center">
                                                    <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">{EachWordCapitalize(parameter.name)}</label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15" id="inputName" placeholder={"Add "+EachWordCapitalize(parameter.name)} onChange={(e)=>getTag(e,parameter.id)} value={postTags[parameter.id] ? postTags[parameter.id] : ""} />
                                                    <ul>
                                                    {(recommendTags && recommendTags.parameter_id == parameter.id) && 
                                                    recommendTags.values.map((recommendTag)=>{ return(
                                                        <li onClick={()=>tagClick(recommendTag.id, recommendTag.tag,parameter.id)}>{recommendTag.tag}</li>
                                                    )})}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='row mb-20 pb-20 border-bottom'>
                                                <div className='col-12'>
                                                    <div className="atbd-tag-wrap">
                                                        <div className="tag-box">
                                                            {FilterParameterTags(parameterTags,parameter.id).map((tag)=>{
                                                                return(
                                                            <span className="atbd-tag tag-light">{tag.tag} <a className="tag-closable" onClick={()=>tagDelete(targets.values[0].id,tag.id,parameter.id)}><i className="la la-times"></i></a></span>
                                                            )})}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                            )})}
                                            </div> */}
                                        {/* <div className="card-body moderator-scroll-tags">
                                            <div className="form-group row">
                                                <div className="col-sm-3 d-flex aling-items-center">
                                                    <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">Age Generation</label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15" id="inputName" placeholder="Add Age Generation" />
                                                </div>
                                            </div>
                                            <div className='row mb-20 pb-20 border-bottom'>
                                                <div className='col-12'>
                                                    <div className="atbd-tag-wrap">
                                                        <div className="tag-box"><span className="atbd-tag tag-light">Baby Boomer <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Generation X <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Millennial <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Generation Z <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <div className="col-sm-3 d-flex aling-items-center">
                                                    <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">Age Range</label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15" id="inputName" placeholder="Add Age Range" />
                                                </div>
                                            </div>
                                            <div className='row mb-20 pb-20 border-bottom'>
                                                <div className='col-12'>
                                                    <div className="atbd-tag-wrap">
                                                        <div className="tag-box"><span className="atbd-tag tag-light">junior <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">6+ <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">13+ <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">20+ <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-3 d-flex aling-items-center">
                                                    <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">Religion</label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15" id="inputName" placeholder="Add Religion" />
                                                </div>
                                            </div>
                                            <div className='row mb-20 pb-20 border-bottom'>
                                                <div className='col-12'>
                                                    <div className="atbd-tag-wrap">
                                                        <div className="tag-box"><span className="atbd-tag tag-light">Christianity <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Islam <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Judaism<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Hinduism<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-3 d-flex aling-items-center">
                                                    <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">Denomination</label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15" id="inputName" placeholder="Add Denomination" />
                                                </div>
                                            </div>
                                            <div className='row mb-20 pb-20 border-bottom'>
                                                <div className='col-12'>
                                                    <div className="atbd-tag-wrap">
                                                        <div className="tag-box"><span className="atbd-tag tag-light">Catholicism <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Shia Islam <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Orthodox Judaism <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Vaishnavism <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-3 d-flex aling-items-center">
                                                    <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">Gender</label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15" id="inputName" placeholder="Add Gender" />
                                                </div>
                                            </div>
                                            <div className='row mb-20 pb-20 border-bottom'>
                                                <div className='col-12'>
                                                    <div className="atbd-tag-wrap">
                                                        <div className="tag-box"><span className="atbd-tag tag-light">Man <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Woman <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Non-binary <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Genderqueer <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-3 d-flex aling-items-center">
                                                    <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">Sexual orientation</label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15" id="inputName" placeholder="Add Sexual orientation" />
                                                </div>
                                            </div>
                                            <div className='row mb-20 pb-20 border-bottom'>
                                                <div className='col-12'>
                                                    <div className="atbd-tag-wrap">
                                                        <div className="tag-box"><span className="atbd-tag tag-light">Straight <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Heterosexual <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Homosexual <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Bisexual <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-3 d-flex aling-items-center">
                                                    <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">Spoken languages</label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15" id="inputName" placeholder="Add Spoken languages" />
                                                </div>
                                            </div>
                                            <div className='row mb-20 pb-20 border-bottom'>
                                                <div className='col-12'>
                                                    <div className="atbd-tag-wrap">
                                                        <div className="tag-box"><span className="atbd-tag tag-light">English <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Spanish <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Mandarin <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Hindi <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-3 d-flex aling-items-center">
                                                    <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">Sign languages</label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15" id="inputName" placeholder="Add Sign languages" />
                                                </div>
                                            </div>
                                            <div className='row mb-20 pb-20 border-bottom'>
                                                <div className='col-12'>
                                                    <div className="atbd-tag-wrap">
                                                        <div className="tag-box"><span className="atbd-tag tag-light">American Sign Language <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">British Sign Language <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">French Sign Language<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Australian Sign Language <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-3 d-flex aling-items-center">
                                                    <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">Ethnicity</label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15" id="inputName" placeholder="Add Ethnicity" />
                                                </div>
                                            </div>
                                            <div className='row mb-20 pb-20 border-bottom'>
                                                <div className='col-12'>
                                                    <div className="atbd-tag-wrap">
                                                        <div className="tag-box"><span className="atbd-tag tag-light">African <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Asian <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Caucasian<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Hispanic/Latino<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-3 d-flex aling-items-center">
                                                    <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">Nationality</label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15" id="inputName" placeholder="Add Nationality" />
                                                </div>
                                            </div>
                                            <div className='row mb-20 pb-20 border-bottom'>
                                                <div className='col-12'>
                                                    <div className="atbd-tag-wrap">
                                                        <div className="tag-box"><span className="atbd-tag tag-light">American <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">British <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Chinese <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Indian <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-3 d-flex aling-items-center">
                                                    <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">Occupation</label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15" id="inputName" placeholder="Add Occupation" />
                                                </div>
                                            </div>
                                            <div className='row mb-20 pb-20 border-bottom'>
                                                <div className='col-12'>
                                                    <div className="atbd-tag-wrap">
                                                        <div className="tag-box"><span className="atbd-tag tag-light">Doctor <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Teacher<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Farmer<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Engineer<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-3 d-flex aling-items-center">
                                                    <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">Education level</label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15" id="inputName" placeholder="Add Education level" />
                                                </div>
                                            </div>
                                            <div className='row mb-20 pb-20 border-bottom'>
                                                <div className='col-12'>
                                                    <div className="atbd-tag-wrap">
                                                        <div className="tag-box">   <span className="atbd-tag tag-light">High school graduate <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Graduate<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Postgraduate<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">Primary school graduate<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-3 d-flex aling-items-center">
                                                    <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">Socioeconomic status</label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15" id="inputName" placeholder="Add Education level" />
                                                </div>
                                            </div>
                                            <div className='row mb-20 pb-20 border-bottom'>
                                                <div className='col-12'>
                                                    <div className="atbd-tag-wrap">
                                                        <div className="tag-box">   <span className="atbd-tag tag-light">test <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">test<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">test<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">test<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-3 d-flex aling-items-center">
                                                    <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">Marital status</label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15" id="inputName" placeholder="Add Education level" />
                                                </div>
                                            </div>
                                            <div className='row mb-20 pb-20 border-bottom'>
                                                <div className='col-12'>
                                                    <div className="atbd-tag-wrap">
                                                        <div className="tag-box">   <span className="atbd-tag tag-light">test <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">test<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">test<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">test<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-3 d-flex aling-items-center">
                                                    <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">Health status</label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15" id="inputName" placeholder="Add Education level" />
                                                </div>
                                            </div>
                                            <div className='row mb-20 pb-20 border-bottom'>
                                                <div className='col-12'>
                                                    <div className="atbd-tag-wrap">
                                                        <div className="tag-box">   <span className="atbd-tag tag-light">test <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">test<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">test<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">test<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <div className="col-sm-3 d-flex aling-items-center">
                                                    <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">Disabilities</label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15" id="inputName" placeholder="Add Education level" />
                                                </div>
                                            </div>
                                            <div className='row mb-20 pb-20 border-bottom'>
                                                <div className='col-12'>
                                                    <div className="atbd-tag-wrap">
                                                        <div className="tag-box">   <span className="atbd-tag tag-light">test <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">test<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">test<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">test<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-3 d-flex aling-items-center">
                                                    <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">Geographical location
                                                    </label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15" id="inputName" placeholder="Add Education level" />
                                                </div>
                                            </div>
                                            <div className='row mb-20 pb-20 border-bottom'>
                                                <div className='col-12'>
                                                    <div className="atbd-tag-wrap">
                                                        <div className="tag-box">   <span className="atbd-tag tag-light">test <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">test<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">test<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">test<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-3 d-flex aling-items-center">
                                                    <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">Interests/Hobbies</label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15" id="inputName" placeholder="Add Education level" />
                                                </div>
                                            </div>
                                            <div className='row mb-20 pb-20 border-bottom'>
                                                <div className='col-12'>
                                                    <div className="atbd-tag-wrap">
                                                        <div className="tag-box">   <span className="atbd-tag tag-light">test <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">test<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">test<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">test<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-3 d-flex aling-items-center">
                                                    <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">Immigration status
                                                    </label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15" id="inputName" placeholder="Add Education level" />
                                                </div>
                                            </div>
                                            <div className='row mb-20 pb-20 border-bottom'>
                                                <div className='col-12'>
                                                    <div className="atbd-tag-wrap">
                                                        <div className="tag-box">   <span className="atbd-tag tag-light">test <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">test<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">test<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">test<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-3 d-flex aling-items-center">
                                                    <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">Military status

                                                    </label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15" id="inputName" placeholder="Add Education level" />
                                                </div>
                                            </div>
                                            <div className='row mb-20 pb-20 border-bottom'>
                                                <div className='col-12'>
                                                    <div className="atbd-tag-wrap">
                                                        <div className="tag-box">   <span className="atbd-tag tag-light">test <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">test<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">test<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">test<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-3 d-flex aling-items-center">
                                                    <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">Family status</label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input type="text" className="form-control ih-medium ip-light radius-xs b-light px-15" id="inputName" placeholder="Add Education level" />
                                                </div>
                                            </div>
                                            <div className='row mb-20 pb-20 border-bottom'>
                                                <div className='col-12'>
                                                    <div className="atbd-tag-wrap">
                                                        <div className="tag-box">   <span className="atbd-tag tag-light">test <a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">test<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">test<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                            <span className="atbd-tag tag-light">test<a href="#" className="tag-closable"><i className="la la-times"></i></a></span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* </div>
                                </div> */}

                                    </div>
                                </>
                                : <div>No data found</div>}

                        </div>

                    </div>
                </div>
                <Footer></Footer>
            </main>
            }

        </>
    );
}
export default HomeModerator;