import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from "react-router-dom";
import { getStorageToken } from '../Api/Storage';
import useCheckUserStatus from '../CustomHooks/useCheckUserStatus';

const MainLanding = ({profile, setIsShowHeader}) => {
    const { authid } = useParams();

    const redirect = useNavigate();
    useEffect(()=>{
        setIsShowHeader(false);
    },[])
    useCheckUserStatus("","");

    // useEffect(()=>{
    //     if(!getStorageToken()){
    //         redirect('/')
    //     }
    //     else if(!profile){
    //         redirect('/')
    //     }
    //     else{

    //         redirect('/curator')
    //     }
    // },[profile])
    function checkLogin(){
        if(!getStorageToken()){
            redirect('/login')
        }
        else if(!profile){
            redirect('/login')
        }
        else{
            redirect(`/u/${authid}/auth`)
        }
    }    
    return (
        <>
           
                <section className="pd-0 pos-rel">
                    <div className="container-fluid">
                        <div className="row pos-rel z2">
                            <div className="col-md-6 pos-rel pd-tb-medium flex-cc">
                                <div className="content pos-rel z2 mr-auto max-px-w600 align-c typo-light" > <a href="#" className="inline-block max-px-w200 mr-b-60" ><img src="/assets/img/landing-logo.png" alt="logo" /></a>
                                    <h1 className="title bold-n f-3 small">Landing Pages</h1>
                                    <h2 className="title mr-b-40" >Quick solution to design beautiful landing pages</h2>

                                    <a href="" className="inline-block mr-6 btn btn-info" onClick={checkLogin}>Login</a>
                                    <a href="" className=" btn btn-success inline-block mr-6">Apply</a> </div>
                                <div className="bg-holder full-wh z0"> <b data-bgholder="overlay" className="full-wh z5"></b>
                                    <div className="videobg z4 full-wh"></div>
                                    <div className="full-wh z3" >
                                        <div className="bgslider full-wh"></div>
                                    </div>
                                    <div className="full-wh z2" data-parallax-img="/assets/img/intro-bg02.jpg"></div>
                                    <b className="full-wh bg-cover bg-cc z1"></b> </div>
                            </div>
                            <div className="col-md-6 pd-tb-medium" >
                                <div className="content w75 mr-auto"> <img src="/assets/img/app-img2.png" className="mr-b-60" alt="app image" />
                                    <div className="row gt40 mb40">
                                        <div className="col-md-6">
                                            <div className="info-obj img-l g20 mini mr-0">
                                                <div className="img txt-default"><span className="iconwrp"><i className="pe-7s-phone"></i></span></div>
                                                <div className="info">
                                                    <h2 className="title tiny mr-b-4">Curator</h2>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                                        tempor incididunt ut labore</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="info-obj img-l g20 mini mr-0">
                                                <div className="img txt-default"><span className="iconwrp"><i className="pe-7s-mouse"></i></span></div>
                                                <div className="info">
                                                    <h2 className="title tiny mr-b-4">Moderator</h2>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                                        tempor incididunt ut labore</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="pd-tb-tiny bg-dark pos-rel">
                    <div className="container typo-light"><p className="align-c mb-0"><a href="#" target="_blank">EV Zone FaithHUB</a> © <span className="copyright-year">2023</span></p></div>
                </section>
           

        </>
    );
}
export default MainLanding;