import React, { useEffect } from 'react'
import useCheckUserStatus from '../CustomHooks/useCheckUserStatus';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getStorageToken } from '../Api/Storage';
import { sid } from '../Api/GeneralApi';

const LanWelcome = ({profile, setIsShowHeader, setPathname}) => {
    const redirect = useNavigate();

    const [searchParams] = useSearchParams();
    const X_AuthUser = searchParams.get('X_AuthUser');
    if(X_AuthUser){
        console.log('sdf');
        redirect(`/u/${X_AuthUser}/auth`)
    }
    const currentDomain = window.location.href;
    const baseUrl = "https://myaccount.evzone.app";                                            
    const redirectUrl = `${baseUrl}?redirecturl=${encodeURIComponent(currentDomain)}`;
    useEffect(()=>{
        sid().then((res)=>{

        }).catch((err)=>{
            
        })  
    },[])
    useEffect(()=>{
        setIsShowHeader(false);
    },[])
    // useCheckUserStatus("","");
    setPathname(window.location.pathname);
    // useEffect(()=>{
    //     if(!getStorageToken()){
    //         redirect('/')
    //     }
    //     else if(!profile){
    //         redirect('/')
    //     }
    //     else{

    //         redirect('/curator')
    //     }
    // },[profile])
    // function checkLogin(){
    //     if(!getStorageToken()){
    //         redirect('/login')
    //     }
    //     else if(!profile){
    //         redirect('/login')
    //     }
    //     else{
    //         redirect('/auth')
    //     }
    // }    
    return (
        <>
            <main className="main-content">
                <div className="signUP-admin">
                    <div className="container-fluid">
                        <div className="row justify-content-center sign-height">
                            <div className="col-xl-7 col-lg-7 col-md-7 p-0 landlogo">
                                <div className="">
                                    <div className="landlogoimg signUP-admin-left__img">
                                        <img className="img-fluid svg" src="/assets/img/faithhub-orange-01.png" alt="img" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 signUp-admin-right">
                                <div className="signUp-admin-right p-md-40 p-10">
                                    <div className="row justify-content-center">
                                        <div className="col-xl-12 col-lg-12 col-md-12">
                                            <div className="edit-profile mt-md-25 mt-0 login-form-height">
                                                <div className="card border-0">
                                                    <div className="card-header border-0  pb-md-15 pb-10 pt-md-20 pt-10 ">
                                                        <div className="edit-profile__title text-center w-100">
                                                            <h2>Welcome To</h2>
                                                            <h2>FaithHub</h2>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="edit-profile__body">
                                                            <div><p className='text-center loginpara'>It appears that you do not have an account with us, or may be using a new brower/device To gain access, Please click the button below</p></div>
                                                            <div className="button-group flex pt-1 text-center">
                                                                <button className="btn btn-outline-warning btn-squared mr-15 text-capitalize lh-normal px-50 py-15 signIn-createBtn">Apply</button>
                                                                {/* <button className="btn btn-warning btn-default btn-squared mr-15 text-capitalize lh-normal px-50 py-15 signIn-createBtn" onClick={checkLogin}>
                                                                    Login
                                                                </button> */}
                                                                <a href={redirectUrl}><button className="btn btn-warning btn-default btn-squared mr-15 text-capitalize lh-normal px-50 py-15 signIn-createBtn">
                                                                    Login
                                                                </button></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
export default LanWelcome;