import React, { useEffect } from 'react'
import { adminCheckUserStatus } from '../Api/GeneralApi';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";

const useCheckUserStatus = (setIsBreak, setUserBreakDetails, setBreakContent=null) => {
    // console.log('workingsfd')
    const { authid } = useParams();
    const redirect = useNavigate();
    const location = useLocation();
    console.log(authid);
    useEffect(()=>{
    // console.log('workingsfdhfh')

        adminCheckUserStatus().then((res)=>{
            if(location.pathname == "/auth" || location.pathname == "/")
            {
                if(authid){
                    redirect(`/u/${authid}/curator`);
                }
            }
        }).catch((err)=>{
            if(setBreakContent){
                if(!err.response.data.data.role_status){
                    setBreakContent({title: null, content: "Your application Details has been received by the Recruitment team and you will receive a follow up email soon", role_status: null})
                }
                else{
                    if(err.response.data.data.role_status == 1){
                        // if(err.response.data.data.status == 9){
                        //     setBreakContent({title: "You have been Logged out of your account", content: "It is important that you contact your supervisor before you log back in", role_status: 1})
                        // }
                        if(err.response.data.data.status == 9){
                            setBreakContent({title: `Welcome back, ${ err.response.data.data.profile_name}!`, content: "You're almost there! Log in to access your work page", role_status: 1})
                        }
                        else{
                            setBreakContent({title: "Welcome back, "+ err.response.data.data.profile_name, content: "You're almost there! Log in to access your work page", role_status: 1})
                        }
                    }
                    else if(err.response.data.data.role_status == 3){
                        setBreakContent({title: "You're account has been suspended", content: "To have this resolved contact your superviser using your work email", role_status: 3})
                    }
                    else if(err.response.data.data.role_status == 6){
                        setBreakContent({title: "You have been Logged out of your account", content: "It is important that you contact your supervisor before you log back in", role_status: 6})
                    }
                    else if(err.response.data.data.role_status == 7){
                        setBreakContent({title: "You're account has been locked", content: "To have this resolved contact your superviser using your work email", role_status: 7})
                    }
                    else{
                        console.log(err.response.data.data.role_status)
                        setBreakContent({title: null, content: null, role_status: err.response.data.data.role_status})
                    }
                }
            }
            
            if(err.response.status == 401)
            {
                redirect('/')
            }
            else if(err.response.status == 402)
            {
                if(authid){
                    redirect(`/u/${authid}/user-details`);
                }
            }
            else if(err.response.status == 403)
            {
                if(authid){
                    redirect(`/u/${authid}/auth`);
            
                }
            }
            else
            {
                
                if(setUserBreakDetails){
                    setUserBreakDetails(err.response.data.data);
                }
                if(setIsBreak){
                    if(err.response.data.data.is_show == 1 && err.response.data.data.status !== 11)
                    {
                        if(setIsBreak)
                        {
                            setIsBreak(true);
                        }
                    }
                }
                if(err.response.data.data.role_status && err.response.data.data.role_status == !null)
                {
                    if(location.pathname == "/user-details")
                    {
                        redirect(-1);
                    }
                }
                if(err.response.status == 422)
                {
                    if(authid){
                        redirect(`/u/${authid}/auth`);
                    }
                }
                if(location.pathname == "/")
                {
                    if(authid){
                        redirect(`/u/${authid}/auth`);
                    }
                }
            }
        });
    },[])
    
//   return (
//         // setIsBreak
//   )
}

export default useCheckUserStatus