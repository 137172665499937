import React, { useState, useEffect } from 'react';

const Preloader = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 20000000);
  }, []);

  return (
    <div className="container">
      {loading ? (
                <div className="loader-container">
                    <div className="spinner"> <video src="/assets/video/loading.mp4" autoPlay loop muted>
                        Your browser does not support the video tag.
                    </video> </div>
                </div>
            ) : (
       <></>
      )}
    </div>
  );
};

export default Preloader;