import axios from 'axios';
import { CoreUrl, GeneralUrl, Configuration, } from "./Url";
const updateUserStatusUrl               = GeneralUrl()+'update/user/status';
const adminStatusListUrl                = GeneralUrl()+'admin/status/lists';
const adminCheckStatusUrl               = GeneralUrl()+'check/admin/status';
const adminUpdateActiveUserTimeUrl      = GeneralUrl()+'update/active/user/time';
const adminCheckLogoutTimeUrl           = GeneralUrl()+"check/admin/logout/time";
const getLanguageListUrl                = GeneralUrl()+"get/language/list" ;
const getDailyTrackUrl                  = GeneralUrl()+"get/daily/track" ;
const sidUrl                            = "https://api.v1.evzone.app/api/v1.0/app/core/auth/sid" ;
let config                              =  Configuration()                          
export function updateUserStatus(data="",passcode="",time="", address="", location="", image){
    let config = Configuration();
    data = {status:data,time:time};
    data.timezone_offset = new Date().getTimezoneOffset();
    if(passcode)
    {
        data.passcode = passcode;
    }
    if(address){
        data.address = address;
    }
    if(location){
        data.location = location;
    }
    if(image){
        data.image = image;
    }
    let url = updateUserStatusUrl;
    return axios.post(url,data,config);
}
export function adminUserStatusList(is_show){
    let config = Configuration();
    let url = adminStatusListUrl;
    let data;
    if(is_show){
        data = {is_show: is_show};
    }
    if(data){
        config.params = data;
    }
    return axios.get(url,config);
}
export function adminCheckUserStatus(){
    let config = Configuration();
    let url = adminCheckStatusUrl;
    let data = {timezone_offset : new Date().getTimezoneOffset()}
    config.params = data;
    return axios.get(url,config);
}
export function adminUpdateActiveUserTime(is_end_of_day, address=null, location=null, time){
    let config = Configuration();
    let url = adminUpdateActiveUserTimeUrl;
    let data = {time: time};
    if(is_end_of_day == true)
    {
        data.end_of_day = true;
    }
    if(address){
        data.address = address;
    }
    if(location){
        data.location = location;
    }
    return axios.post(url,data,config);
}
export function checkAdminLogoutTime(){
    let config = Configuration();
    let url = adminCheckLogoutTimeUrl;
    return axios.get(url,config);
}
export function getLanguageList(){
    let config = Configuration();
    let url = getLanguageListUrl;
    return axios.get(url,config);
}
export function getDailyTrack(){
    let config = Configuration();
    let url = getDailyTrackUrl;
    return axios.get(url,config);
}

export function sid(){
    let config = Configuration();
    let url = sidUrl;
    return axios.get(url,config);
}